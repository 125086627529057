import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {Slider} from '../style/Slider'
import {BlockTitle} from '../style/Titles'
import {MobileLargeLogo, MobileLogo} from '../style/Nav'
import {ChevronLeft, ChevronRight} from 'preact-feather'


export class Scenario extends Component {
    render({scenario, current, onNext, onPrev}, {}, _) {
        return <Container>
            <ScenarioSlider item={current}>
                <Slide>
                    <MobileLargeLogo/>
                    <BlockTitle>Scénario</BlockTitle>
                    <ScenarioText dangerouslySetInnerHTML={{__html: scenario?.first}}/>
                    <TutorialImage src={require('../style/imgs/tutorial/01.png')}/>
                </Slide>
                <Slide>
                    <MobileLargeLogo/>
                    <BlockTitle>Scénario</BlockTitle>
                    <ScenarioText dangerouslySetInnerHTML={{__html: scenario?.second}}/>
                    <TutorialImage src={require('../style/imgs/tutorial/02.png')}/>
                </Slide>
                <Slide>
                    <MobileLargeLogo/>
                    <BlockTitle>Scénario</BlockTitle>
                    <ScenarioText dangerouslySetInnerHTML={{__html: scenario?.third}}/>
                    <TutorialImage src={require('../style/imgs/tutorial/03.png')}/>
                </Slide>
            </ScenarioSlider>
            {current > 0 && <Arrow left={true} onClick={onPrev}><ChevronLeft size="1em"/></Arrow>}
            <Arrow left={false} onClick={onNext}><ChevronRight size="1em"/></Arrow>
        </Container>
    }
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 0 auto',
    position     : 'relative',
    height       : '100%',
    width        : '100%',
})
const Arrow = styled('div')(({left}) => ({
    position: 'absolute',
    bottom  : '13%',
    ...(left ? {left: '0%'} : {right: '0%'}),
    fontSize: '5em',
    cursor  : 'pointer',
}))
const ScenarioSlider = styled(Slider)((_, theme) => ({
    backgroundColor: theme.colors.main,
}))
const Slide = styled('article')({
    display      : 'flex',
    overflowY    : 'auto',
    flexDirection: 'column',
    alignSelf    : 'stretch',
    padding      : '1em',
})
const ScenarioText = styled('p')((_, theme) => ({
    flex      : '1 0 auto',
    color     : theme.colors.inverted.text,
    fontWeight: 700,
    lineHeight: 1.4,
    textAlign : 'center',
}))
const TutorialImage = styled('img')({
    display     : 'block',
    margin      : '0 auto',
    width       : '70%',
    marginBottom: '2em',
    height      : 'auto',
    flex        : '0 0 auto',
})
