import {connect} from '../../../utils/connect'
import {Component, h} from 'preact'
import {combine} from '../../../utils/combine'
import {connectActsLibrary, connectCampaign, connectCampaignActs, connectCampaignThemes} from '../campaigns.connectors'
import {updateAct} from '../campaigns.creators'
import React from 'preact-compat'
import 'react-dragula/dist/dragula.css'
import humane from 'humane-js'
import {DragFromLibrary} from '../DragFromLibrary'
import {CampaignActDraggable} from './CampaignActDraggable'
import {ModalStyle} from '../../../style/modalStyle'
import {CampaignActScreen} from './CampaignActScreen'
import {CiviActs} from '../../../app/CiviActs/CiviActs'
import {CiviActEnd} from '../../../app/CiviActs/CiviActEnd'
import {CiviAct} from '../../../app/CiviActs/CiviAct'
import {styled} from '../../../style/styled'
import {PlayerTypes} from '../../../app/PlayerActions/player.types'
import {Popup} from '../../../app/Modals/Modals'
import {CampaignProgress} from '../CampaignProgress/CampaignProgress'
import nanoid from "nanoid"

export const CampaignActsScreen = connect(combine(
    connectCampaign,
    connectCampaignActs,
    connectActsLibrary,
    connectCampaignThemes,
))
(class CampaignActsScreen extends Component {
    state = {
        modalOpened: false,
        userData   : {
            guide: {
                'choose-challenge': true,
            },
            acts: {},
        },
    }

    save = async (param) => {
        this.setState({
            acts: param
        })
        const paramSave = param.reduce((obj, item) => {
            obj[item.key] = item
            return obj
        }, {})
        await updateAct(this.props.campaignRef, paramSave)
        humane.log('Enregistré')
    }

    edit = (data) => {
        this.setState({
            modalOpened: true,
            currentAct : data,
        })
    }
    create = (data) => {
        this.setState({
            modalOpened: true,
            currentAct : data,
        })
    }

    closeModal = () => {
        this.setState({
            modalOpened: false,
            currentAct : null,
        })
    }

    onActUpdate = (act, ...args) => {
        this.setState({
            currentAct: act,
        })
    }
    onUrlChange = (url) => {
        const [, id, end] = url.split('/').filter(Boolean)
        this.setState({
            preview: {
                end: !!end,
                id,
            }
        })
    }

    closeIt = (...args) => {
        this.setState({
            preview: {}
        })
    }

    getPreviewComponent = () => {
        if (this.state.preview?.content) {
            return () => {
                const Comp = Popup({
                    ...this.state.preview?.content,
                    close: this.closeIt,
                })
                return <Comp {...this.state.preview?.content} close={this.closeIt}/>
            }
        }
        return (props) => <Scrollable><CiviActs {...props}/></Scrollable>
    }

    getChildContext() {
        return {
            userData: this.state.userData,
            startedActs: [],
            acts: this.props.acts,
            popups  : {
                open: (content) => {
                    this.setState({
                        preview: {
                            content,
                        },
                    })
                }
            },
        }
    }

    onAddActions = (action) => {
        if (action?.type === PlayerTypes.startAct) {
            const {act} = action.payload
            this.setState({
                userData: {
                    ...this.state.userData,
                    acts: {
                        ...this.state.userData.acts,
                        [act]: {
                            started: true,
                        }
                    }
                }
            })
        } else if (action?.type === PlayerTypes.endAct) {

            const {act} = action.payload
            this.setState({
                userData: {
                    ...this.state.userData,
                    acts: {
                        ...this.state.userData.acts,
                        [act]: {
                            ...this.state.userData.acts[act],
                            done: true,
                        }
                    }
                }
            })
        }
    }

    saveAct = async () => {
        const {key, ...act} = this.state.currentAct

        await updateAct(this.props.campaignRef, {[key || nanoid()]: act})
        this.closeModal()
        humane.log('Enregistré')

    }

    render({actsLibrary, themes, campaignRef, campaign, campaignId, acts}, {modalOpened, currentAct}, _) {
        console.log(themes, acts)
        return <DragFromLibrary
            pageTitle="Défis"
            tip={`Les Défis, sont les actions que les joueurs doivent faire dans la vie réelle. Notre bibliothèque en contient une cinquantaine sur les différents volets de la RSE (Social, Environnemental, Consommation).`}
            campaign={campaign}
            library={actsLibrary}
            items={acts}
            themes={themes}
            edit={this.edit}
            create={this.create}
            component={CampaignActDraggable}
            preview={this.getPreviewComponent()}
            onAddActions={this.onAddActions}
            onUrlChange={this.onUrlChange}
            save={this.save}
        >
            <CampaignProgress campaign={campaign} campaignId={campaignId}/>
            {currentAct && <ModalStyle
                isOpen={modalOpened}
                onRequestCancel={this.closeModal}
                onRequestClose={this.saveAct}
                title="Défis"
                key={currentAct.key || 'act'}
            >
                <CampaignActScreen
                    act={currentAct}
                    campaign={campaign}
                    onUpdate={this.onActUpdate}
                />
            </ModalStyle>}
        </DragFromLibrary>
    }
})

const FalseModal = styled('div')({
    position: 'absolute',
    top     : 0,
    left    : 0,
    right   : 0,
    bottom  : 0,
})
const Scrollable = styled('div')({
    overflowY: 'auto'
})
