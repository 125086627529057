import {Component, h} from 'preact'
import {connect} from '../../../utils/connect'
import {managerTheme} from '../../ui/managerTheme'
import {styled} from '../../../style/styled'
import {RoundButton} from '../../ui/Buttons'
import {Edit2, Plus, Trash2} from 'preact-feather'
import {Page} from '../../ui/Page'
import {forScreen} from '../../../../common/i18n'
import {deleteCampaign, initializeCampaign} from '../campaigns.initHepers'
import {tracker} from '../../../../common/tracker'

const t = forScreen('manager.campaignsList')
export const CampagnsListScreen = connect(() => ({
    campaigns: 'campaigns as Array'
}))(class CampagnsListScreen extends Component {
    createCampaign = async () => {
        try {
            const key = await initializeCampaign()
            this.context.goTo(`campaigns/${key}/settings`)
            tracker.push({
                event: 'create-campaign',
                cat  : 'manager',
            })
        } catch (e) {
            console.error(e)
        }
    }

    deleteCampaign = async (campaign) => {
        await deleteCampaign(campaign)
    }

    goTo = (campaign) => {
        this.context.goTo('/campaigns/' + campaign.key + '/settings')
        tracker.push({
            event: 'edit-campaign',
            cat  : 'manager',
        })
    }

    render({campaigns}) {
        return <Page title={t('title')}>
            <NewCampaign onClick={this.createCampaign}>
                <RoundButton>
                    <Plus/>
                </RoundButton>
                <p>{t('nextCampaign')}</p>
            </NewCampaign>
            {
                campaigns?.map((campaign) => <Campaign key={campaign.key}>
                    <span>{campaign?.settings?.name || 'Nouvelle campagne'}</span>
                    <Buttons>
                        <RoundButton onClick={() => this.goTo(campaign)}>
                            <Edit2/>
                        </RoundButton>
                        {/*<RoundButton onClick={() => this.deleteCampaign(campaign)} cancel={true}>
                            <Trash2/>
                        </RoundButton>*/}
                    </Buttons>
                </Campaign>)
            }
        </Page>
    }
})


const campaignStyle = ({
    textDecoration     : 'none',
    padding            : '0.2rem 1rem',
    width              : '100%',
    boxSizing          : 'border-box',
    color              : managerTheme.colors.text,
    display            : 'flex',
    flexDirection      : 'row',
    justifyContent     : 'space-between',
    alignItems         : 'center',
    backgroundColor    : managerTheme.colors.cardBackground,
    '&:nth-of-type(2n)': {
        backgroundColor: managerTheme.colors.background,
    }
})
const Campaign = styled('div')(campaignStyle)
const Buttons = styled('div')({

})
const NewCampaign = styled('div')({
    ...campaignStyle,
    flexDirection : 'column',
    justifyContent: 'center',
    textAlign     : 'center',
})
