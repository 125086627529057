import {styled} from '../../style/styled'
import {h} from 'preact'

const cardStyle = (_, theme) => ({
    position       : 'relative',
    borderRadius   : '0.6em',
    backgroundColor: theme.colors.background,
    color          : theme.colors.text,
    boxShadow      : theme.shadow(),
    margin         : '0.3em',
    padding        : '0.4em',
    flex           : '1 1 auto',
    display        : 'flex',
    flexDirection  : ' column',
})
export const Card = styled('article')(cardStyle)
export const CardTitle = styled(({children, ...props}) => <CardTitleContainer {...props}>
    <CardTitleContent>{children}</CardTitleContent>
</CardTitleContainer>)({})

const CardTitleContainer = styled('div')({
    margin   : '-0.4em -0.4em 0',
    textAlign: 'center',
    padding  : '0.4rem',
    fontSize : '1em',

    borderTopLeftRadius : '0.6em',
    borderTopRightRadius: '0.6em',
})

const CardTitleContent = styled('h1')({
    textAlign: 'center',
    margin   : 0,
})
export const CardBottom = styled('div')({
    margin : '0 -0.4em -0.4em',
    padding: '0.4rem',

    borderBottomLeftRadius : '0.6em',
    borderBottomRightRadius: '0.6em',
})

export const CardRow = styled('div')({
    margin : '0 -0.4em',
    padding: '0.4rem',
})

export const ModalCard = ({children, ...props}) => <ModalCardContainer {...props}>
    <InnerContent>
        {children}
    </InnerContent>
</ModalCardContainer>
const ModalCardContainer = styled('article')(cardStyle, ({
    margin   : '0.3em 0.3em 2em',
    overflowY: 'auto',
}))
const InnerContent = styled('div')({
    flex: '1 0 auto',

    display      : 'flex',
    flexDirection: 'column',
})

export const Splitter = styled('hr')({
    border   : '1px solid white',
    alignSelf: 'stretch',
    width    : '100%',
    margin   : '1em 0',
})
