import {Component, h} from 'preact'
import {styled} from '../../../style/styled'
import React from 'preact-compat'
import {Card, CardFoot} from '../../ui/Card'
import {managerTheme} from '../../ui/managerTheme'
import {Edit2, Plus, Trash2} from 'preact-feather'


export class CampaignQuizDraggable extends Component {
    open = () => {
        this.props.open && this.props.open(this.props.data)
    }

    delete = () => {
        this.props.delete && this.props.delete(this.props.data)
    }

    render({data, library, open, canCopy, canEdit, canDelete}, {}, _) {
        const originalLabel = data.question || 'Nouveau'
        const label = originalLabel.substr(0, 100)
        return (
            <Draggable data-orderdragg={data.order} data-keydrag={data.key} data-library={library}>
                <Section>
                    <ContentText
                        dangerouslySetInnerHTML={{__html: label + (originalLabel.length > label.length ? '...' : '')}}
                    />
                </Section>
                <Information>
                    <Label>
                        {Object.keys(data.responses || {}).length} réponses possibles
                    </Label>
                    {
                        canDelete &&
                        <TrashButtonDrag onClick={this.delete}><Trash2/></TrashButtonDrag>
                    }
                    {
                        canCopy &&
                        <EditButtonDrag onClick={this.open}><Plus/></EditButtonDrag>
                    }
                    {
                        canEdit &&
                        <EditButtonDrag onClick={this.open}><Edit2/></EditButtonDrag>
                    }
                </Information>
            </Draggable>
        )
    }
}


const Draggable = Card.extends({
    flex           : '0 0 auto',
    margin         : '0.5rem',
    overflow       : 'hidden',
    width          : '20rem',
    height         : '7rem',
    display        : 'flex',
    flexDirection  : 'column',
    backgroundColor: managerTheme.colors.cardBackground,
    cursor         : 'move',
}).withProps({paddingSize: '0.15rem'})

const ContentText = styled('p')({
    margin: 0,
})

const Information = CardFoot.extends({
    display        : 'flex',
    alignItems     : 'center',
    backgroundColor: managerTheme.colors.lightBackground,
})

const Label = styled('div')({
    margin : '0 auto',
    padding: '0.15rem',
})

const EditButtonDrag = styled('div')({
    display        : 'flex',
    alignItems     : 'center',
    justifyContent : 'center',
    backgroundColor: managerTheme.colors.action,
    color          : '#ffffff',
    padding        : '0.15rem',
    cursor         : 'pointer',
    '&:hover'      : {},
})

const TrashButtonDrag = EditButtonDrag.extends({
    backgroundColor: managerTheme.colors.alert,
})

const Section = styled('div')({
    flex     : '1 1 auto',
    padding  : '10px 5px',
    textAlign: 'left'
})
