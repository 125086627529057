import translate from 'translate.js'
import {plural_FR} from 'translate.js/plurals'
import {login} from './i18n/login'
import {firebase} from './i18n/firebase'
import {campaignsList} from './i18n/manager/campaignsList'

export const t = translate({
    login,
    firebase,
    'manager.campaignsList': campaignsList,
}, {
    pluralize     : plural_FR,
    resolveAliases: true,
})

export const forScreen = (key) => (...args) => t(key, ...args)

export const fromFirebaseError = (error) => t('firebase', tap(error).code)

function tap(v) {
    console.log(v)
    return v
}
