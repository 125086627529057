
declare module window {
    const dataLayer: Array<any>;
}

export const tracker = {
    push(event) {
        try {
            window.dataLayer.push(event)
        } catch (e) {}
    }
}
