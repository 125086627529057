import {firebaseApp} from '../firebase'

export async function uploadFileFromDataUrl(file, path) {
    await import('firebase/storage')
    const uploadTask = firebaseApp.storage().ref(path).putString(file, 'data_url')
    return {
        on     : (...props) => uploadTask.on(...props),
        promise: uploadTask,
        ref    : uploadTask.snapshot.ref,
        getUrl() {
            return uploadTask.snapshot.ref.getDownloadURL()
        },
    }
}

export async function getFileUrl(path) {
    await import('firebase/storage')
    try {
        return await firebaseApp.storage().ref(path).getDownloadURL()
    } catch (e) {
        return null
    }
}
