import {Page} from '../../ui/Page'
import {RichTextArea} from '../../ui/Input'
import {Component, h} from 'preact'
import {connectCampaign} from '../campaigns.connectors'
import {Watcher} from '../../../utils/DataSync'
import {updateCampaign} from '../campaigns.creators'
import humane from 'humane-js'
import {connect} from '../../../utils/connect'
import {media, styled} from '../../../style/styled'
import {tracker} from '../../../../common/tracker'
import {AppPreview} from '../../Preview/AppPreview'
import {ScenarioScreen} from '../../../app/Scenario/Scenario.screen'
import {Card} from '../../ui/Card'
import {managerTheme} from '../../ui/managerTheme'
import {CampaignProgress} from '../CampaignProgress/CampaignProgress'


export const CampaignScenarioScreen = connect(connectCampaign)
(class CampaignScenarioScreen extends Component {
    state = {
        scenario: null,
        index   : 0,
    }
    unsubscribe = null

    componentWillMount() {
        this.watcher = Watcher(this.props.campaign?.scenario)
        this.unsubscribe = this.watcher.subscribe(tree => this.setState({
            scenario: tree,
        }))
    }

    componentWillReceiveProps(nextProps, _) {
        this.watcher.put('')(nextProps?.campaign?.scenario)
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe()
    }

    select = (index) => () => this.setState({
        index: index,
    })


    save = async () => {
        const scenario = this.watcher.get('')
        await updateCampaign(this.props.campaignRef, {scenario})
        humane.log('Enregistré')
        tracker.push({
            event: 'save-scenario',
            cat  : 'manager',
        })
    }

    render({campaign, campaignId}, {index}, _) {
        const watcher = this.watcher
        return <Page title="Scénario" shouldScroll={false}
                     tip={`Modifiez ici le scénario que liront vos collaborateurs lors de leur première connexion.`}>
            <CampaignProgress campaign={campaign} campaignId={campaignId}/>
            <ToRight>
                <AppPreview>
                    <ScenarioScreen scenario={watcher.get()}/>
                </AppPreview>
            </ToRight>
            <Wrapper>
                <FieldCard>
                    <RichTextArea onInput={watcher.put('first')} value={watcher.get('first')}
                                  placeholder="Ecran 1" onFocus={this.select(0)} onBlur={this.save}/>
                </FieldCard>
                <FieldCard>

                    <RichTextArea onInput={watcher.put('second')} value={watcher.get('second')}
                                  placeholder="Ecran 2" onFocus={this.select(1)} onBlur={this.save}/>

                </FieldCard>
                <FieldCard>
                    <RichTextArea onInput={watcher.put('third')} value={watcher.get('third')}
                                  placeholder="Ecran 3" onFocus={this.select(2)} onBlur={this.save}/>
                </FieldCard>
            </Wrapper>
        </Page>
    }

})

const ToRight = styled('div')({
    display       : 'flex',
    justifyContent: 'flex-end',
    alignSelf     : 'stretch',
})

const FieldCard = Card.extends({
    backgroundColor: managerTheme.colors.cardBackground,
    margin         : '1rem',
    flex           : '1 1 30%',
})

const Wrapper = styled('div')({
    overflow     : 'auto',
    marginTop    : '20px',
    display      : 'flex',
    width        : '100%',
    height       : '80%',
    flexFlow     : 'column nowrap',
    alignSelf    : 'stretch',
    [media(1025)]: {
        flexFlow: 'row nowrap',
        overflow: 'visible'
    },
})
