import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import {generateJobsMapName} from '../../../common/generateMapName'
import {seenJob} from '../PlayerActions/player.actions'
import {ModalTitle} from '../Modals/Modals'

export class ImageMap extends Component {
    state = {
        map : 'map_default.png',
        jobs: []
    }

    componentDidMount() {
        this.bounds = [[0, 0], [3020, 4420]]
        const position = initialPositin
        const map = this.map = L.map(this.base, {
            crs               : L.CRS.Simple,
            maxBounds         : this.bounds,
            zoomDelta         : 0.1,
            zoomSnap          : 0.1,
            maxZoom           : -0.4,
            minZoom           : -2.1,
            zoom              : position.zoom,
            zoomControl       : false,
            attributionControl: false,
        })
        map.on('move', function (event) {
            const bounds = map.getBounds()
            const northEast = bounds.getNorthEast()
            const southWest = bounds.getSouthWest()
            console.log('move', map.getZoom(), `[[${Math.round(northEast.lat)}, ${Math.round(northEast.lng)}],[${Math.round(southWest.lat)}, ${Math.round(southWest.lng)}]]`)
        })
        map.fitBounds(position.bounds)
        this.updateMap(this.context.userJobs)
        this.triggerSeen(this.context.userJobs)
    }

    componentWillReceiveProps({}, {userJobs}) {
        this.updateMap(userJobs)
    }

    updateMap(jobs) {
        const name = generateJobsMapName(
            jobs.filter(job => job.seen)
        )
        this.changeMap(`https://map.civitime.com/maps/${name}`)
    }

    async triggerSeen(jobs) {
        let lastJob = null
        await jobs.filter(job => job.viewable)
            .reduce(async (acc, job) => {
                await acc
                lastJob = job
                await this.context.popups.alert({
                    content: <div>
                        <ModalTitle>Le CiviJob "<i>{job.name}</i>" est terminé !</ModalTitle>
                        {job.feedbackOut && <p>{job.feedbackOut}</p> }
                    </div>
                })
                this.context.addActions(seenJob(job.key, this.context.now))
            }, Promise.resolve())
        if (lastJob) {
            const position = jobsPositions[lastJob?.key] || defaultPosition
            this.map.flyToBounds(position.bounds)
        }
    }

    componentWillUnmount() {
        this.map.off()
        this.map.remove()
    }

    changeMap = async (map) => {
        const oldMap = this.previousMap
        await preload(map)

        this.previousMap = L.imageOverlay(map, this.bounds, {
            className: 'map-overlay',
            opacity  : 0,
        }).addTo(this.map)
        setTimeout(() => {
            this.previousMap.setOpacity(1)
            setTimeout(() => {
                oldMap?.remove()
            }, 2000)
        }, 500)
    }

    render(props, _, {userJobs}) {
        return <Container {...props} key="map-drag-listener" className={
            (userJobs || [])
                .filter(job => job.seen)
                .map(({key}) => key).join(' ')
        }/>
    }
}

const Container = styled('div')({
    width           : '100%',
    height          : '100%',
    '& .map-overlay': {
        transition: 'opacity ease-in-out 2000ms'
    }
})

const initialPosition = {
    zoom  : -1.4,
    bounds: [[3019, 3035], [1449, 2085]]
}
const defaultPosition = {
    zoom  : -1.5,
    bounds: [[3020, 3180], [1238, 1780]]
}
const jobsPositions = {
    Amphitheater     : {
        bounds: [
            [2800, 1870],
            [700, 670]
        ]
    },
    Awarness         : {
        bounds: [
            [3000, 2600],
            [1300, 1700]
        ]
    },
    Bikes            : {
        bounds: [
            [2800, 3750], [950, 2750],
        ]
    },
    BodyBuildingRoom : {
        bounds: [
            [2500, 1400],
            [450, 250]
        ]
    },
    BreakingRoomLVL2 : {
        bounds: [
            [3000, 2450],
            [1100, 1350]
        ],
    },
    Diversity        : {
        bounds: [
            [3000, 3000],
            [450, 1600]
        ],
    },
    HerOffice        : {
        bounds: [[3019, 3506], [1821, 2841]]
    },
    Hives            : {
        bounds: [[2236, 2083], [0, 842]]
    },
    NappingRoom      : {
        bounds: [[2926, 3712], [1549, 2948]]
    },
    OfficeLVL2       : {
        bounds: defaultPosition.bounds,
    },
    PMR              : {
        bounds: defaultPosition.bounds,
    },
    Plant            : {
        bounds: defaultPosition.bounds,
    },
    QVT              : {
        bounds: defaultPosition.bounds,
    },
    RecyclingDumpster: {
        bounds: [[2184, 2999], [808, 2235]],
    },
    Remote           : {
        bounds: [[2631, 3051], [1588, 2472]],
    },
    RenewableEnergy  : {
        bounds: [[2573, 1670], [5, 244]],
    },
    ShortList        : {
        bounds: defaultPosition.bounds,
    },
}

async function preload(url) {
    return new Promise((resolve) => {
        const img = new Image()
        img.onload = resolve
        img.src = url
        setTimeout(resolve, 2000)
    })
}
