import {connect} from '../../../utils/connect'
import {Component, h} from 'preact'
import React from 'preact-compat'
import {Page} from '../../ui/Page'
import {combine} from '../../../utils/combine'
import {connectCampaign} from '../campaigns.connectors'
import {media, styled} from '../../../style/styled'
import {ThemeDatePicker} from '../../ui/ThemeDatePicker'
import {DragAndDropTheme} from './DragAndDropTheme'
import {ManageTheme} from './ManageTheme'
import {Card} from '../../ui/Card'
import {managerTheme} from '../../ui/managerTheme'
import {CampaignProgress} from '../CampaignProgress/CampaignProgress'

const colors = ['#aedccb', '#9ca2c5', '#9fb7c4', '#d8d8d8']

export const CampaignThemesScreen = connect(combine(
    connectCampaign,
))
(class CampaignThemesScreen extends Component {
    state = {
        i         : 0,
        editingKey: null,
    }

    changeEditingKey = (param = null) => {
        this.setState({
            editingKey: param
        })
    }


    render({campaign, campaignRef, campaignId}, {editingKey}, _) {
        return campaign && (
            <Page title={"Thèmes"} key="theme-page">
                <CampaignProgress campaign={campaign} campaignId={campaignId}/>
                <Line>
                    <Col>
                        <WrapperThemeManage>
                            <DragAndDropTheme
                                campaign={campaign}
                                campaignRef={campaignRef}
                                colors={colors}
                                changeEditingKey={this.changeEditingKey}
                            />
                            {editingKey != null && <ManageTheme
                                campaign={campaign}
                                campaignRef={campaignRef}
                                editingKey={editingKey}
                                changeEditingKey={this.changeEditingKey}
                            />}
                        </WrapperThemeManage>
                    </Col>
                    <Col>
                        <ThemeDatePicker campaign={campaign} campaignRef={campaignRef} colors={colors}/>
                    </Col>
                </Line>
            </Page>
        )
    }

})


const WrapperThemeManage = Card.withProps({color: managerTheme.colors.lightBackground}).extends({})

const Col = styled('div')({
    margin       : '0.75rem',
    flex         : '1 1 100%',
    [media(1280)]: {
        flex: '1 1 50%',
    }
})

const Line = styled('div')({
    display      : 'flex',
    flexWrap     : 'wrap',
    alignSelf    : 'stretch',
    [media(1280)]: {
        flexWrap: 'nowrap',
    }
})
