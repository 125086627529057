import {styled} from '../../style/styled'
import {managerTheme} from './managerTheme'
import {h} from 'preact'

export const TransparentButton = styled('button')(({active}) => ({
        display        : 'inline-flex',
        justifyContent : 'center',
        alignItems     : 'center',
        fontSize       : '1em',
        backgroundColor: 'transparent',
        color          : managerTheme.colors.action,
        cursor         : 'pointer',
        padding        : '5px',
        border         : 'none',
        outline        : 'none',
        transition     : 'all ease-out 150ms',
        transform      : 'translate(0, 0)',
        borderRadius   : '0.5rem',
        ...(active ? {
            backgroundColor: managerTheme.colors.action + ' !important',
            color          : managerTheme.colors.navText + ' !important',
        } : {}),
    })
)


export const RoundButton = styled('button')(({size = 'medium', margin = '0 5px', cancel}) => ({
    display        : 'inline-flex',
    justifyContent : 'center',
    alignItems     : 'center',
    width          : fromSize(size),
    height         : fromSize(size),
    padding        : 0,
    borderRadius   : '50%',
    border         : 'none',
    outline        : 'none',
    boxShadow      : managerTheme.shadow(6),
    transition     : 'all ease-in-out, 350ms',
    backgroundColor: cancel ? managerTheme.colors.alert : managerTheme.colors.action,
    color          : managerTheme.colors.navText,
    margin         : margin,
    cursor         : 'pointer',
    transform      : 'translate(0, 0)',
    '&:hover'      : {
        boxShadow: managerTheme.shadow(7),
        transform: 'translate(-1px, -1px)',
    },
}))
export const RoundedButton = styled('button')(({size = 'medium', margin = '0 5px'}) => ({
    display        : 'inline-flex',
    justifyContent : 'center',
    alignItems     : 'center',
    alignSelf      : 'center',
    padding        : '15px',
    fontSize       : '1rem',
    borderRadius   : fromSize(size),
    border         : 'none',
    outline        : 'none',
    boxShadow      : managerTheme.shadow(6),
    transition     : 'all ease-in-out, 350ms',
    backgroundColor: managerTheme.colors.action,
    color          : managerTheme.colors.navText,
    margin         : margin,
    cursor         : 'pointer',
    transform      : 'translate(0, 0)',
    '&:hover'      : {
        boxShadow: managerTheme.shadow(7),
        transform: 'translate(-1px, -1px)',
    },
}))

export const FlatButton = styled('button')(({size = 'medium', margin = '0 5px', cancel}) => ({
    display        : 'inline-flex',
    justifyContent : 'center',
    alignItems     : 'center',
    alignSelf      : 'flex-end',
    padding        : '15px',
    minWidth       : '120px',
    fontSize       : '1rem',
    borderRadius   : '4px',
    border         : 'none',
    outline        : 'none',
    boxShadow      : managerTheme.shadow(6),
    transition     : 'all ease-in-out, 350ms',
    backgroundColor: cancel ? managerTheme.colors.alert : managerTheme.colors.action,
    color          : managerTheme.colors.navText,
    margin         : margin,
    cursor         : 'pointer',
    transform      : 'translate(0, 0)',
    '&:hover'      : {
        boxShadow: managerTheme.shadow(7),
        transform: 'translate(-1px, -1px)',
    },
}))

export const IconButton = ({icon, children, ...props}) => <IconButtonContainer role="button" {...props}>
    <div className="icon">{icon}</div>
    <div className="text-container">
        <div className="text">
            <div className="text-wrapper">
                {children}
            </div>
        </div>
    </div>
</IconButtonContainer>

const IconButtonContainer = styled('div')(({size = 'medium', margin = '0 5px', direction}) => {
    const isRight = direction === 'right'
    return ({
        display : 'inline-flex',
        margin  : margin,
        position: 'relative',
        zIndex  : 1,
        color   : managerTheme.colors.navText,

        '& > *': {
            flex: '0 0 auto',
        },

        '& .icon': {
            order          : isRight ? 0 : 1,
            display        : 'flex',
            justifyContent : 'center',
            alignItems     : 'center',
            alignSelf      : 'center',
            fontSize       : '1rem',
            width          : fromSize(size),
            height         : fromSize(size),
            padding        : 0,
            border         : 'none',
            outline        : 'none',
            transition     : 'all ease-in-out, 350ms',
            borderRadius   : '50%',
            backgroundColor: managerTheme.colors.action,
            color          : managerTheme.colors.navText,
            cursor         : 'pointer',
            transform      : 'translate(0, 0)',
        },

        '& .text-container, & .text': {
            display       : 'flex',
            justifyContent: 'center',
            alignItems    : 'center',
            fontSize      : '1rem',
            height        : fromSize(size),
            overflow      : 'hidden',
        },

        '& .text-container': {
            position: 'relative',
            ...(isRight ? {
                order: 1,
                left : '-' + fromDemiSize(size),
            } : {
                order: 0,
                right: '-' + fromDemiSize(size),
            }),
            zIndex  : '-1',
        },

        '& .text': {
            padding        : '0 ' + fromDemiSize(size),
            ...(isRight ? {
                transform              : `translateX(-100%)`,
                borderTopRightRadius   : fromDemiSize(size),
                borderBottomRightRadius: fromDemiSize(size),
            } : {
                transform             : `translateX(100%)`,
                borderTopLeftRadius   : fromDemiSize(size),
                borderBottomLeftRadius: fromDemiSize(size),
            }),
            backgroundColor: managerTheme.colors.action,
            transition     : 'transform ease-in-out 350ms',
            cursor         : 'pointer',
        },

        '&:hover .text': {
            transform: `translateX(0)`,
        },

        '& .text-wrapper': {
            padding   : '0 5px',
            opacity   : 0,
            transition: 'transform ease-in-out 200ms',
        },

        '&:hover .text-wrapper': {
            opacity   : 1,
            transition: 'transform ease-in-out 200ms 150ms',
        },
    })
})

function fromDemiSize(size) {
    switch (size) {
        case 'small':
            return '7.5px'
        case 'large':
            return '22.5px'
        case 'medium':
            return '15px'
        default:
            return size
    }
}

function fromSize(size) {
    switch (size) {
        case 'small':
            return '15px'
        case 'large':
            return '45px'
        case 'medium':
            return '30px'
        default:
            return size
    }
}
