import {Component, h} from 'preact'
import {Slider} from '../style/Slider'
import {QuizzQuestion} from './QuizzQuestion'
import {styled} from '../../style/styled'
import {QuizzResponse} from './QuizzResponse'
import {QuizzResult} from './QuizzResult'
import {answerQuizQuestion} from '../PlayerActions/player.actions'
import {activityUserCode} from '../PlayerActions/player.reducers'

export class QuizzQuestions extends Component {
    state = {
        questions    : [],
        responses    : [],
        current      : 0,
        loading      : true,
        todayQuizzKey: activityUserCode(),
    }

    componentDidMount() {
        this.setState({
            todayQuizzKey: activityUserCode(this.context.now),
        }, () => this.syncQuestions(this.context.userData?.quiz))
    }

    componentWillReceiveProps({}, {userData, now}) {
        this.setState({
            todayQuizzKey: activityUserCode(now),
        }, () => this.syncQuestions(userData?.quiz))
    }

    syncQuestions = (quizz) => {
        if (quizz == null || quizz[this.state.todayQuizzKey] == null) return
        const questions = quizz[this.state.todayQuizzKey].questions
        const questionsResult = Object.keys(questions || {}).map(key => {
            const question = questions[key]
            return ({
                key,
                ...question,
                responses: Object.keys(question.responses || {}).map(key => ({key, ...question.responses[key]}))
            })
        })
        const firstUnansweredQuestion = questionsResult.findIndex(({answer}) => !answer)
        this.setState({
            quizz,
            questions: questionsResult,
            current  : this.state.loading
                ? (firstUnansweredQuestion < 0
                        ? quizz[this.state.todayQuizzKey].answeredAll ? questionsResult.length * 2 : 0
                        : (firstUnansweredQuestion * 2)
                )
                : this.state.current,
            responses: questionsResult.map(({answer, responses}) => responses[answer])
        }, () => {
            this.setState({
                loading: false,
            })
        })
    }

    choose = (question, response) => {
        const index = this.state.questions.indexOf(question)
        this.context.addActions(answerQuizQuestion(question.key, response.key))
        this.setState({
            responses: this.state.responses.concat(response),
            current  : 1 + index * 2,
        })
    }
    next = (question) => {
        const index = this.state.questions.indexOf(question)
        this.setState({
            current: (index + 1) * 2,
        })
    }

    goBack = () => {
        this.context.goTo('/')
    }

    render({closeIt}, {loading, questions, current, responses, quizz, todayQuizzKey}, _) {
        return loading ? <div/> : <QuizzSlider item={current}>
            {
                questions
                    .map((question, i) => [<QuizzQuestion
                        key={'question-' + i}
                        question={question}
                        item={i + 1}
                        count={questions.length}
                        choose={this.choose}
                    />, <QuizzResponse
                        key={'response-' + i}
                        question={question}
                        response={responses[i]}
                        item={i + 1}
                        count={questions.length}
                        next={this.next}
                    />])
                    // flatten the array containing arrays
                    .reduce((acc, item) => acc.concat(item), [])
            }
            <QuizzResult close={this.goBack} quiz={quizz && quizz[todayQuizzKey]} todayQuizzKey={todayQuizzKey}/>


        </QuizzSlider>
    }
}

const QuizzSlider = styled(Slider)((_, theme) => ({
    backgroundColor: theme.colors.main,
}))

