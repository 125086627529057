
export const guides = ({user, userData}) => [
    {
        key     : 'hello',
        selector: '#entreprise-details',
        messages: [
            {
                text       : `Bienvenue dans CiviTime ${(userData && userData.name) || (user && user.displayName) || ''} !`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Nous avons besoin de vos talents de responsable RSE : les actions que vous allez réaliser dans le monde réel vont rendre notre entreprise plus responsable et plus vertueuse.`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Nous voici sur l'écran d'accueil. C'est ici que vous voyez notre entreprise et ses évolutions.`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Le niveau de l'entreprise est représenté par la jauge située à droite de votre écran. Pour le moment elle est à zéro. Mais ensemble, je suis sûr que nous pouvons la remplir !`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Cliquez sur la jauge "niveau de l'entreprise !"`,
                position   : 'top',
                showTrigger: true,
            },
        ],
    },
    {
        key     : 'launch-challenges',
        selector: '#launch-challenges',
        messages: [
            {
                text       : `Le niveau de l'entreprise correspond à la somme de la jauge environnementale et de la jauge sociale.`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Pour augmenter le niveau des jauges, et rendre notre entreprise plus responsable, vous allez lancer des améliorations : les CiviJobs.`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Et pour lancer les CiviJobs, vous aurez besoin de CiviFlouz, la monnaie locale ! Une manière d'en gagner est de relever des Défis. Allons tester ça...`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Rendez-vous dans le menu "Défis"`,
                position   : 'center',
                showTrigger: true,
            },
        ],
    },
    {
        key     : 'choose-challenge',
        selector: '#act-choose-challenge',
        bonus   : 2,
        messages: [
            {
                text       : `Voici la liste de Défis que vous devrez réaliser dans la vie réelle. Chacun rapporte des CiviFlouz et prend un certain temps.`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Regardez le premier par exemple : il permet de gagner 2 CiviFlouz et vous pouvez le valider tout de suite. Ajoutez-le pour voir.`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Ajoutez le premier Défi de la liste`,
                position   : 'top',
                showTrigger: true,
            },
        ],
    },

    {
        key     : 'return-challenge',
        selector: '#acts-screen #back-button',
        messages: [
            {
                text       : `Bravo, vous avez désormais 2 CifiFlouz ! Retournez sur l'écran d'accueil pour les investir.`,
                position   : 'center',
                showTrigger: true,
            },
        ],
    },
    {
        key     : 'launch-jobs',
        selector: '#launch-jobs',
        messages: [
            {
                text       : `Avant d'investir votre CiviFlouz, sachez qu'il existe deux autres moyens d’en gagner : le Dual-Quiz et le Mini-Jeu. Vous y accédez avec les boutons situés à droite du bouton “Défis”... Mais je vous laisserai tester par vous même plus tard.`,
                position   : 'center',
                showTrigger: false,
            },
            {
                text       : `Pour le moment, rendez-vous dans le Bureau RSE pour lancer un CiviJob.`,
                position   : 'top',
                showTrigger: true,
            },
        ],
    },
    {
        key     : 'trigger-job',
        selector: '#job-Amphitheater',
        messages: [
            {
                text       : `Dans le Bureau RSE sont affichés tous les CiviJobs qui rendront notre entreprise plus durable.`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Le premier CiviJob ne coûte qu’un CiviFlouz, vous avez donc assez pour le lancer.`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `Lancez le premier CiviJob`,
                position   : 'top',
                showTrigger: true,
            },
        ],
    },
    // Une étape suplémentaire lorsque l'on vient de cliquer sur le CiviJob mais que l'on est encore dans le Bureau RSE
    {
        key     : 'job-back',
        selector: '#jobs-screen #back-button',
        messages: [
            {
                text       : `Retournons sur l'écran d'accueil. J'ai hâte de découvrir notre nouvel amphitéâtre !`,
                position   : 'center',
                showTrigger: true, // Trigger -> le bouton pour revenir vers l'écran d'accueil
            },
        ],
    },
    // A déclancher après avoir cliqué OK sur le dialogue d'apparition du CiviJob
    {
        key     : 'end-job',
        selector: '.Amphitheater',
        messages: [
            {
                text       : `Super, maintenant nous pouvons mieux former nos collaborateurs ! Et le niveau de l'entreprise a augmenté. Regardez, on peut déjà la voir évoluer !`,
                position   : 'top',
                showTrigger: false,
            },
            {
                text       : `C’est à vous de jouer maintenant. Je vous laisse les rênes pour emmener notre entreprise vers la durabilité et la responsabilité. Je compte sur vous... bonne chance ! 
                Et n'hésitez à explorer le Dual-Quiz et le Mini-Jeu.`,
                position   : 'center',
                showTrigger: false,
            },
        ],
    },
]
export const guideAct = {
    key             : 'choose-challenge',
    flouz           : 2,
    daysToValidation: 0,
    duration        : 0,
    label           : 'Découvrez CiviTime',
}
export const guideJob = {
    key              : 'choose-job',
    cost             : 1,
    productionTime   : 0,
    socialPoints     : 1,
    environmentPoints: 1,
    name            : 'Découvrez CiviTime',
}
