import {h} from 'preact'
import {css, keyframes} from 'glamor'

function styleds(styles) {
    return (props, theme) => css(...styles.map(fn => fn(props, theme)))
}

export const styled = (Component) => (...styles) => {
    styles = styles.map(s => typeof s === 'function' ? s : () => s)

    const styleFn = styleds(styles)
    const component = (props, context) => h(Component, {...props, ...styleFn(props, context.theme)}, props.children)
    component.extends = (newStyle) => styled(Component)(...styles, newStyle)
    component.with = (Component) => styled(Component)(...styles)
    component.withProps = (props) => {
        return styled((innerProps, context) => {
            const classes = [innerProps.className, innerProps.class, props.className, props.class].filter(Boolean)
            const className = classes.length ? {
                className: classes.join(' ')
            } : {}
            return component({...props, ...innerProps, ...className}, context)
        })(...styles)
    }
    return component
}

export const animation = keyframes


export const media = (size) => `@media only screen and (min-width: ${size}px)`
