import {LoginFormContainer} from '../style/ui/forms'
import {h} from 'preact'
import {Paragraph, TitlePage} from '../style/ui/text'
import {forScreen} from '../../common/i18n'

const t = forScreen('login')
export const SignupSuccess = () => <LoginFormContainer>
    <TitlePage>{t('emailSent')}</TitlePage>
    <Paragraph>{t('accountCreated')}</Paragraph>
</LoginFormContainer>
