import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {Check, Plus} from 'preact-feather'
import {Card, CardBottom} from '../style/Cards'

export class CiviActPreview extends Component {
    seeMore = (event) => {
        event.preventDefault()
        this.props.seeMore && this.props.seeMore(this.props.act)
    }

    render({act, disabled, status}, {}, _) {
        const started = status === 'started'
        const ended = status === 'done'
        const awaiting = act.daysToValidation
        const terminated = started && act.active
        return <Container onClick={this.seeMore} id={`act-${act.key}`}>
            <ActContainer active={started} terminated={terminated} ended={ended} noButton={(started && !terminated) || ended}>
                <ActContent>
                    <HeadContainer>
                        <CardLabel>{
                            do {
                                if (terminated) {
                                    'à valider'
                                } else if (status === 'awaiting') {
                                    'disponible'
                                } else if (started) {
                                    'en cours'
                                } else {
                                    'Terminé'
                                }
                            }
                        }</CardLabel>
                        <Separator/>
                        <ActText>{act.label}</ActText>
                    </HeadContainer>
                    <ActBonuses active={started} terminated={terminated}>
                        <ActBonus>
                            <span>+{act.flouz} CiviFlouz</span>
                            <ActBonusIcon src={require('!!from-file!../style/svgs/quizPoints.svg')}/>
                        </ActBonus>
                        <ActBonus>
                            {
                                terminated
                                    ? <span>Terminé</span>
                                    : (
                                        started
                                            ?
                                            <span>{awaiting < 1 ? 0 : awaiting} jour{awaiting > 1 ? 's' : ''} restants</span>
                                            : <span>{act.duration} jour{act.duration > 1 ? 's' : ''}</span>
                                    )
                            }
                            <ActBonusIcon src={require('../style/imgs/clock.png')}/>
                        </ActBonus>
                    </ActBonuses>
                </ActContent>
            </ActContainer>
            {((!started || terminated) && !ended) && <GoContainer>
                <ActGo active={started} terminated={terminated}>
                    {started ? <Check size="3em"/> : <Plus size="3em"/>}
                </ActGo>
            </GoContainer>}
        </Container>
    }
}

const Container = styled('div')({
    position: 'relative',
    zIndex  : 1,
    padding : '0.3em',
})
const ActContainer = Card.extends(({active, terminated, ended, noButton}, theme) => ({
    backgroundColor: ended ? theme.colors.lightValid : terminated ? theme.colors.valid : (active
            ? '#FCD3B1'
            : theme.colors.background
    ),
    position       : 'relative',
    zIndex         : 1,
    margin         : 0,
    marginBottom   : noButton ? '2em' : '0.3em',
}))
const HeadContainer = styled('div')({
    padding: '0.5em',
})
const CardLabel = styled('h2')({
    fontSize     : '1.2em',
    textTransform: 'uppercase',
    margin       : '0.2em 0',
})
const Separator = styled('hr')((_, theme) => ({
    border      : 'none',
    borderBottom: '2px solid ' + theme.colors.text,
    width       : '100%',
    margin      : '0.2em 0',
}))
const ActContent = styled('div')({
    display       : 'flex',
    flex          : '1 1 auto',
    flexDirection : 'column',
    minHeight     : '7em',
    justifyContent: 'space-between',
})
const ActText = styled('p')({
    margin    : '0',
    fontSize  : '0.8em',
    lineHeight: '1.3',
    fontWeight: 'bold',
})
const ActBonuses = CardBottom.extends(({active, terminated}, theme) => ({
    display        : 'flex',
    backgroundColor: active ? 'white' : theme.colors.modalRefuse,
    color          : theme.colors.text,
}))
const ActBonus = styled('div')({
    flex         : '1 1 auto',
    marginRight  : '0.3em',
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
})
const ActBonusIcon = styled('img')({
    display: 'block',
    width  : 'auto',
    height : '1.5em',
    margin : '0.3em',
})

const GoContainer = styled('div')({
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    position      : 'relative',
    top           : '-0.5em',
    zIndex        : -1,
})
const ActGo = styled('button')(({active, terminated}, theme) => ({
    border         : 'none',
    backgroundColor: terminated
        ? theme.colors.valid
        : theme.colors.modalRefuse,
    color          : terminated
        ? theme.colors.inverted.text
        : theme.colors.text,
    width          : '3em',
    height         : '3em',
    borderRadius   : '0.5em',
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    boxShadow      : theme.shadow()
}))
