import {Component, h} from 'preact'
import {ContentWithPreviewCard, Page, PreviewCard, PreviewRow} from '../../ui/Page'
import {RoundButton, RoundedButton} from '../../ui/Buttons'
import {UploadInput} from '../../ui/UploadInput'
import {Input, RichTextArea} from '../../ui/Input'
import {TeamPreview as AppTeamPreview} from '../../../app/Teams/TeamPreview'
import {connectCampaign, connectCampaignTeam} from '../campaigns.connectors'
import {combine} from '../../../utils/combine'
import {Watcher} from '../../../utils/DataSync'
import {getFileUrl} from '../../../utils/files'
import humane from 'humane-js'
import {connect} from '../../../utils/connect'
import {styled} from '../../../style/styled'
import {updateTeams} from '../campaigns.creators'
import {tracker} from '../../../../common/tracker'

const logoFromTeam = (campaign, team) => `campaigns/${campaign}/teams/${team}/logo`

export const CampaignTeamScreen = connect(combine(connectCampaign, connectCampaignTeam))
(class CampaignTeamScreen extends Component {
    state = {
        team   : null,
        explain: true,
    }
    unsubscribe = null

    componentWillMount() {
        this.watcher = Watcher(this.props.team)
        this.unsubscribe = this.watcher.subscribe(tree => this.setState({
            team: tree,
        }))
        this.loadLogo(this.props.campaignId, this.props.teamId)
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe()
    }

    componentWillReceiveProps(nextProps, _) {
        this.watcher.put('')(nextProps.team)
        if (!nextProps.campaignId || !nextProps.teamId || (nextProps.teamId === this.props.teamId && nextProps.campaignId === this.props.campaignId)) return
        this.loadLogo(nextProps.campaignId, nextProps.teamRef)
    }

    focusIntroduction = () => {
        this.setState({
            explain: false,
        })
    }
    blurIntroduction = () => {
        this.setState({
            explain: true,
        })
    }
    loadLogo = async (campaign, team) => {
        if (campaign && team) {
            const path = logoFromTeam(campaign, team)
            this.setState({
                logo: await getFileUrl(path)
            })
        }
    }

    setTempLogo = (url) => {
        this.setState({
            tempLogo: url
        })
    }

    save = async () => {
        const team = this.watcher.get('')
        await updateTeams(this.props.campaignRef, {[this.props.teamId]: team})
        await this.uploader?.upload()
        humane.log('Enregistré')
        tracker.push({
            event: 'save-team',
            cat  : 'manager',
        })
        history.back()
    }

    render({campaign, campaignRef, team, teamRef, campaignId, teamId}, {explain, logo, tempLogo}, _) {
        const watcher = this.watcher
        return <Page title={watcher.get('name')} shouldScroll={false}
                     tip={`Modifiez les informations de l'équipe.`}>
            <PreviewRow>
                <ContentWithPreviewCard>
                    <ListContainer>
                        <UploadInput value={logo || ' '} onChange={this.setTempLogo}
                                     ref={ref => this.uploader = ref}
                                     size='150px'
                                     line={true}
                                     path={logoFromTeam(campaignId, teamId)}>
                            <Input value={watcher.get('name')} onInput={watcher.put('name')}
                                   placeholder="Nom de l'équipe"/>
                        </UploadInput>
                        <RichTextArea margin={'0'} onInput={watcher.put(`introduction`)} key="introduction"
                                      placeholder="Introduction" value={watcher.get('introduction')}
                                      onFocus={this.focusIntroduction} onBlur={this.blurIntroduction}
                        />
                        <RichTextArea margin={'0'} onInput={watcher.put(`description`)}
                                      placeholder="Déscription" value={watcher.get('description')}/>
                        <RoundedButton type="button" onClick={this.save}>Enregistrer</RoundedButton>
                    </ListContainer>
                </ContentWithPreviewCard>
                <PreviewCard campaignId={campaignId} settings={campaign?.settings}>
                    <AppTeamPreview team={watcher.get('')} teamId={teamId} campaignId={campaignId} tempLogo={tempLogo} explain={explain}/>
                </PreviewCard>
            </PreviewRow>
        </Page>
    }
})


const ListContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'stretch',
    width        : '600px',
    maxWidth     : '100%',
    margin       : '0 auto',
})

const ButtonRow = styled('div')({
    display       : 'flex',
    justifyContent: 'flex-end',
    padding       : '10px 0',
    alignSelf     : 'stretch',
    '& > *'       : {
        margin: '5px',
    }
})
