import {styled} from '../../style/styled'
import {managerTheme} from './managerTheme'
import {Component, h} from 'preact'
import generate from 'nanoid/generate'
import 'pell/dist/pell.css'
import {exec, init} from 'pell'

const inputBorder = '1px solid ' + managerTheme.colors.inputBorder

const inputStyle = ({margin = '0', width = '100%', padding = '3px 5px', type, value, forcedFocus = false}) => ({
    display     : 'block',
    border      : 'none',
    borderBottom: inputBorder,
    color       : managerTheme.colors.text,
    padding     : padding,
    fontSize    : '1rem',
    fontWeight  : 'normal',
    width       : type === 'checkbox' ? 'auto' : width,
    maxWidth    : '100%',
    outline     : 'none',
    margin      : margin,
    marginTop   : '1.5rem',
})

function isEmpty(value, forcedFocus) {
    return !forcedFocus && (value == null || value === '' || (value?.trim && value.trim() === ''))
}

const StyledInput = styled('input')(inputStyle)
const StyledInputContainer = styled('label')(({width}) => ({
    display : 'block',
    position: 'relative',
    color   : managerTheme.colors.text,
    padding : '1px',
    width   : width ? width : 'auto',
    margin  : '0.5rem 0'
}))
const RawInputContainer = StyledInputContainer.with('div')
export const StyledLabel = styled('p')({
    margin       : 0,
    padding      : '0',
    fontWeight   : 'bold',
    fontFamily   : 'Raleway',
    fontSize     : '18px',
    fontStyle    : 'normal',
    fontStretch  : 'normal',
    lineHeight   : 'normal',
    letterSpacing: '0.3px',
    color        : '#3d3d3d',
})

export class Input extends Component {
    lastTimeout = null
    state = {
        value: '',
    }
    focused = false
    onFocus = (event) => {
        this.props.onFocus && this.props.onFocus(event)
        this.focused = true
    }
    onBlur = (event) => {
        this.props.onBlur && this.props.onBlur(event)
        this.focused = false
    }

    constructor(...args) {
        super(...args)
        this.state.value = this.props.value
        this.onInput = (event) => {
            this.setState({value: event.target.type === 'checkbox' ? !event.target.checked : event.target.value})
            clearTimeout(this.lastTimeout)
            if (this.props.onInput) {
                this.lastTimeout = setTimeout(() => this.props.onInput(event), 100)
            }
        }
    }

    componentWillReceiveProps({value}) {
        if (this.focused) return
        this.setState({
            value,
        })
    }

    componentWillUnmount() {
        clearTimeout(this.lastTimeout)
    }

    render({placeholder, width, onInput, onFocus, onBlur, ...props}, {value}) {
        return <StyledInputContainer width={width}>
            <StyledLabel>{placeholder}</StyledLabel>
            <StyledInput {...props} onInput={this.onInput} value={value} onFocus={this.onFocus}
                         onBlur={this.onBlur}/>
        </StyledInputContainer>
    }
}

const StyledTextArea = styled('textarea')(inputStyle)

export class TextArea extends Component {
    lastTimeout = null
    state = {
        value: '',
    }
    focused = false

    constructor(...args) {
        super(...args)
        this.state.value = this.props.value
        this.onInput = (event) => {
            this.setState({value: event.target.value})
            clearTimeout(this.lastTimeout)
            if (this.props.onInput) {
                this.lastTimeout = setTimeout(() => {
                    this.props.onInput(event)
                }, 500)
            }
        }
    }

    componentWillReceiveProps({value}) {
        if (this.focused) return
        this.setState({
            value,
        })
    }

    onFocus = (event) => {
        this.props.onFocus && this.props.onFocus(event)
        this.focused = true
    }
    onBlur = (event) => {
        this.props.onBlur && this.props.onBlur(event)
        this.focused = false
    }

    componentWillUnmount() {
        clearTimeout(this.lastTimeout)
    }

    render({placeholder, onInput, onFocus, onBlur, ...props}, {value}) {
        return <StyledInputContainer {...props}>
            <StyledTextArea {...props} onInput={this.onInput} value={value} onFocus={this.onFocus}
                            onBlur={this.onBlur}/>
            <StyledLabel>{placeholder}</StyledLabel>
        </StyledInputContainer>
    }
}

export const InvertedArea = styled(TextArea)(({disabled}) => ({
    backgroundColor: (disabled ? 'lightgrey' : managerTheme.colors.text) + ' !important',
    color          : managerTheme.colors.background + ' !important',
}))

export const Checkbox = ({value, placeholder, onChange}) => <StyledInputContainer>
    <Toggle value={value} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        return onChange(!value)
    }}/>
    <StyledLabel>{placeholder}</StyledLabel>
</StyledInputContainer>

const Toggle = styled('div')(({value}) => ({
    position           : 'relative',
    height             : '2rem',
    width              : '4rem',
    margin             : '0.5rem',
    '&:before, &:after': {
        content: '\'\'',
        display: 'block',
    },
    '&:before'         : {
        position       : 'relative',
        top            : '0.25rem',
        left           : '0.5rem',
        height         : '1.5rem',
        width          : '3rem',
        borderRadius   : '1rem',
        backgroundColor: value ? managerTheme.colors.action : managerTheme.colors.inputBorder,
        opacity        : '0.3',
    },
    '&:after'          : {
        height         : '2rem',
        width          : '2rem',
        borderRadius   : '50%',
        backgroundColor: value ? managerTheme.colors.action : managerTheme.colors.inputBorder,
        transition     : 'transform ease-in-out 150ms',
        boxShadow      : managerTheme.shadow(5),

        position : 'absolute',
        top      : '0',
        left     : '0',
        transform: value ? 'translateX(2rem)' : 'translateX(0)',
    },
}))

const StyledRichTextArea = styled('div')({
    display   : 'block',
    border    : 'none',
    color     : managerTheme.colors.text,
    fontSize  : '1rem',
    fontWeight: 'normal',
    width     : '100%',
    maxWidth  : '100%',
    outline   : 'none',
    marginTop : '1.5rem',
    height    : '100%',
    '& .pell-content': {
        minHeight : '5em',
    }
})

export class RichTextArea extends Component {
    lastTimeout = null
    state = {
        value: '',
    }
    focused = false

    constructor(...args) {
        super(...args)
        this.id = generate('1234567890abcdefghijklmnopqrstuvwxyz', 10)
        this.state.value = this.props.value
        this.onInput = (value) => {
            this.setState({value: value})
            clearTimeout(this.lastTimeout)
            if (this.props.onInput) {
                this.lastTimeout = setTimeout(() => {
                    this.props.onInput(value)
                }, 500)
            }
        }
    }

    async componentDidMount() {
        const $container = document.getElementById(this.id)
        this.editor = init({
            element : $container,
            onChange: this.onInput,
            actions : ['bold', 'underline', 'italic', {
                name  : 'couleur',
                icon  : 'C',
                title : 'Couleur',
                result: () => exec('foreColor', 'blue')
            }],
        })
        this.editor.content.innerHTML = this.state.value || this.props.value || ''
        this.editor.content.addEventListener('focus', this.onFocus)
        this.editor.content.addEventListener('blur', this.onBlur)
    }

    componentWillReceiveProps({value}) {
        if (this.focused) return
        if (value === this.state.value) return
        this.setState({
            value,
        }, () => {
            if (!this.editor) return
            this.editor.content.innerHTML = value || ''
        })
    }

    onFocus = (event) => {
        this.props.onFocus && this.props.onFocus(event)
        this.focused = true
    }
    onBlur = (event) => {
        this.props.onBlur && this.props.onBlur(event)
        this.focused = false
    }

    componentWillUnmount() {
        clearTimeout(this.lastTimeout)
    }

    render({placeholder, onInput, onFocus, onBlur, ...props}, {value}) {
        return <RawInputContainer {...props} htmlFor={this.id} key={this.id + 'container'}>
            <StyledLabel>{placeholder}</StyledLabel>
            <StyledRichTextArea
                {...props}
                padding={'0'}
                id={this.id}
                key={this.id}
                forcedFocus={true}
            />
        </RawInputContainer>
    }
}

export class Select extends Component {
    state = {
    }

    constructor(...args) {
        super(...args)
        this.id = generate('1234567890abcdefghijklmnopqrstuvwxyz', 10)
    }

    render({placeholder, ...props}, {}, _) {
        return <RawInputContainer {...props} htmlFor={this.id} key={this.id + 'select'}>
            <StyledLabel>{placeholder}</StyledLabel>
            <select {...props}>

            </select>
        </RawInputContainer>
    }
}
