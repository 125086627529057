import {PlayerTypes} from './player.types'

export const chooseCampaign = (code) => ({
    type   : PlayerTypes.chooseCampaign,
    payload: {
        code,
    },
})

export const seenScenario = () => ({
    type   : PlayerTypes.seenScenario,
    payload: {},
})

export const chooseTeam = (team) => ({
    type   : PlayerTypes.chooseTeam,
    payload: {
        team,
    },
})

export const startAct = (act) => ({
    type   : PlayerTypes.startAct,
    payload: {
        act,
    },
})
export const endAct = (act) => ({
    type   : PlayerTypes.endAct,
    payload: {
        act,
    },
})

export const findQuizOpponent = () => ({
    type   : PlayerTypes.findQuizOpponent,
    payload: {},
})

export const answerQuizQuestion = (question, answer) => ({
    type   : PlayerTypes.answerQuizQuestion,
    payload: {
        question,
        answer,
    },
})

export const guideStep = (guideKey) => ({
    type   : PlayerTypes.guideStep,
    payload: {
        guideKey,
    },
})

export const buyJob = (jobKey, now) => ({
    type   : PlayerTypes.buyJob,
    payload: {
        jobKey,
        bought: typeof now === 'object' ? now.toISOString() : now,
    },
})

export const seenJob = (jobKey, now) => ({
    type   : PlayerTypes.seenJob,
    payload: {
        jobKey,
        seen: typeof now === 'object' ? now.toISOString() : now,
    },
})

export const addADay = () => ({
    type   : PlayerTypes.addADay,
    payload: {},
})
export const resetProfile = () => ({
    type   : PlayerTypes.resetProfile,
    payload: {},
})
