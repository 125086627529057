
export class IterableObject {
    private readonly __value: any

    constructor(object) {
        this.__value = object
        if (!this.__value) return
        Object.defineProperties(this, this.reduce((acc, value, key) => ({
            ...acc,
            [key]: {
                get() {
                    return value
                },
            },
        }), {}))
    }

    map(fn) {
        if (!this.__value) return this
        return new IterableObject(this.reduce((acc, value, key) => ({
            ...acc,
            [key]: fn(value, key, this),
        }), {}))
    }

    forEach(fn) {
        if (!this.__value) return
        Object.keys(this.__value).forEach((key) => fn(this.__value[key], key, this))
    }

    reduce(fn, initial) {
        if (!this.__value) return initial
        return Object.keys(this.__value).reduce((acc, key) => {
            return fn(acc, this.__value[key], key, this)
        }, initial)
    }

    filter(fn) {
        return new IterableObject(this.reduce((acc, value, key) => {
            if (!fn(value, key, this)) return acc
            return {
                ...acc,
                [key]: value,
            }
        }, {}))
    }

    entries() {
        if (!this.__value) return []
        return Object.keys(this.__value).map(key => [key, this.__value[key]])
    }

    toArray() {
        // @ts-ignore
        return [...this]
    }

    [Symbol.iterator]() {
        if (!this.__value) return {
            next() {
                return {
                    done: true,
                }
            },
        }
        const self = this
        const keys = Object.keys(this.__value)
        let index = 0
        return {
            next() {
                if (index < keys.length) {
                    const currentIndex = index++
                    const test = self.__value[keys[currentIndex]]
                    console.log('test', test)
                    return {
                        value: test,
                        done : false,
                    }
                } else {
                    return {
                        done: true,
                    }
                }
            },
        }
    }

    static withKeys(initial) {
        if (!initial) return new IterableObject({})
        return new IterableObject(Object.keys(initial).reduce((acc, key) => ({
            ...acc,
            [key]: {
                key,
                ...initial[key],
            },
        }), {}))
    }
}
