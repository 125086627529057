import {Component, h} from 'preact'
import {media, styled} from '../../style/styled'
import {managerTheme} from './managerTheme'
import {RoundButton} from './Buttons'
import {HelpCircle} from 'preact-feather'
import {appTheme} from '../../app/style/appTheme'
import {getFileUrl} from '../../utils/files'
import {currentCustomer} from '../Customer/customer.accessors'
import {formatCampaignForApp} from '../../app/AppDataWrapper'


export const Page = ({title, shouldScroll, tip, children, innerRef}) =>
    <PageContainer shouldScroll={shouldScroll} ref={innerRef}>
        <PageTitleContainer>
            <PageTitle>{title}</PageTitle>
            {tip && <InfoButton type="button">
                <HelpCircle size='30px'/>
                <Info>{tip}</Info>
            </InfoButton>}
        </PageTitleContainer>
        {children}
    </PageContainer>

export const InfoButton = RoundButton.extends({
    position     : 'relative !important',
    '& > p'      : {},
    '&:hover > p': {
        display: 'block !important',
    },
    zIndex       : 1,
})

export const Info = styled('p')({
    position       : 'absolute',
    display        : 'none',
    top            : '100%',
    whiteSpace     : 'pre-line',
    textAlign      : 'left',
    fontSize       : '1.6em',
    backgroundColor: managerTheme.colors.action,
    color          : managerTheme.colors.text,
    boxShadow      : managerTheme.shadow(),
    padding        : '1em',
    borderRadius   : '0.5em',
    zIndex         : '9999',
})

export const ContentCard = styled('div')({
    backgroundColor: managerTheme.colors.cardBackground,
    display        : 'flex',
    flexDirection  : 'column',
    alignItems     : 'stretch',
    justifyContent : 'center',
    flex           : '1 0 auto',
    minWidth       : '60%',
    padding        : '0 20px',
    borderRadius   : '8px',
    margin         : '18px',
})


export const ContentWithPreviewCard = styled('div')({
    backgroundColor: managerTheme.colors.cardBackground,
    boxShadow      : managerTheme.shadow(20),
    display        : 'flex',
    flexDirection  : 'column',
    alignItems     : 'stretch',
    flex           : '0 0 auto',
    padding        : '15px',
    margin         : '0 auto',
    overflowY      : 'scroll',
    borderRadius   : '0.4rem',
    maxWidth       : '100vw',
    [media(1025)]  : {
        width: '60%',
    },
    '& > *'        : {
        flex: '0 0 auto'
    }
})
const logoPathFromCampaign = (campaignId) => `campaigns/${campaignId}/logo`

export class PreviewCard extends Component {
    state = {
        logo: null,
    }

    getChildContext() {
        return {
            addActions: (...args) => {
                this.props.onAddActions && this.props.onAddActions(...args)
                return Promise.resolve()
            },
            goTo      : (...args) => {
                this.props.onUrlChange && this.props.onUrlChange(...args)
            },
            now: new Date(),
            theme     : {
                ...appTheme,
                logo  : this.state.logo,
                colors: {
                    ...appTheme.colors,
                    main  : this.props.settings?.colors?.main || appTheme.colors.main,
                    topBar: this.props.settings?.colors?.second || appTheme.colors.topBar,
                    second: this.props.settings?.colors?.third || appTheme.colors.second,
                }
            }
        }
    }

    render({children, ...props}, {}, _) {
        return <PreviewCardStyle {...props}>
            <Intermediate>
                {children}
            </Intermediate>
        </PreviewCardStyle>
    }
}

const PreviewCardStyle = styled('div')((_, theme) => ({
    display        : 'flex',
    fontSize       : '2vmin',
    [media(1025)]  : {
        fontSize: '0.8vw',
    },
    flex           : '0 0 auto',
    alignSelf      : 'center',
    background     : `url(${require('./images/phone.png')})`,
    backgroundSize : '100% 100%',
    backgroundColor: theme.colors.main,
    boxSizing      : 'content-box',
    padding        : '4.5em 1.27em 7.6em',
    width          : '22em',
    height         : '39em',
    flexDirection  : 'column',
    overflow       : 'hidden',
    borderRadius   : '2.75em',
}))
const Intermediate = styled('div')({
    overflow     : 'hidden',
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 1 auto',
    position     : 'relative',
})

export const PreviewRow = styled('div')({
    display       : 'flex',
    justifyContent: 'space-evenly',
    flex          : '1 1 auto',
    alignSelf     : 'stretch',
    overflow      : 'hidden',
})

const PageContainer = styled('section')(({shouldScroll = true}) => ({
    display      : 'flex',
    maxWidth     : '100vw',
    flex         : '1',
    flexDirection: 'column',
    overflowY    : shouldScroll ? 'auto' : 'hidden',
    fontSize     : '1rem',
    alignItems   : 'stretch',
    [media(1025)]: {
        alignItems: 'start',

    }
}))
const PageTitleContainer = styled('div')({
    minWidth      : '60%',
    maxWidth      : '100%',
    margin        : '4vh 0 0.5vh 0',
    alignSelf     : 'flex-start',
    display       : 'flex',
    justifyContent: 'start',
    flex          : '0 0 auto',
})
const PageTitle = styled('h1')({
    color        : managerTheme.colors.text,
    margin       : '0 15px 15px 70px',
    lineHeight   : '0.9',
    fontSize     : '2.4em',
    textTransform: 'capitalize',
    flex         : '0 0 auto',
})

