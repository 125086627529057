import {Component, h} from 'preact'
import nanoid from 'nanoid'
import ProgressBar from 'progressbar.js'
import {managerTheme} from './managerTheme'

export class CustomProgressBar extends Component {
    state = {
        id: nanoid()
    }
    lastRef = null

    attatch = (ref) => {
        if (this.lastRef === ref) return
        if (this.bar) {
            this.destroy()
        }
        if (ref == null) {
            return
        }

        this.bar = new (this.props.line ? ProgressBar.Line : ProgressBar.Circle)(ref, {
            // options
            color     : managerTheme.colors.action,
            trailColor: managerTheme.colors.cardBackground,
        })
        this.bar.animate(this.props.progress)
        this.lastRef = ref
    }

    destroy() {
        this.bar?.destroy()
        this.bar = null
    }

    componentWillReceiveProps({progress}) {
        this.bar?.animate(progress)
    }

    componentWillUnmount() {
        this.destroy()
    }

    render(props, {id}, _) {
        return <div {...props} ref={this.attatch} key={id}/>
    }
}
