// @ts-ignore
import Emittery from 'emittery'
import {getIn, mergeIn, setIn} from 'timm'
import {completePathFromRef} from './onlineActions'

export type Watcher<T> = {
    parent: Watcher<any>,
    key: string,
    silentUpdate(state): void;
    get(): T;
    get<K extends keyof T>(path: K): T[K];
    get(path?: string): any;
    put(path?: string): (data) => void;
    patch(path?: string): (data) => void;
    subscribe(fn: (T) => void): Emittery.UnsubscribeFn;
    on(fn: (T) => void): (T) => void;
    off(fn: (T) => void): void;
    onDestroy(fn): Emittery.UnsubscribeFn;
    destroy(): void;
}

export function Watcher<T>(initialState: T): Watcher<T> {
    const bus = new Emittery()
    let tree = initialState
    return {
        parent      : null,
        key         : null,
        silentUpdate: (state) => {
            tree = state
        },
        get         : (path?: string) => {
            if (!path || path === '/') {
                return tree
            }

            return getIn(tree, formatPathForTim(path))
        },
        put         : (path) => (data) => {
            if (data && data.target) {
                data = data.target.type === 'checkbox' ? !(getIn(tree, formatPathForTim(path))) : data.target.value
            }
            try {
                if (!path || path === '/') {
                    tree = data
                    return
                }
                tree = setIn(tree, formatPathForTim(path), data)
            } catch (e) {
                console.error(e)
            } finally {
                bus.emit('data', tree)
            }
        },
        patch       : (path) => (data) => {
            try {
                if (!path || path === '/') {
                    tree = Object.assign({}, tree || {}, data || {})
                    return
                }

                tree = mergeIn(tree, formatPathForTim(path), data)
            } catch (e) {
                console.error(e)
            } finally {
                bus.emit('data', tree)
            }
        },
        subscribe(fn) {
            fn(tree)
            return bus.on('data', fn)
        },
        off(fn) {
            bus.off('data', fn)
        },
        on(fn) {
            bus.on('data', fn)
            return fn
        },
        onDestroy(fn) {
            return bus.on('destroy', fn)
        },
        destroy() {
            bus.emit('destroy')
            bus.clearListeners()
        },
    }
}

export function childWatcher<T, K extends keyof T>(watcher: Watcher<T>, rootPath: K): Watcher<T[K]> {
    const usablePath = formatPathForTim(rootPath)
    return {
        parent      : watcher,
        key         : rootPath as string,
        silentUpdate: (state) => {
            const newVar = watcher.get()
            watcher.silentUpdate(setIn(newVar, usablePath, state))
        },
        get         : (path?: string) => {
            return getIn(watcher.get(), formatPathForTim((rootPath as String) + '/' + (path || '')))
        },
        put         : (path) => watcher.put((rootPath as String).concat(path || '')),
        patch       : (path) => watcher.patch((rootPath as String).concat(path || '')),
        subscribe(fn) {
            return watcher.subscribe((state) => {
                fn(getIn(state, usablePath))
            })
        },
        on (fn) {
            return watcher.on((state) => {
                fn(getIn(state, usablePath))
            })
        },
        off(fn) {
            return watcher.off(fn)
        },
        onDestroy(fn) {
            return watcher.onDestroy(fn)
        },
        destroy() {
            // TODO handle destroy
        },
    }
}

export function recursiveChildWatcher(watcher: Watcher<any>, rootPath: string): Watcher<any> {
    return formatPathForTim(rootPath)
        .reduce((parent, key) => {
            // @ts-ignore
            return childWatcher(parent, key)
        }, watcher)
}

export const ConnectedWatcher = (reference, fallback) => {
    const path = completePathFromRef(reference)
    const data = silentParse(localStorage.getItem(path)) || fallback
    const watcher = Watcher(data)
    const cb = reference.on('value', (snapshot) => {
        watcher.put('')(snapshot.val())
    })
    watcher.subscribe((tree) => {
        localStorage.setItem(path, JSON.stringify(tree))
    })
    watcher.onDestroy(() => {
        reference.off('value', cb)
    })

    return watcher
}

const splitter = /[.\/]/g

function formatPathForTim(path) {
    return path.split(splitter).filter(Boolean)
}


function silentParse(string) {
    try {
        return JSON.parse(string)
    } catch (e) {
        return null
    }
}
