export const appTheme = {
    colors: {
        main          : '#7ECBD8',
        second        : '#2c3e50',
        third         : '#1abc9c',
        topBar        : '#97D5E0',
        text          : '#4A4A4A',
        background    : '#f5f5f5',
        valid         : '#96d167',
        lightValid    : '#d6efbd',
        cancel        : '#ee5763',
        create        : '#feec8a',
        modalRefuse   : '#bfd1d5',
        modalAccept   : '#638d95',
        cardBackground: '#daecf0',
        inverted      : {
            get text() {
                return appTheme.colors.background
            },
            get background() {
                return appTheme.colors.text
            },
        },
    },
    logo  : require('../../style/img/logo-white.png'),
    shadow: (size = 5) => `0 ${Math.floor(3 * size / 5)}px ${size}px 0 rgba(0, 0, 0, 0.35)`,
}
