import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {ModalCard} from '../style/Cards'


export class Modals extends Component {
    state = {
        popups: [],
    }

    getChildContext() {
        const modal = {
            popups: {
                open   : (props) => {
                    const popup = {
                        ...props
                    }
                    return new Promise((resolve) => {
                        popup.close = resolve
                        this.setState({
                            popups: this.state.popups.concat(popup)
                        })
                    }).then(value => {
                        this.setState({
                            popups: this.state.popups.filter(p => p !== popup)
                        })
                        return value
                    })
                },
                alert  : (props) => {
                    return modal.popups.open({
                        content: () => <ModalTitle>{props.content}</ModalTitle>,
                        buttons: [{
                            content: 'Ok',
                        }]
                    })
                },
                confirm: (props) => {
                    return modal.popups.open({
                        content: () => typeof props.content === 'string' ?
                            <ModalTitle>{props.content}</ModalTitle> : props.content,
                        buttons: [
                            {
                                content : 'Oui',
                                onSelect: ({close}) => {
                                    close(true)
                                }
                            },
                            {
                                content : 'Non',
                                onSelect: ({close}) => {
                                    close(false)
                                }
                            },
                        ],
                    })
                }
            }
        }
        return modal
    }

    render({children, Component}, {start, popups}, _) {
        return <Component className={popups.length > 0 ? 'modal-opened' : ''}>
            {children}
            {
                popups.map((props) => <Popup {...props}/>)
            }
        </Component>
    }
}

export const Popup = ({content: Content, buttons, ...popupProps}) => <Backdrop className="modal">
    <Container>
        <PopupCard>
            <Content {...popupProps}/>
        </PopupCard>
        <ButtonRow>
            {
                buttons?.map(({props, content, onSelect}, i) => <PopupButton
                        key={'popup-button-' + i}
                        {...(props || {})}
                        onClick={onSelect ? () => onSelect(popupProps) : popupProps.close}
                    >{content}</PopupButton>
                )
            }
        </ButtonRow>
    </Container>
</Backdrop>


const Container = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    flex          : '1 0 auto',
    justifyContent: 'center',
    alignItems    : 'center',
    textAlign     : 'center',
    margin        : 'auto',
})
const PopupCard = styled(ModalCard)({
    flex        : '0 0 auto !important',
    alignSelf   : 'stretch',
    marginBottom: '1em !important',
    maxWidth    : '15em',
    padding     : '0.7em 0.85em !important',
})
const Backdrop = styled('div')({
    position: 'fixed',
    top     : 0,
    bottom  : 0,
    left    : 0,
    right   : 0,

    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'flex-start',
    alignItems    : 'center',
    overflowY     : 'auto',

    backgroundColor: 'rgba(0,0,0, 0.35)',
    zIndex         : 99999,
})
const ButtonRow = styled('div')({
    borderRadius: '0.4em',
    overflow    : 'hidden',
})
const PopupButton = styled('button')({
    outline      : 'none',
    border       : 'none',
    fontSize     : '1.2em',
    textTransform: 'uppercase',
    fontWeight   : 'bold',
    padding      : '0.5em',
    margin: 0,
}, ({color}, theme) => color ? {
    color: color,
} : {
    color: theme.colors.inverted.text,

    '&:last-of-type': {
        color: theme.colors.text,
    },
}, ({backgroundColor}, theme) => backgroundColor ? {
    backgroundColor: backgroundColor,
} : {
    backgroundColor: theme.colors.modalAccept,

    '&:last-of-type': {
        backgroundColor: theme.colors.modalRefuse,
    },
})

export const ModalTitle = styled('h2')({
    margin   : 0,
    textAlign: 'center',
    fontSize : '1.2em',
})
