import {InputTextWhite, LoginFormContainer} from '../style/ui/forms'
import {TitlePage} from '../style/ui/text'
import {ButtonWhite} from '../style/ui/buttons'
import {TextLink} from '../style/ui/links'
import {forScreen} from '../../common/i18n'
import {loginUrls} from './loginUrls'
import {h} from 'preact'

const t = forScreen('login')


export const ForgotPassword = ({errors}, {goTo}) => <LoginFormContainer>
    <TitlePage>{t('forgotPassword')}</TitlePage>
    {errors}
    <InputTextWhite placeholder={t('email')} name="email"/>
    <ButtonWhite type="submit">{t('send')}</ButtonWhite>
    <TextLink onClick={() => goTo(loginUrls.login)}>{t('iRemember')}</TextLink>
</LoginFormContainer>
