import {Action, ActionsListeners, Database} from '../database'
import {UserTypes} from './User.types'
import {addFlouz} from '../../web/app/PlayerActions/player.reducers'
import {user} from 'firebase-functions/lib/providers/auth'

export const UserReducers: ActionsListeners = {
    [UserTypes.create]       : [updateProfile],
    [UserTypes.updateProfile]: [updateProfile],
    [UserTypes.deleteProfile]: [deleteProfile],
}

async function updateProfile(action: Action, database: Database) {
    const {
        triggeredBy, payload: {
            name,
            currentCampaign,
            team,
            temporaryId,
            avatar,
            receiveNotifications,
            receiveEmails,
        },
    } = action

    const updateData: any = {}
    if (name != null) {
        updateData.name = name
    }
    if (currentCampaign != null) {
        updateData.currentCampaign = currentCampaign
    }
    if (avatar != null) {
        updateData.avatar = avatar
    }
    const userRef = database.ref('users').child(triggeredBy)
    await userRef.update(updateData)

    if (receiveNotifications != null) {
        await userRef.child('settings').child('notification').set(receiveNotifications)
    }
    if (receiveEmails != null) {
        await userRef.child('settings').child('email').set(receiveEmails)
    }
    if (currentCampaign && team) {
        const invited = database.ref('campaigns').child(currentCampaign).child('users').child(triggeredBy)
        const teamRef = invited.child('team')
        await teamRef.set(team)
        const invites = (await teamRef.child('invites').once('value')).val()

        const index = invites.findIndex(({temporaryId: id}) => id === temporaryId)
        if (index !== null && index >= 0) {
            await teamRef.child('invites').child(index).child('joined').set(true)
            const inviter = database.ref('campaigns').child(currentCampaign).child('users').child(invites[index].invitedBy)
            await addFlouz(inviter, 1)
        }
    }
    const userResult = (await userRef.once('value')).val()
    if (userResult.avatar && !userResult.completed) {
        userRef.child('completed').set(true)
        await addFlouz(userRef, 1)
    }
}

async function deleteProfile(action: Action, database: Database, {notify}) {
    await database.ref('users').child(action.triggeredBy).remove()
}
