import nanoid from 'nanoid'
import {createCampaign, removeCampaign} from './campaigns.creators'
import {currentCustomer} from '../Customer/customer.accessors'
import {database} from '../../firebase'

export const getUserCampaignsRef = async () => {
    const customer = await currentCustomer()
    return customer.ref.child('campaigns')
}

export const initializeCampaign = async () => {
    const key = nanoid()
    const campaignsRef = await getUserCampaignsRef()
    await createCampaign(campaignsRef, key, null, null)
    return key
}

export const deleteCampaign = async ({key}) => {
    const customer = await currentCustomer()
    await removeCampaign(customer.ref.child('campaigns').child(key))
}
