import {Component, h} from 'preact'
import {Router} from 'preact-router'
import {Login} from './Login.screen'
import {ForgotPassword} from './ForgotPassword.screen'
import {ForgotPasswordSuccess} from './ForgotPasswordSuccess.screen'
import {SignUp} from './AppSignup.screen'
import {SignupSuccess} from './SignupSuccess.screen'
import {LoginForm} from '../style/ui/forms'
import {extractFormData} from '../utils/forms'
import {styled} from '../style/styled'
import {fromFirebaseError} from '../../common/i18n'
import {loginUrls} from './loginUrls'
import {LogoCivitime} from '../style/ui/identity'
import {auth, database, firebaseApp} from '../firebase'
import {ResetPassword} from './ResetPassword.screen'
import {ResetPasswordSuccess} from './ResetPasswordSuccess.screen'
import {UserTypes} from '../../common/User/User.types'
import {history} from '../utils/history'
import {fromBaseUrl} from '../utils/appRoute'

export class LoginController extends Component {
    state = {
        errors: [],
        status: loginUrls.login,
        socialCredentials: null,
        email: '',
    }

    componentDidMount() {
        auth.getRedirectResult()
            .then(async user => {
                if (!auth.currentUser) return
                await (this.context.addActions && this.context.addActions({
                    type   : UserTypes.updateProfile,
                    payload: {
                        name           : auth.currentUser.displayName,
                        currentCampaign: getCodeFromDomain(),
                    }
                }))
            })
            .catch(async (error) => {
                this.setState({
                    errors: [error],
                    socialCredentials: error.credential,
                    email: error.email
                })
            })
    }

    handleRouteChange = async (e) => {
        console.log(e)
        if (e.url.indexOf('redirect-auth') >= 0) {
            const [url, search] = e.url.split('?')
            const data = search.split('&').reduce((acc, s) => {
                const [key, value] = s.split('=')
                return ({
                    ...acc,
                    [key]: value,
                })
            }, {})
            this.setState({
                code: data.oobCode,
            }, () => {
                console.log(data)
                e.router.routeTo(fromBaseUrl(loginUrls[data.mode]))
            })
        } else {
            console.log("url ?", e)
            this.setState({
                status: e.url,
            })
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        const data = extractFormData(event.target)
        this.setState({
            errors: []
        }, async () => {
            try {
                if (this.state.status?.indexOf('invite') >= 0) {
                    return await this.signUp(data)
                }
                if (this.state.status?.indexOf('join') >= 0) {
                    return await this.signUp(data)
                }
                switch (this.state.status) {
                    case loginUrls.signUp:
                        return await this.signUp(data)
                    case loginUrls.forgotPassword:
                        return await this.forgotPassword(data)
                    case loginUrls.resetPassword:
                        return await this.resetPassword(data)
                    case loginUrls.login:
                    default:
                        return await this.login(data)
                }
            } catch (e) {
                this.addErrors(e)
            }
        })
    }

    loggedInInvite = async (data) => {
        this.waiting = true
        const user = (await database.ref('users').child(this.context.authUser?.uid).once('value')).val()
        const code = data.code && data.code.toLowerCase()
        if (user.currentCampaign && user.currentCampaign !== code) {
            const campaignName = (await database.ref('campaigns').child(code).child('settings/name').once('value')).val()
            const result = await this.context.popups.confirm({
                content: `Rejoindre la camapgne "${campaignName}" ?`
            })
            if (!result) {
                this.context.goTo('/')
                return
            }
        }
        if (data.team) {
            const teamName = (await database.ref('campaigns').child(code).child('teams').child(data.team).child('name').once('value')).val()
            const result = await this.context.popups.confirm({
                content: `Rejoindre l'équipe "${teamName}" ?`
            })
            if (!result) {
                this.context.goTo('/')
                return
            }
        }
        await this.context.addActions({
            type   : UserTypes.updateProfile,
            payload: {
                currentCampaign: code,
                team           : data.team,
                temporaryId    : data.temporaryId,
            }
        })
        this.context.goTo('/')
    }

    login = async (data) => {
        const user = await firebaseApp.auth().signInWithEmailAndPassword(data.email, data.password)
        if (this.state.socialCredentials) {
            await auth.currentUser.linkWithCredential(this.state.socialCredentials)
        }
        /*if (!user?.user?.emailVerified) {
         throw {
         code: 'auth/user-not-verified',
         }
         }*/
    }
    resetPassword = async (data) => {
        if (data.password !== data.passwordConfirm) {
            throw {code: 'auth/not-same-password'}
        }
        try {
            await firebaseApp.auth().confirmPasswordReset(this.state.code, data.password)
            this.context.goTo(loginUrls.login)
        } catch (e) {
            throw {
                code: 'auth/code-used'
            }
        }
    }
    verifyEmail = async (data) => {
        // nothing done for this right now...
    }
    signUp = async (data) => {
        if ('code' in data) {
            const exists = (await database.ref('campaigns').child(data.code.toLowerCase()).once('value')).exists()
            if (!exists) throw {
                code: 'auth/wrong-code'
            }
        }

        const user = await firebaseApp.auth().createUserWithEmailAndPassword(data.email, data.password)
        if (user?.user) {
            const realUser = user.user
            await realUser.updateProfile({
                displayName: data?.name,
            })

            try {
                await (this.context.addActions && this.context.addActions({
                    type   : UserTypes.updateProfile,
                    payload: {
                        name           : data?.name,
                        currentCampaign: data?.code.toLowerCase(),
                        team           : data?.team,
                    }
                }))
            } catch (e) {
                // TODO anything to do ?
            }
        }
        this.context.goTo(loginUrls.signUpSuccess)
    }
    forgotPassword = async (data) => {
        await firebaseApp.auth().sendPasswordResetEmail(data.email)
        this.context.goTo(loginUrls.forgotPasswordSuccess)
    }
    addErrors = (...errors) => {
        this.setState(({errors: oldErrors}) =>
            ({
                errors: oldErrors.concat(errors)
            })
        )
    }
    removeError = (error) => {
        return () => this.setState({
            errors: this.state.errors.filter(e => e !== error)
        })
    }

    render(props, {errors, email}, _) {
        const {
            LoginScreen = Login,
            SignUpScreen = SignUp,
            SignupSuccessScreen = SignupSuccess,
            ForgotPasswordScreen = ForgotPassword,
            ForgotPasswordSuccessScreen = ForgotPasswordSuccess,
        } = (props.components || {})
        const {
            loggedIn,
            child: Child
        } = props

        const renderedErrors = <FireErrors>
            {errors.map((error) => <FireError>
                <FireMessage>{fromFirebaseError(error)}</FireMessage>
                <FireClose onClick={this.removeError(error)}>X</FireClose>
            </FireError>)}
        </FireErrors>
        return loggedIn
            ? <Router key="log-router" history={history}>
                <Child default key="auth-root"/>
                <InviteForm path={loginUrls.invite} onSubmit={this.handleSubmit} errors={renderedErrors}
                            loggedInInvite={this.loggedInInvite} SignUpScreen={SignUpScreen} waiting={this.waiting}/>
            </Router>
            : <LoginForm onSubmit={this.handleSubmit}>
                <LogoCivitime src={require('../style/img/logo-white.png')} alt="Logo"/>
                <Router onChange={this.handleRouteChange} history={history}>
                    <LoginScreen path={loginUrls.login} errors={renderedErrors} email={email} default/>
                    <ForgotPasswordScreen path={loginUrls.forgotPassword} errors={renderedErrors}/>
                    <ForgotPasswordSuccessScreen path={loginUrls.forgotPasswordSuccess}/>
                    <SignUpScreen path={loginUrls.signUp} errors={renderedErrors}/>
                    <SignUpScreen path={loginUrls.invite} errors={renderedErrors}/>
                    <SignUpScreen path={loginUrls.join} errors={renderedErrors}/>
                    <SignupSuccessScreen path={loginUrls.signUpSuccess}/>
                    <ResetPassword path={loginUrls.resetPassword} errors={renderedErrors}/>
                    <ResetPasswordSuccess path={loginUrls.resetPasswordSuccess}/>
                </Router>
            </LoginForm>
    }
}

const InviteForm = ({handleSubmit, SignUpScreen, ...props}) => <LoginForm onSubmit={handleSubmit}>
    <LogoCivitime src={require('../style/img/logo-white.png')} alt="Logo"/>
    <SignUpScreen {...props}/>
</LoginForm>

const FireErrors = styled('div')({
    alignSelf: 'stretch',
    margin   : '0 20px',
})

const FireError = styled('div')({
    display        : 'flex',
    flexDirection  : 'row',
    alignItems     : 'flex-start',
    justifyContent : 'space-between',
    backgroundColor: 'indianred',
    color          : 'white',
    width          : '100%',
    alignSelf      : 'stretch',
    padding        : '5px',
    boxSizing      : 'border-box',
})

const FireMessage = styled('p')({
    flex  : '1',
    margin: 0,
})

const FireClose = styled('a')({
    textDecoration: 'none',
    color         : 'white',
    fontWeight    : 'bold',
    cursor        : 'pointer',
})

const codesToIgnore = ["app", "localhost"]
function getCodeFromDomain() {
    const [sub] = window.location.hostname.split('.')
    return !codesToIgnore.includes(sub) ? sub : null
}