import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import Gauge from '../../style/img/icons/btn-rse.svg'
import Target from '../../style/img/icons/cibles RSE.svg'
import Heart from '../../style/img/icons/coeur.svg'
import Leaf from '../../style/img/icons/feuille.svg'
import {Progress} from '../style/Progress'

const maxLevel = 100

export const CiviJobsBar = (props) => <div {...props}>
    <JobLevel id="entreprise-details"/>
    <OpenJobScreen id="launch-jobs"/>
</div>

class JobLevel extends Component {
    state = {
        move: 0,
    }

    toggle = (event) => {
        if (this.state.move > 0) {
            this.setState({
                move: 0,
            })
        } else {
            const items = this.base.querySelectorAll('.gauges-content')

            let width = 0
            for (let i = 0; i < items.length; i++) {
                width += items[i].offsetWidth
            }

            this.setState({
                move: width,
            })
        }
    }

    render({id}, {move}, {userData}) {
        const socialPoints = userData?.socialPoints || 0
        const environmentPoints = userData?.environmentPoints || 0
        const total = socialPoints + environmentPoints
        return <JobButton onClick={this.toggle} move={move} id={id}>
            <GaugeContainer>
                <CompanyGauge level={total}/>
                <Level>{total}<br/>/{maxLevel}</Level>
            </GaugeContainer>
            <Gauges className="gauges-content">
                <BarLabel>Environnement {environmentPoints}/{50}</BarLabel>
                <Progress
                    icon={Leaf}
                    progress={environmentPoints / 50}
                />
                <BarLabel>Social {socialPoints}/{50}</BarLabel>
                <Progress
                    icon={Heart}
                    color={this.context.theme.colors.cancel}
                    progress={socialPoints / 50}
                />
            </Gauges>
        </JobButton>
    }
}

const OpenJobScreen = (props, context) => <JobButton
    id={props.id}
    color="rgba(230,230,230, 0.7)"
    onClick={() => context.goTo('/jobs')}
>
    <GaugeContainer>
        <TargetIcon/>
    </GaugeContainer>
</JobButton>

const size = 3
const levelColor = '#FEF5CB'
const JobButton = ({move, children, id, ...props}) => <JobButtonWrapperContainer>
    <JobButtonWrapper move={move}>
        <JobButtonContainer {...props} id={id}>
            {children}
        </JobButtonContainer>
    </JobButtonWrapper>
</JobButtonWrapperContainer>

const JobButtonWrapperContainer = styled('div')({
    padding: '0.5em 0',
})
const JobButtonWrapper = styled('div')({
    height    : `${size}em`,
    width     : `${size}em`,
    display   : 'flex',
    alignItems: 'stretch',
    transition: 'all ease-in-out 350ms',

    '& > *': {
        flex: '0 0 auto',
    },
}, ({move}) => move ? {
    transform: `translateX(-${move}px)`
} : {})

const JobButtonContainer = styled('div')({
    display               : 'flex',
    alignItems            : 'stretch',
    '& > *'               : {
        flex: '0 0 auto',
    },
    borderBottomLeftRadius: `${size / 2}em`,
    borderTopLeftRadius   : `${size / 2}em`,
    paddingRight          : '0.3em',
    overflow              : 'visible',
    boxShadow             : `0px 0px 1px 4px rgba(0,0,0, 0.6)`,
    cursor                : 'pointer',
}, ({color}) => ({
    backgroundColor: color || levelColor,
}))

const GaugeContainer = styled('div')({
    height        : `${size}em`,
    width         : `${size}em`,
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    position      : 'relative',
})
const full = 115
const CompanyGauge = styled(Gauge)(({level}) => ({
    height        : `${size * 0.8}em`,
    '& > .st1-rse': {
        transition      : 'all ease-in-out 350ms',
        strokeDashoffset: full - (level / maxLevel) * full
    },
}))
const TargetIcon = styled(Target)({
    height: `${size * 0.8}em`,
})

const Level = styled('span')({
    position  : 'absolute',
    top       : '1.9em',
    left      : '1.9em',
    fontWeight: 'bolder',
    fontSize  : '0.5em',
    textAlign : 'center',
})

const Gauges = styled('div')({
    width  : '10em',
    padding: '0 1em',
})

const BarLabel = styled('div')({
    fontSize  : '0.5em',
    fontWeight: 'bold',
})
