import {Component, h} from 'preact'
import nanoid from 'nanoid'
import {styled} from '../../style/styled'
import {RoundButton} from './Buttons'
import {Camera} from 'preact-feather'
import {CustomProgressBar} from './Progressbar'
import {uploadFileFromDataUrl} from '../../utils/fileUrl'
import {managerTheme} from './managerTheme'

export class UploadInput extends Component {
    state = {
        id      : null,
        progress: 0,
        changed : false,
    }

    componentWillMount() {
        this.setState({
            id   : nanoid(),
            value: this.props.value
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value  : nextProps.value,
                changed: false,
            })
        }
    }

    fileSelected = (event) => {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            reader.onload = async (e) => {
                const url = e.target.result
                this.setState({value: url, progress: 0, changed: true})
                this.props.onChange && this.props.onChange(url)
            }

            reader.readAsDataURL(input.files[0])
        }
    }

    upload = async () => {
        if (!this.state.changed) return
        const uploadResponse = await uploadFileFromDataUrl(this.state.value, this.props.path)
        uploadResponse.on('state_changed', async (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
            this.setState({
                progress,
            })
        })

        await uploadResponse.promise
        const downloadUrl = await uploadResponse.getUrl()
        this.props.onChange && this.props.onChange(downloadUrl)
        this.setState({
            progress: 1,
        })
    }

    render({size = '5vw', line, children}, {id, value = require('!!from-file!../ui/images/upload.svg')}, _) {
        return <Container for={id} size={size} line={line}>
            {
                line
                    ? <LineProgress progress={this.state.progress} size={size} value={value}/>
                    : <CircleProgress progress={this.state.progress} size={size}
                                      value={value} for={id}/>
            }

            {line && <UploadButton style={buttonStyle(line ? '100px' : size)} type="button" size='large'>
                <label for={id}><Camera/></label>
            </UploadButton>}
            <HiddenInput type="file" id={id} onChange={this.fileSelected} accept="image/*"/>
        </Container>
    }
}

const UploadButton = RoundButton.with('div')

const CircleProgress = ({value, size, progress, children, for: target}) => {
    return <CircleContainerPreview for={target}>
        <Preview src={value} size={size}/>
        <Progress progress={progress} line={false}/>
        {children}
    </CircleContainerPreview>
}

const CircleContainerPreview = styled('label')(({size}) => ({
    position: 'relative',
    width   : size,
    height  : size,
    margin  : 'auto',
}))

const LineProgress = ({value, progress, size, children}) => {
    return <LineProgressContainer value={value}>
        <div>{children}</div>
        <RawLineProgress progress={progress} line={true}/>
    </LineProgressContainer>
}

const LineProgressContainer = styled('div')(({size = '120px', value}) => ({
    backgroundImage   : `url(${value})`,
    backgroundPosition: 'center center',
    backgroundSize    : 'cover',
    height            : size,
    display           : 'flex',
    flexDirection     : 'column',
    justifyContent    : 'center',
    alignItems        : 'center',
    alignSelf         : 'stretch',
    width             : '100%',
}))

const buttonStyle = (size) => {
    const number = parseInt(size)
    const unit = size.toString().replace(number, '')
    const value = `${number / 10}${unit}`
    return ({
        position: 'absolute',
        bottom  : value,
        right   : value,
    })
}

const Container = styled('div')(({size, line}) => ({
    position      : 'relative',
    display       : 'flex',
    alignSelf     : line ? 'stretch' : 'center',
    width         : line ? '100%' : `calc(${size} + 3em)`,
    flexDirection : 'column',
    justifyContent: 'center',
    margin        : '1.5rem auto',
}))

const Preview = styled('img')(({size}) => ({
    width       : size,
    height      : size,
    objectFit   : 'cover',
    borderRadius: '50%',
    overflow    : 'hidden',
    backgroundColor: managerTheme.colors.action,
}))

const HiddenInput = styled('input')({
    display : 'block',
    width   : '1px',
    height  : '1px',
    opacity : 0,
    position: 'absolute'
})


const Progress = styled(CustomProgressBar)({
    position: 'absolute',
    top     : 0,
    bottom  : 0,
    left    : 0,
    right   : 0,
})
const RawLineProgress = styled(CustomProgressBar)({
    width : '60%',
    margin: '5px auto',
})
