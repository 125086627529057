import {auth, firebaseApp} from '../firebase'
import firebase from 'firebase'
import Logo from '../app/style/svgs/facebook-logo.svg'
import {h} from 'preact'
import {styled} from '../style/styled'
import {ButtonWhite} from '../style/ui/buttons'
import {TextLink} from '../style/ui/links'

export const FacebookButton = () => <TextLink onClick={loginFacebook}>
    <Logo  style={style}/>
    <span>Me connecter avec Facebook</span>
</TextLink>

const style= {
    width: '2em',
    margin: '0.5em'
}
const wrapperStyle = {
    verticalAlign: 'middle',
}
const Wrapper = styled('div')({

})

async function loginFacebook() {

    const provider = new firebase.auth.FacebookAuthProvider();

    await auth.signInWithRedirect(provider)
}
