import {Component, h} from 'preact'
import 'preact-compat'
import DayPicker, {DateUtils} from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import {updateCampaign} from '../Campaigns/campaigns.creators'
import humane from 'humane-js'
import {StyledLabel} from './Input'
import React from 'preact-compat'
import {Card} from './Card'
import {managerTheme} from './managerTheme'
import {addWorkDays, themesDaysCount} from '../../../common/Themes/themesCalculator'
import {styled} from '../../style/styled'

const isSameDay = require('date-fns/is_same_day')
const isBefore = require('date-fns/is_before')
const addDays = require('date-fns/add_days')
const differenceInDays = require('date-fns/difference_in_days')
const isWithinRange = require('date-fns/is_within_range')
const isWeekend = require('date-fns/is_weekend')
const format = require('date-fns/format')
const getDay = (date) => format(date, 'D')

const MONTHS = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
const DAY = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
const DAYLONG = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']


export class ThemeDatePicker extends Component {
    state = {
        from          : null,
        to            : null,
        numberOfMonths: 1,
    }

    save = async (day) => {
        if (this.notAValidDate(day)) return
        const range = addDayToRange(day, this.state)
        await this.updateRange(range, this.props.campaign)
        await updateCampaign(this.props.campaignRef?.child('settings'), {
            startAt: this.state.from ? this.state.from.toISOString() : '',
            endAt  : this.state.to ? this.state.to.toISOString() : '',
        })
        await this.uploader?.upload()
        humane.log('Enregistré')
    }

    componentDidMount() {
        if (this.props.campaign?.settings?.endAt) {
            this.updateRange({
                from: asDate(this.props.campaign.settings.startAt),
                to  : asDate(this.props.campaign.settings.endAt),
            }, this.props.campaign)
        }
    }

    componentWillReceiveProps({campaign}, _,) {
        if (campaign?.settings?.endAt) {
            this.updateRange({
                from: asDate(campaign.settings.startAt),
                to  : asDate(campaign.settings.endAt),
            }, campaign)
        }
    }

    notAValidDate = (date) => isBefore(date, addDays(new Date(), -1))

    updateRange = async (range, campaign) => {
        const minDays = (Object.keys(campaign?.themes || {}).length * 5) || 5
        const minimalEnd = addWorkDays(range.from, minDays - 1) // - 1 cause the first day is included
        if (differenceInDays(range.to, minimalEnd) < 0) {
            range.to = minimalEnd
        }
        const counts = themesDaysCount(range.from, range.to, campaign.themes)
        const colors = this.props.colors
        const colorsByDays = counts.reduce((acc, count, i) => {
            return acc.concat(
                Array(count)
                    .fill(colors[i % colors.length])
            )
        }, [])
        return new Promise(resolve => this.setState({
            ...range,
            colorsByDays,
        }, resolve))
    }

    renderDay = (day, modifiers) => {
        const {from, to, colorsByDays} = this.state
        const i = differenceInDays(day, from)
        const j = differenceInDays(day, to)
        const color = colorsByDays && colorsByDays[i]
        const noSelection = from == null
        return <ShowDay
            selected={isWithinRange(day, from, to)}
            start={i === 0}
            afterStart={i > 0}
            beforeStart={i < 0}
            noSelection={noSelection}
            weekend={isWeekend(day)}
            end={j === 0}
            afterEnd={j > 0}
            disabled={modifiers.disabled || modifiers.outside}
            color={color}
        >{getDay(day)}</ShowDay>
    }

    render({}, {from, to, colorsByDays}, _) {
        return (
            <Container>
                <StyledLabel>Choisir vos dates de campagne</StyledLabel>

                <DayPicker
                    numberOfMonths={this.props.numberOfMonths}
                    selectedDays={[from, {from, to}]}
                    showOutsideDays={true}
                    onDayClick={this.save}
                    months={MONTHS}
                    weekdaysLong={DAYLONG}
                    weekdaysShort={DAY}
                    renderDay={this.renderDay}
                    firstDayOfWeek={1}
                    disabledDays={this.notAValidDate}
                />
            </Container>
        )
    }
}

const Container = Card.withProps({color: 'white'}).extends({
    display                                      : 'flex',
    flexDirection                                : 'column',
    justifyContent                               : 'center',
    alignItems                                   : 'center',
    '& .DayPicker-wrapper'                       : {
        outline: 'none',
    },
    '& .DayPicker-Day'                           : {
        backgroundColor: 'transparent !important',
        borderRadius   : '0 !important',
        border         : 'none',
        padding        : 0,
        outline        : 'none',
    },
    '& .DayPicker-WeekdaysRow'                   : {
        opacity             : '0.62',
        backgroundColor     : '#3d3d3d',
        marginBottom        : '0.25rem',
        zIndex              : 1,
        borderTopRightRadius: '0.25rem',
        borderTopLeftRadius : '0.25rem',
    },
    '& .DayPicker-Weekday'                       : {
        color        : '#ffffff',
        paddingBottom: '1.5em',
        '& *'        : {
            textTransform: 'uppercase',
            fontWeight   : 'bolder',
        }
    },
    '& .DayPicker-Body'                          : {
        display                : 'block',
        zIndex                 : 2,
        borderBottomLeftRadius : '0.25rem',
        borderBottomRightRadius: '0.25rem',
        overflow               : 'hidden',
        padding                : '4px 0',
        backgroundColor        : '#ffffff',
    },
    '& .DayPicker-WeekdaysRow, & .DayPicker-Body': {
        boxShadow: managerTheme.shadow(16),
        position : 'relative',
    },
    '& .DayPicker-Week, & .DayPicker-WeekdaysRow': {
        display: 'flex',
        '& > *': {
            display: 'block',
            flex   : '1 1 calc(100% / 7)',
        }
    }
})

const leftRoundRadius = {
    borderBottomLeftRadius: '50%',
    borderTopLeftRadius   : '50%',
}
const rightRoundRadius = {
    borderBottomRightRadius: '50%',
    borderTopRightRadius   : '50%',
}
const ShowDay = styled('div')(({selected, color, start, end, disabled, afterStart, afterEnd, noSelection, weekend}) => ({
    backgroundColor: color || '#FFF',
    color          : do {
        if (disabled || weekend) {
            'grey'
        } else if (selected) {
            managerTheme.colors.lightText
        } else {
            managerTheme.colors.text
        }
    },
    padding        : '1rem',
    filter         : weekend ? 'grayscale(100%)' : 'none',
    opacity        : weekend ? 0.8 : 1,
    ...(!start ? {} : leftRoundRadius),
    ...(!end ? {} : rightRoundRadius),
    ...(disabled ? {} : {
        '&:hover': {
            backgroundColor: managerTheme.colors.action,
            ...hoverStatus(start, afterEnd, noSelection),
        }
    }),
}))

function asDate(date) {
    return date ? new Date(date) : null
}

function isDayBefore(d1, d2) {
    return differenceInDays(d1, d2) < 0
}

function addDayToRange(day, range = {from: null, to: null}) {
    let {from, to} = range
    if (!from) {
        from = day
    } else if (isSameDay(from, day)) {
        from = null
        to = null
    } else if (isDayBefore(day, from)) {
        from = day
    } else if (to && isDayBefore(day, to)) {
        from = day
    } else if (to && isSameDay(to, day)) {
        to = null
    } else {
        to = day
        if (isDayBefore(to, from)) {
            to = from
            from = day
        }
    }

    return {from, to}
}

function hoverStatus(isStart, afterEnd, noSelection) {
    if (noSelection) {
        return leftRoundRadius
    }
    if (isStart) {
        return {...leftRoundRadius, ...rightRoundRadius}
    }
    if (afterEnd) {
        return rightRoundRadius
    }
    return leftRoundRadius
}
