import {Component, h} from 'preact'
import {firebaseApp} from '../firebase'
import {LoginController} from './Login'
import {loginUrls} from './loginUrls'
import {FullscreenLoader} from '../style/ui/Loader'

const auth = firebaseApp.auth()

export class AuthWrapper extends Component {
    state = {
        waiting : true,
        user    : null,
        loggedIn: !!auth.currentUser,
    }
    lastUrl = '/'

    componentWillMount() {
        const loginUrl = Object.keys(loginUrls).map(key => loginUrls[key])
            .find(url => window.location.href.indexOf(url) >= 0)
        this.lastUrl = loginUrl?.length > 0 ? '/' : window.location.href
        this.setState({
            waiting: true,
        })
        auth.onAuthStateChanged((user) => {
            this.setState({
                loggedIn: !!user,
                authUser: user,
                waiting : false,
            }, async () => {
                if (user) {
                    this.context.goTo(this.lastUrl)
                } else if (user && !user.emailVerified) {
                    // TODO : call the function
                    //await user.sendEmailVerification()
                }
            })
        })
    }

    shouldComponentUpdate({}, {loggedIn, authUser, waiting}, {}) {
        return loggedIn !== this.state.loggedIn
            || waiting !== this.state.waiting
            || authUser !== this.state.authUser
    }

    getChildContext() {
        return {
            authUser: this.state.authUser,
        }
    }

    render({children, components = {}}, {loggedIn, waiting}, _) {
        if (children.length > 1) throw new Error('Should not contain more than one child')
        if (waiting) return <FullscreenLoader/>
        return <LoginController components={components} child={() => children[0]} loggedIn={loggedIn}
                                key="inner-auth-wrapper"/>
    }
}
