import {auth, database} from '../../firebase'
import {createLogger} from '../../utils/logger'
import {addActions} from '../../utils/onlineActions'
import {createCustomer} from './customer.actions'
import {tracker} from '../../../common/tracker'

const logger = createLogger('customer.accessors')

export const currentCustomer = async () => {
    logger.log('Acquiring customer data from user')
    const currentUser = auth.currentUser
    if (currentUser) {
        try {
            const ownRef = database.ref('users').child(currentUser.uid).child('own')
            console.log(ownRef)
            let customerId = (await ownRef.once('value')).val()
            console.log(customerId)
            if (!customerId) {
                await addActions(createCustomer())
                customerId = await new Promise(resolve => {
                    const cb = ownRef.on('value', (snap) => {
                        const value = snap.exists() && snap.val()
                        if (value) {
                            ownRef.off('value', cb)
                            resolve(value)
                            tracker.push({
                                event: 'first-login',
                                cat  : 'manager',
                            })
                        }
                    })
                })
            }
            const customerPath = `customers/${customerId}`
            const dataSnapshot = await database.ref(customerPath).once('value')
            const customer = dataSnapshot.val()
            if (!customer) {
                await ownRef.remove()
                return await currentCustomer()
            }
            customer.key = dataSnapshot.key
            customer.ref = dataSnapshot.ref
            return customer
        } catch (e) {
            logger.warn('No customer found', e)
            return null
        }
    } else {
        return null
    }
}
