const dot = require('dot')

function formatEquivalence(equivalences) {
    if (Array.isArray(equivalences)) return equivalences
    return Object.keys(equivalences)
                 .map(key => equivalences[key].types)
                 .reduce((acc, item) => [...acc, ...item], [])
                 .reduce((acc, equivalence) => [...acc, ...Object.keys(equivalence.units).map(subKey => ({...equivalence, ...equivalence.units[subKey]}))], [])
}

export function renderEquivalence(equivalences, value, count, duration, isUniversity) {
    if (!value) return ''


    try {
        value = removeExtraParenthesis(value
            .replace(/{{ *it/ig, '{{= it'))
        equivalences = formatEquivalence(equivalences)
        const template = dot.template(value)
        return template({
            equivalence: (value, unit) => {
                const equivalences1 = evaluateEquivalences(equivalences, value, unit, count)
                const quantity = Math.round(equivalences1.value)
                const pluralRegex = /\.([^ ]+)/ig
                const label = quantity > 1 ? equivalences1.label.replace(pluralRegex, '$1') : equivalences1.label.replace(pluralRegex, '')
                return quantity + ' ' + label
            },
            duration   : duration,
            participants: count,
            entreprise : isUniversity ? 'l\'U.F.R.' : 'mon entreprise',
        })
    } catch (e) {
        console.warn('Compilation failed on', value)
        return value
    }
}

function evaluateEquivalences(equivalences, userValue, unit, quantity = 1) {
    try {
        return equivalences
            .filter(item => item.unit.toLowerCase() === unit.toLowerCase())
            .map((item) => {
                const value = (userValue * quantity) / item.reference
                return ({
                    ...item,
                    value: Math.round(value),
                    delta: Math.abs(Math.round(value) - value) / Math.abs(item.max - item.min),
                    in   : value >= item.min && value <= item.max,
                })
            })
            .filter(({value}) => value > 0)
            .sort((item1, item2) => {
                if (item1.in && !item2.in) {
                    return -1
                } else if (item1.in === item2.in) {
                    if (item1.value === item2.value)
                        return item1.delta - item2.delta
                    else
                        return item1.value - item2.value
                } else {
                    return 1
                }
            })[0]
    } catch (e) {
        console.warn('failed', userValue, unit)
    }
}


function removeExtraParenthesis(s) {
    let acc = []
    let count = 0
    for (let char of s) {
        if (char === '(') count++
        if (char === ')') count--
        if (count < 0) {
            count = 0
        } else {
            acc.push(char)
        }
    }
    let next = [...acc].reverse()
    acc = []
    count = 0
    for (let char of next) {
        if (char === ')') count++
        if (char === '(') count--
        if (count < 0) {
            count = 0
        } else {
            acc.push(char)
        }
    }
    return [...acc].reverse().join('')
}
