import {Component, h} from 'preact'
import {Input, RichTextArea, Select, StyledLabel} from '../../ui/Input'
import {FlatButton} from '../../ui/Buttons'
import React from 'preact-compat'
import * as nanoid from 'nanoid'
import {updateThemeList} from '../campaigns.creators'
import humane from 'humane-js'
import {styled} from '../../../style/styled'
import {managerTheme} from '../../ui/managerTheme'
import {Card} from '../../ui/Card'
import {ConfigurationTypes, ConfigurationTypesArray} from '../../../../common/ConfigurationTypes'


export class ManageTheme extends Component {
    state = {
        newName        : '',
        description    : '',
        formattedThemes: [],
    }

    componentWillMount() {
        const theme = (this.props.campaign?.themes || {})[this.props.editingKey]
        this.changeField(theme?.text, theme?.description)
    }

    componentWillReceiveProps(nextProps, _) {
        if (nextProps.editingKey !== this.props.editingKey) {
            const theme = nextProps.campaign?.themes && nextProps.campaign?.themes[nextProps.editingKey]
            this.changeField(theme?.text, theme?.description)
        }
    }

    updateName = (event) => {
        this.setState({
            newName: event.target.value
        })
    }

    updateDescription = (description) => {
        this.setState({
            description,
        })
    }

    changeField = (newName = '', description = '') => {
        this.setState({
            newName,
            description,
        })
    }

    saveTheme = () => {
        if (!this.state.selectedType) return
        const theme = this.state.selectedType === 'custom' ? {
            text       : this.state.newName,
            order      : Object.keys(this.props.campaign?.themes || {}).length,
            description: this.state.description,
            key        : this.props.editingKey || nanoid()
        } : {
            text       : ConfigurationTypes[this.state.selectedType],
            type       : this.state.selectedType,
            order      : Object.keys(this.props.campaign?.themes || {}).length,
            description: '',
            key        : this.props.editingKey || nanoid(),
        }
        this.save(theme)
        this.props.changeEditingKey()
        this.changeField()
    }

    save = async ({key, ...theme}) => {
        await updateThemeList(this.props.campaignRef, theme, key)
        await this.uploader?.upload()
        humane.log('Enregistré')
    }

    cancel = () => {
        this.props.changeEditingKey()
    }

    updateThemeType = (event) => {
        const $select = event.target
        const $selected = $select.options[$select.selectedIndex]
        console.log($selected)
        this.setState({
            selectedType: $selected?.value
        })
    }

    render({editingKey, campaign}, {newName, description, selectedType}, _) {
        const themesWithType = Object.keys(campaign?.themes ||{})
            .map(key => campaign?.themes[key].type)
            .filter(Boolean)
        return (<Container key={editingKey || 'new-item'}>
                <InputThemeWrapper>
                    <StyledLabel>{
                        editingKey
                            ? 'Modifier le thème'
                            : 'Créer un nouveau thème'
                    }</StyledLabel>
                    {!!editingKey || <Select placeholder="Type de thème" onChange={this.updateThemeType} value={selectedType}>
                        <option value="">Selectioner un type de thème</option>
                        {
                            ConfigurationTypesArray
                                .filter(item => !themesWithType.includes(item.id))
                                .map(item => <option value={item.id}>{item.name}</option>)
                        }
                        <option value="custom">Personnalisé</option>
                    </Select>}
                    {(selectedType === 'custom' || editingKey) &&
                    <Input type='text' value={newName} onInput={this.updateName}
                           placeholder={'Nom du thème'}/>
                    }
                    {(selectedType === 'custom' || editingKey) &&
                    <RichTextArea value={description}
                                  placeholder="Description"
                                  onInput={this.updateDescription}/>
                    }
                </InputThemeWrapper>
                <div>
                    <SimpleColumn>
                        <FlatButton onClick={this.cancel} key="undo" cancel={true}>Annuler</FlatButton>
                        <FlatButton onClick={this.saveTheme} key="save">Enregistrer le thème</FlatButton>
                    </SimpleColumn>
                </div>
            </Container>
        )
    }
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'row',
    alignItems   : 'flex-end',
})

const InputThemeWrapper = Card.extends({
    marginBottom: 0,
}).withProps({
    color: managerTheme.colors.cardBackground,
})

const SimpleColumn = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    marginLeft   : '1rem',
    minWidth     : '190px',
    '& > *'      : {
        marginTop: '1rem !important',
        alignSelf: 'stretch !important',
    }
})
