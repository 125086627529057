import {Action, ActionsListeners, Database} from '../../common/database'
import {ManagerTypes} from './manager.types'
import {campaignActions} from './Campaigns/campaigns.actions'

export const ManagerActions: ActionsListeners = {
    [ManagerTypes.create]: [create],
    [ManagerTypes.update]: [update],
    [ManagerTypes.delete]: [remove],
    ...campaignActions,
}

async function create(action: Action, database: Database) {
    const {target, data} = action.payload
    const ref = await database.ref('customers').push(data)
    await database.ref('users').child(action.triggeredBy).child('own').set(ref.key)
}

async function update(action: Action, database: Database) {
    const {target, data} = action.payload
    data.updatedAt = Date.now()
    await database.ref(target).update(data)
}

async function remove(action: Action, database: Database) {
    const {target} = action.payload
    try {
        await database.ref(target).remove()
    } catch (e) {
    }
}
