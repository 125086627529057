import {media, styled} from "../../style/styled";

export const Row = styled('div')(({ overflow = 'auto'}) => ({
    display       : 'flex',
    justifyContent: 'space-evenly',
    flex          : '1 1 auto',
    alignSelf     : 'stretch',
    overflow      : overflow,
    margin: '10px',
    flexFlow: 'row wrap',
    [media(1025)]: {
        flexFlow: 'row nowrap',
    }
}))

export const Column = styled('div')(({align = 'space-evenly', margin = '5px'}) => ({
    position         : 'relative',
    display          : 'flex',
    alignSelf        : 'stretch',
    width            : '100%',
    margin           : margin,
    justifyContent   : align,
    flexDirection    : 'column',
}))
