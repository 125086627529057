import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {PlayerPreview} from './PlayerPreview'
import {ActionBar} from './ActionBar'
import {ImageMap} from '../Map/ImageMap'
import {CiviJobsBar} from './CiviJobsBar'

export class HomeComponent extends Component {
    render({}, {campaign}, {jobs}) {

        return <Container>
            <MapDisplay jobs={jobs} key="map"/>
            <RightWrapper/>
            <TopWrapper>
                <PlayerPreview/>
            </TopWrapper>
            <BottomWrapper>
                <ActionBar/>
            </BottomWrapper>
        </Container>
    }
}

const TopWrapper = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    flex          : '1 1 auto',
    margin        : '1em',
    justifyContent: 'space-between',
    zIndex        : 2,
    position      : 'absolute',
    top           : 0,
    left          : 0,
    right         : 0,
})
const BottomWrapper = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    flex          : '1 1 auto',
    margin        : '1em',
    justifyContent: 'space-between',
    zIndex        : 2,
    position      : 'absolute',
    bottom        : 0,
    left          : 0,
    right         : 0,
    width         : 'calc(100% - 2em)',
})

const RightWrapper = styled(CiviJobsBar)({
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    alignItems    : 'flex-end',
    flex          : '0 0 auto',

    zIndex   : 2,
    position : 'absolute',
    top      : '25%',
    right    : 0,
    transform: 'translateY(-50%)'
})

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 1 auto',
    position     : 'relative',
    overflow     : 'hidden',
})

const MapDisplay = styled(ImageMap)({
    position: 'absolute',
    top     : 0,
    bottom  : 0,
    left    : 0,
    right   : 0,
    zIndex  : 1,
})
