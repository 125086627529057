import {styled} from '../styled'

export const TitlePage = styled('h1')(({color = 'white', size = '2'}) => ({
    color     : color,
    fontSize  : `${size}em`,
    fontFamily: 'Raleway, sans-serif'
}))

export const Paragraph = styled('p')(({color = 'white', size = '1'}) => ({
    color     : color,
    fontSize  : `${size}em`,
    fontFamily: 'Raleway, sans-serif'
}))
