import * as firebase from 'firebase'
// @ts-ignore
import Emittery from 'emittery/legacy'

export type Action = {
    type: string,
    time?: number,
    triggeredBy?: string,
    payload: {target: string, data: Object} | any
}
export type Database = firebase.database.Database
export type ActionListener = (action: Action, database: Database, provided: any) => Promise<any>
export type ActionsListeners = {
    [key: string] : Array<ActionListener>
}
type firebaseListener = (snap: (firebase.database.DataSnapshot | null)) => any

export const createRealTimeDatabase = function createRealTimeDatabase(database: Database, actionListeners : ActionsListeners, provided: any = {}) {
    const bus = new Emittery()
    const listenersKeys = Object.keys(actionListeners)
    for (const key of listenersKeys) {
        const listenersList = [].concat(actionListeners[key])
        for (const listener of listenersList) {
            bus.on(key, (action: any) => {
                console.log(key, action)
                return listener(action, database, provided)
            })
        }
    }

    return {
        emit           : async function emit(action: Action) {
            try {
                console.log("go", action)
                return await bus.emit(action.type, action)
            } catch (e) {
                console.error('Something went wrong on the listeners for event : ' + action.type, e)
            }
        },
        subscribeChange: function subscribeChange(path: string, fn: firebaseListener) {
            const ref = database.ref(path)
            ref.on('value', fn)
            return () => ref.off('value', fn)
        }
    }
}

export const mergeActions = function mergeActions(...actions : Array<ActionsListeners>): ActionsListeners {
    return actions.reduce((acc, actions) =>
            Object.keys(actions).reduce((acc, key) => {
                acc[key] = (acc[key] || []).concat(actions[key])
                return acc
            }, acc)
        , {})
}
