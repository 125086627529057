import {h} from 'preact'
import {animation, styled} from '../../style/styled'
import {findQuizOpponent} from '../PlayerActions/player.actions'
import {activityUserCode} from '../PlayerActions/player.reducers'

export const ActionBar = (({}, context) => {
    const state = quizzState(context.userData)
    const canPlay = gameState(context.userData)
    const actsNotifs = (context.startedActs || [])
        .filter(act => act.active).length
    return <ActionContainer>
        <ActionButton
            id="launch-challenges"
            icon={require('../style/imgs/hand-leaf.png')}
            notifCount={actsNotifs}
            onClick={() => context.goTo('/challenges')}
        />
        <ActionButton
            inactive={state === comeback}
            notifCount={state === finish ? 1 : 0}
            icon={require('!!from-file!../style/imgs/dual.svg')}
            onClick={async () => {
                switch (state) {
                    case noQuizz:
                        const result = await context.popups.confirm({
                            content: <span>
                                <h3>Dual-Quiz</h3>
                                <ModalImage src={require('../style/imgs/boxing.png')}/>
                                <p>
                                    Défiez un adversaire dans un dual quizz ?
                                </p>
                                <p>
                                    +2 <PointsIcon src={require('!!from-file!../style/svgs/quizPoints.svg')}/> en cas de victoire
                                </p>
                            </span>
                        })
                        if (!result) return
                        context.addActions(findQuizOpponent())
                        return context.goTo('/quizz')
                    case finish:
                    case comeback:
                    case waiting:
                        return context.goTo('/quizz')
                }
            }}
        />
        <ActionButton
            inactive={!canPlay}
            icon={require('../style/imgs/gamepad.png')}
            onClick={async () => {
                if (canPlay) {
                    const result = await context.popups.confirm({
                        content: <div>
                            <h3>Démarrer une partie ?</h3>
                            <ModalImage src={require('../style/imgs/game-controller.png')}/>
                            <p>
                                Récupérez uniquement les légumes de saison en faisant glisser votre panier ! À vous de
                                jouer !
                            </p>
                            <p>+1 <PointsIcon src={require('!!from-file!../style/svgs/quizPoints.svg')}/> en cas de victoire</p>
                        </div>
                    })
                    if (!result) return
                    context.goTo('/game')
                } else {
                    await context.popups.alert({
                        content: 'Revenez jouer demain !'
                    })
                }
            }}
        />
    </ActionContainer>
})

const ActionContainer = styled('div')({
    display       : 'flex',
    justifyContent: 'space-evenly',
    alignItems    : 'center',
    flex          : '0 0 auto',
})
const noQuizz = 'noquizz'
const comeback = 'comeback'
const finish = 'finish'
const waiting = 'waiting'

function quizzState(user) {
    if (!user) return noQuizz
    if (!user.quiz) return noQuizz
    const todayQuizz = activityUserCode(user?.date || new Date())
    if (!user.quiz[todayQuizz]) {
        return noQuizz
    }
    if (user.quiz[todayQuizz].ended) {
        return comeback
    }
    if (user.quiz[todayQuizz].answeredAll) {
        return waiting
    }
    return finish
}

function gameState(user) {
    if (!user) return true
    if (!user.games) return true
    const todayKey = activityUserCode(user?.date || new Date())
    return user.games[todayKey] == null
}

const ActionButton = ({icon, ...props}) => <Button {...props}>
    <img src={icon}/>
</Button>

const notifShadowAnimation = animation({
    '0%'   : {
        transform: 'translateY(0)',
    },
    '40%'  : {
        transform: 'translateY(0)',
    },
    '41.5%': {
        transform: 'translateY(-0.15em)',
    },
    '43%'  : {
        transform: 'translateY(0)',
    },
    '44.5%': {
        transform: 'translateY(-0.15em)',
    },
    '46%'  : {
        transform: 'translateY(0)',
    },
    '100%' : {
        transform: 'translateY(0)',
    },
})

const Button = styled('button')(({inactive, notifCount}, theme) => ({
    border         : 'none',
    borderRadius   : '50%',
    width          : '3em',
    height         : '3em',
    fontSize       : '1em',
    boxShadow      : '0.15em 0.15em 0.3em black',
    boxSizing      : 'border-box',
    margin         : '0 auto',
    padding        : '0.3em',
    opacity        : inactive ? 0.7 : 1,
    backgroundColor: theme.colors.background,
    transition     : 'all ease-in-out 150ms',
    flex           : '0 0 auto',
    '& img'        : {
        maxWidth : '100%',
        objectFit: 'contain',
    },
    '&:hover'      : {
        transform: 'translate(-0.15em, -0.15em)',
        boxShadow: '0.3em 0.3em 0.3em black',
    },

    position: 'relative',
    ...(notifCount > 0 ? {
        boxShadow: '0em 0em 0.3em 0.15em' + theme.colors.cancel,
        border   : '0.15em solid ' + theme.colors.cancel,
        animation: `${notifShadowAnimation} 10s infinite`,
        '&:hover': {
            transform: 'translate(-0.15em, -0.15em)',
            boxShadow: '0em 0em 0.3em 0.15em' + theme.colors.cancel,
        },
        '&:after': {
            content        : `"${notifCount}"`,
            display        : 'block',
            position       : 'absolute',
            fontSize       : '0.8em',
            fontWeight     : 'bolder',
            lineHeight     : 1,
            width          : '1.4em',
            height         : '1.4em',
            padding        : '0.2em',
            boxSizing      : 'border-box',
            borderRadius   : '50%',
            top            : '-0.4em',
            right          : '-0.4em',
            backgroundColor: theme.colors.cancel,
            color          : theme.colors.inverted.text,
        }
    } : {})
}))

const PointsIcon = styled('img')({
    height       : '1em',
    verticalAlign: 'middle',
})
const ModalImage = styled('img')({
    width       : '30%',
})
