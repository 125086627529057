import {InputTextWhite, LoginFormContainer} from '../style/ui/forms'
import {h} from 'preact'
import {TitlePage} from '../style/ui/text'
import {ButtonWhite} from '../style/ui/buttons'
import {TextLink} from '../style/ui/links'
import {forScreen} from '../../common/i18n'
import {loginUrls} from './loginUrls'
import {fromToken} from '../../common/lighttoken'
import {FullscreenLoader} from '../style/ui/Loader'
import {FacebookButton} from './FacebookButton'

const t = forScreen('login')
export const SignUp = (({errors, matches: {token, code}, loggedInInvite, waiting}, {goTo}) => {
    const data = token ? fromToken(token) : {code: code || getCodeFromDomain()}

    if (loggedInInvite && !waiting) {
        loggedInInvite(data)
        return <FullscreenLoader/>
    }
    if (waiting) return null
    return <LoginFormContainer>
        <TitlePage>{t('signUp')}</TitlePage>
        {errors}
        <InputTextWhite placeholder={t('code')} name="code" type={data.code ? 'hidden'  :'text'} value={data.code || ''}/>
        <InputTextWhite name="team" type="hidden" value={data.team || ''}/>
        <InputTextWhite name="temporaryId" type="hidden" value={data.temporaryId || ''}/>
        <InputTextWhite placeholder={t('email')} name="email" value={data.email || ''}/>
        <InputTextWhite placeholder={t('name')} name="name"/>
        <InputTextWhite placeholder={t('password')} type="password" name="password"/>
        <InputTextWhite placeholder={t('passwordConfirm')} type="password" name="confirm"/>
        <ButtonWhite type="submit">{t('signUp')}</ButtonWhite>
        <FacebookButton/>
        <TextLink onClick={() => goTo(loginUrls.login)}>{t('logIn')}</TextLink>
    </LoginFormContainer>
})

const codesToIgnore = ["app", "localhost"]
function getCodeFromDomain() {
    const [sub] = window.location.hostname.split('.')
    return !codesToIgnore.includes(sub) ? sub : null
}
