import {h} from 'preact'
import {styled} from '../../style/styled'
import {Link} from 'preact-router/match'
import {fromBaseUrl} from '../../utils/appRoute'
import {addADay} from '../PlayerActions/player.actions'

const differenceInDays = require('date-fns/difference_in_days')

export const Navbar = (props, {addActions, campaign, now}) => {
    const counter = differenceInDays(campaign?.settings?.endAt, now)
    return <NavContainer {...props}>
        <MobileLogo/>

        <ChronoButton onClick={campaign?.type === 'test' ? () => addActions(addADay()) : null}>
            <ChronoImg src={require('!!from-file!../../style/img/icons/chrono-blanc.svg')}/>
            <span>&nbsp;{counter < 1
                ? 'dernier jour !'
                : `${counter} jours restants`}</span>
        </ChronoButton>

        <NavLink href="/">
            <MobileLogoImg src={require('!!from-file!../style/svgs/map.svg')}/>
        </NavLink>
        <NavLink href="/community">
            <MobileLogoImg src={require('!!from-file!../../style/img/icons/top_share_icon.svg')}/>
        </NavLink>
        <NavLink href="/profile">
            <MobileLogoImg src={require('!!from-file!../style/svgs/settings.svg')}/>
        </NavLink>
    </NavContainer>
}

const NavContainer = styled('div')((_, theme) => ({
    backgroundColor: theme.colors.topBar,
    height         : '2.5em',
    display        : 'flex',
    flex           : '0 0 auto',
}))


const ChronoImg = styled('img')({
    display  : 'block',
    height   : '1.4em',
    width    : 'auto',
    alignSelf: 'center',
    objectFit: 'contain',
})
const MobileLogoImg = styled('img')({
    display  : 'block',
    height   : '1.8em',
    width    : 'auto',
    alignSelf: 'center',
    objectFit: 'contain',
    margin   : '0.3em',
})
const MobileLargeLogoImg = styled('img')({
    display  : 'block',
    height   : '3.5em',
    width    : 'auto',
    alignSelf: 'center',
    objectFit: 'contain',
    margin   : '0.3em',
})

const ActiveLink = (props) => <Link {...props} activeClassName="active" href={fromBaseUrl(props.href)}/>

const NavLink = styled(ActiveLink)((_, theme) => ({
    color            : theme.colors.inverted.text,
    textDecoration   : 'none',
    cursor           : 'pointer',
    display          : 'block',
    textTransform    : 'capitalize',
    '&.active'       : {
        backgroundColor: theme.colors.main,
    },
    '&:first-of-type': {
        marginLeft: 'auto',
    }
}))

const ChronoButton = styled('a')((_, theme) => ({
    color          : theme.colors.inverted.text,
    backgroundColor: theme.colors.inverted.background,
    borderRadius   : '1em',
    padding        : '0.4em 0.6em',
    textDecoration : 'none',
    cursor         : 'pointer',
    margin         : 'auto',
    display        : 'flex',
    flexDirection  : 'row',
    alignItems     : 'center',
    justifyContent : 'center',
    fontSize       : '0.7em',
    '& > *'        : {
        flex: '0 0 auto',
    }
}))

export const MobileLogo = (_, {theme, logo}) => <MobileLogoImg
    src={theme.logo || logo || require('../../style/img/logo-white.png')}/>
export const MobileLargeLogo = (_, {theme, logo}) => <MobileLargeLogoImg
    src={theme.logo || logo || require('../../style/img/logo-white.png')}/>
export const CivitimeMobileLargeLogo = () => <MobileLargeLogoImg
    src={require('../../style/img/logo-white.png')}/>
