export const connectCampaign = (props) => ({
    campaign: `campaigns/${props.campaignId}`,
})
export const connectCampaignTeams = (props) => ({
    teams: `campaigns/${props.campaignId}/teams as Array`,
})
export const connectCampaignTeam = (props) => ({
    team: `campaigns/${props.campaignId}/teams/${props.teamId}`,
})
export const connectCampaignQuizzes = (props) => ({
    quizzes: `campaigns/${props.campaignId}/quizzes as Array`,
})
export const connectCampaignQuiz = (props) => ({
    quiz: `campaigns/${props.campaignId}/quizzes/${props.quizId}`,
})
export const connectCampaignActs = (props) => ({
    acts: `campaigns/${props.campaignId}/acts as Array`,
})
export const connectActsLibrary = (props) => ({
    actsLibrary: `/acts as Array`,
})
export const connectQuizzesLibrary = (props) => ({
    quizzesLibrary: `/quiz as Array`,
})
export const connectCampaignAct = (props) => ({
    act: `campaigns/${props.campaignId}/acts/${props.actId}`,
})

export const connectCampaignThemes = (props) => ({
    themes: `campaigns/${props.campaignId}/themes as Array`,
})
export const connectCampaignTheme = (props) => ({
    theme: `campaigns/${props.campaignId}/themes/${props.theme}`,
})
