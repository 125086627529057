import {Component, h} from 'preact'
import Modal from 'react-modal'
import {TransparentButton} from '../manager/ui/Buttons'
import {Card} from '../manager/ui/Card'
import {styled} from './styled'
import {PageTitle} from '../manager/ui/Page'
import {managerTheme} from '../manager/ui/managerTheme'

Modal.setAppElement('#dialog-root')

export class ModalStyle extends Component {
    render({isOpen, onRequestClose, onRequestCancel, title, children}, {}, _) {
        return <Modal
            isOpen={isOpen}
            onRequestClose={onRequestCancel || onRequestClose}
            contentLabel="Example Modal"
            style={innerModalStyle}
        >
            <section>
                <ModalTitle>{title}</ModalTitle>
                <ModalContent>
                    <ModalCard>
                        {children}
                    </ModalCard>
                    <ActionBar>
                        <BackButton onClick={onRequestCancel || onRequestClose}>{'<'}&nbsp;Retour</BackButton>
                        <TransparentButton active={true} onClick={onRequestClose}>Valider</TransparentButton>
                    </ActionBar>
                </ModalContent>
            </section>
        </Modal>
    }
}

const innerModalStyle = {
    content: {
        position    : 'initial',
        background  : 'transparent',
        borderRadius: '0px',
        margin      : '2rem 0',
        padding     : '0',
        border      : 'none',
        outline     : 'none',
        maxWidth    : '90vw',
    },
    overlay: {
        zIndex         : '155',
        position       : 'fixed',
        top            : '0',
        left           : '0',
        right          : '0',
        bottom         : '0',
        backgroundColor: '#3d3d3de6',
        display        : 'flex',
        alignItems     : 'center',
        justifyContent : 'center',
        overflowY      : 'auto',
    }
}


const ModalCard = Card.extends({
    minWidth       : '50vw',
    minHeight      : '50vh',
    maxHeight      : '90vh',
    overflowY      : 'auto',
    backgroundColor: managerTheme.colors.cardBackground,
})
const ModalTitle = styled('h1')({
    color        : managerTheme.colors.navText,
    margin       : '15px 0',
    lineHeight   : '0.9',
    fontSize     : '2.4em',
    textTransform: 'capitalize',
})
const ModalContent = styled('div')({
    display: 'flex',
})
const ActionBar = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'flex-start',
    '& > * '      : {
        margin: '0.5rem'
    }
})

const BackButton = styled(TransparentButton)({
    color: managerTheme.colors.navText + ' !important'
})
