import {Component, h} from 'preact'
import nanoid from 'nanoid'
import {styled} from '../../style/styled'
import {managerTheme} from './managerTheme'
import * as AColorPicker from 'a-color-picker'

export class ColorPicker extends Component {
    state = {
        color     : null,
        key       : nanoid(),
        showPicker: false,
    }
    colorPicker = null

    componentWillMount() {
        this.setState({
            color: this.props.color,
        })
        document.body.addEventListener('click', function () {

        })
    }

    componentWillUnmount() {
        this.colorPicker && (this.colorPicker.onchange = void 0)
        delete this.colorPicker
    }

    onNewColor = (picker) => {
        const color = picker.color
        this.setState({
            color,
        })
        this.props.onChange && this.props.onChange(color)
    }

    onColorZoneReady = (colorZone) => {
        if (colorZone && !this.colorPicker) {
            this.colorPicker = AColorPicker.createPicker({attachTo: colorZone.base, color: this.state.color})
            this.colorPicker.onchange = this.onNewColor
        }
    }

    componentDidUpdate(prevProps) {
        const color = this.props.color
        if (color !== prevProps.color) {
            this.setState({color})

            if (this.colorPicker) this.colorPicker.color = color
        }
    }

    toggleColorPicker = () => {
        this.setState({
            showPicker: !this.state.showPicker,
        })
    }

    render({label, first}, {color, key, showPicker}, _) {
        return <Container>
            <PreviewContainer onClick={this.toggleColorPicker}>
                <PreviewText>
                    <Preview color={color}/>
                </PreviewText>
            </PreviewContainer>
            <Label>{label}</Label>
            {first && <Color>{color}</Color>}
            {showPicker && <Overlay onCLick={this.toggleColorPicker}/>}
            <Picker key={key} ref={this.onColorZoneReady} showPicker={showPicker}/>
        </Container>
    }
}

const Container = styled('article')({
    position: 'relative',
})
const PreviewContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
    cursor       : 'pointer',
})
const PreviewText = styled('div')({
    display   : 'flex',
    alignItems: 'center',
})
const Label = styled('h1')({
    color : managerTheme.colors.text,
    margin: '0',
})
const Color = styled('span')({
    color     : managerTheme.colors.text,
    marginLeft: '1.5rem',
})

const Preview = styled('div')(({color}) => ({
    width          : '2rem',
    height         : '2rem',
    border         : '1px solid white',
    backgroundColor: color,
    flex           : '0 0 auto',
}))

const Picker = styled('div')(({showPicker}) => ({
    position: 'absolute',
    bottom  : '-7%',
    display : showPicker ? 'block' : 'none',
    zIndex  : 999999,
}))

const Overlay = styled('div')({
    position       : 'fixed',
    top            : 0,
    bottom         : 0,
    left           : 0,
    right          : 0,
    backgroundColor: managerTheme.colors.background,
    opacity        : 0.8,
    zIndex: 99999,
})
