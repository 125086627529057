import {Component, h} from 'preact'
import {BlockTitle, SectionTitle} from '../style/Titles'
import {ModalCard} from '../style/Cards'
import {styled} from '../../style/styled'
import {Button} from '../style/buttons'
import {startAct} from '../PlayerActions/player.actions'

export class CiviAct extends Component {
    startAct = async () => {
        await this.context.addActions(startAct(this.props.matches.id))
        this.props.closeIt()
    }

    render({closeIt, matches: {id}}, {}, {campaign, userData}) {
        if (!campaign) return null
        const act = campaign.acts[id] || {}
        return <Container>
            <BlockTitle>Défis</BlockTitle>
            <FullHeightCard>
                <CardTitle>Relever le défi ?</CardTitle>
                <ActText>{act.label}</ActText>
                <EcoGesteLogo src={require('../style/imgs/hand-leaf.png')}/>
                <ActBonuses>
                    <ActBonus>
                        <ActBonusLabel>Récompenses :</ActBonusLabel>
                        <ActBonusIcon src={require('../style/imgs/leaf.png')}/>
                        <ActBonusText>+{act.flouz || 0} CiviFlouz</ActBonusText>
                    </ActBonus>
                    <ActBonus>
                        <ActBonusLabel>Durée :</ActBonusLabel>
                        <ActBonusIcon src={require('../style/imgs/clock.png')}/>
                        <ActBonusText>{act.duration || 0} Jours</ActBonusText>
                    </ActBonus>
                </ActBonuses>
                <FeedbackIn dangerouslySetInnerHTML={{__html: act.feedbackIn}}/>
                <ActBonuses>
                    <ActButton onClick={this.startAct}>Oui</ActButton>
                    <ActButton onClick={closeIt}>Non</ActButton>
                </ActBonuses>
            </FullHeightCard>
        </Container>
    }
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 0 auto',
    height       : '100%',
})
const CardTitle = styled(SectionTitle)({
    borderBottom: 'none !important',
    width       : 'auto !important',
    textAlign   : 'center',
})

const ActText = styled('p')({
    margin    : '0',
    fontSize  : '1em',
    lineHeight: '1.3',
    textAlign : 'center',
})
const ActBonusLabel = styled('p')({
    margin    : '0',
    fontSize  : '1em',
    lineHeight: '1.3',
    display   : 'block'
})
const ActBonuses = styled('div')({
    display   : 'flex',
    alignItems: 'center',
    margin    : '1em',
    //justifyContent: 'space-between',
})
const ActBonus = styled('div')({
    textAlign: 'center',
    width    : '50%',
})
const ActBonusIcon = styled('img')({
    display      : 'inline-block',
    width        : 'auto',
    height       : '1.5em',
    margin       : '0.3em 0.3em 0.3em 0',
    verticalAlign: 'middle',
})
const ActBonusText = styled('span')({
    display      : 'inline-block',
    verticalAlign: 'middle',
})
const EcoGesteLogo = styled('img')({
    display  : 'block',
    maxWidth : '40%',
    margin   : '0 auto',
    alignSelf: 'center',
    objectFit: 'contain',
})
const ActButton = Button

const FullHeightCard = styled(ModalCard)({
    justifyContent: 'space-between',
    padding       : '1em !important',
})

const FeedbackIn = styled('p')({
    textAlign: 'center',
    margin   : '1.5em 0.5em',
})
