import {Component, h} from 'preact'
import {ContentWithPreviewCard, Page, PreviewCard, PreviewRow} from '../../ui/Page'
import {RoundButton} from '../../ui/Buttons'
import {Edit2, Plus, Trash2} from 'preact-feather'
import {TeamsList} from '../../../app/Teams/TeamsList'
import {connectCampaign, connectCampaignTeams} from '../campaigns.connectors'
import {combine} from '../../../utils/combine'
import {styled} from '../../../style/styled'
import {getFileUrl} from '../../../utils/files'
import {InvertedArea} from '../../ui/Input'
import {managerTheme} from '../../ui/managerTheme'
import {createTeam, deleteTeam} from '../campaigns.creators'
import {connect} from '../../../utils/connect'
import {tracker} from '../../../../common/tracker'


export const CampaignTeamsScreen = connect(combine(connectCampaign, connectCampaignTeams))
(class CampaignTeamsScreen extends Component {
    deleteTeam = async (team) => {
        await deleteTeam(this.props.campaignRef, team.key)
        tracker.push({
            event: 'delete-team',
            cat  : 'manager',
        })
    }
    createTeam = async () => {
        await createTeam(this.props.campaignRef)
        tracker.push({
            event: 'create-team',
            cat  : 'manager',
        })
    }
    selectTeam = async (team) => {
        this.context.goTo(`campaigns/${this.props.campaignId}/teams/${team.key}`)
        tracker.push({
            event: 'edit-team',
            cat  : 'manager',
        })
    }

    render({campaign, teams, campaignRef, campaignId}, {}, _) {
        return <Page title="équipes" shouldScroll={false}
                     tip={`Listez les projets que les joueurs choisiront afin de constituer les équipes.`}>
            <PreviewRow>
                <ContentWithPreviewCard>
                    <ListContainer>
                        <div style={{textAlign: 'center',}}>
                            <RoundButton
                                onClick={this.createTeam}>
                                <Plus/>
                            </RoundButton>
                            <p onClick={this.createTeam}>
                                Créer une équipe
                            </p>
                        </div>
                        {
                            teams?.map(team => <TeamPreview
                                key={team.key}
                                campaignId={campaignId}
                                onSelect={this.selectTeam}
                                onDelete={this.deleteTeam}
                                team={team}
                            />)
                        }
                    </ListContainer>
                </ContentWithPreviewCard>
                <PreviewCard campaignId={campaignId} settings={campaign?.settings}>
                    <TeamsList teams={campaign?.teams} campaignId={campaignId}/>
                </PreviewCard>
            </PreviewRow>
        </Page>
    }
})


const ListContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'stretch',
    width        : '600px',
    maxWidth     : '100%',
    margin       : '0 auto',
})

class TeamPreview extends Component {
    state = {
        logo: null,
    }

    async componentWillMount() {
        if (!this.props.campaignId || !this.props.team?.key) return
        const path = `campaigns/${this.props.campaignId}/teams/${this.props.team?.key}/logo`
        const logo = await getFileUrl(path)
        this.setState({
            logo: logo,
        })
    }

    async componentWillReceiveProps({team, campaignId}, {}) {
        if (!campaignId || !team?.key || (campaignId === this.props.campaignId && this.props.team?.key === team?.key)) return
        const path = `campaigns/${this.props.campaignId}/teams/${this.props.team?.key}/logo`
        const logo = await getFileUrl(path)
        this.setState({
            logo: logo,
        })
    }

    selectTeam = (event) => {
        event?.preventDefault()
        event?.stopPropagation()
        this.props.onSelect && this.props.onSelect(this.props.team)
    }

    deleteTeam = (event) => {
        event?.preventDefault()
        event?.stopPropagation()
        this.props.onDelete && this.props.onDelete(this.props.team)
    }

    render({team, onUpdateIntroduction, onUpdateName}, {logo}, _) {
        if (!team) return null
        return <TeamPreviewContainer>
            <Title logo={logo}>
                {team.name}
            </Title>
            <PreviewEditZone>
                <div>
                    {team.introduction}
                </div>
                <ButtonRow>
                    <RoundButton onClick={this.deleteTeam}>
                        <Trash2/>
                    </RoundButton>
                    <RoundButton onClick={this.selectTeam}>
                        <Edit2/>
                    </RoundButton>
                </ButtonRow>
            </PreviewEditZone>
        </TeamPreviewContainer>
    }
}

const TeamPreviewContainer = styled('article')(({logo}) => ({
    padding  : '5px',
    color    : managerTheme.colors.text,
    margin   : '5px',
    cursor   : 'pointer',
    alignSelf: 'stretch',
}))

const Title = styled('h1')(({logo}) => ({
    backgroundBlendMode: 'multiply',
    backgroundImage    : `url(${logo})`,
    backgroundColor    : logo ? 'lightgrey' : managerTheme.colors.background,
    backgroundPosition : 'center center',
    backgroundSize     : 'cover',
    margin             : 0,
    padding            : '50px 15px',
    fontSize           : '1rem',
    textAlign          : 'center',
    textTransform      : 'capitalize',
    '&:after'          : {
        display        : 'block',
        content        : '\'\'',
        height         : '2px',
        width          : '100%',
        backgroundColor: managerTheme.colors.inputBorder,
    }
}))
const TeamArea = InvertedArea
const PreviewEditZone = styled('div')({
    backgroundColor: managerTheme.colors.text,
    padding        : '0 15px',
})
const ButtonRow = styled('div')({
    display       : 'flex',
    justifyContent: 'flex-end',
    padding       : '10px 0',
    alignSelf     : 'stretch',
    '& > *'       : {
        margin: '5px',
    }
})
