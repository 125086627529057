import {InputTextWhite, LoginFormContainer} from '../style/ui/forms'
import {h} from 'preact'
import {TitlePage} from '../style/ui/text'
import {TextLink} from '../style/ui/links'
import {ButtonWhite} from '../style/ui/buttons'
import {forScreen} from '../../common/i18n'
import {loginUrls} from './loginUrls'

const t = forScreen('login')

export const ResetPassword = (({errors}, {goTo}) => <LoginFormContainer>
    <TitlePage>{t('changePassword')}</TitlePage>
    {errors}
    <InputTextWhite placeholder={t('password')} name="password" type="password"/>
    <InputTextWhite placeholder={t('passwordConfirm')} name="passwordConfirm" type="password"/>
    <ButtonWhite>{t('changePassword')}</ButtonWhite>
    <TextLink onClick={() => goTo(loginUrls.signUp)}>{t('logIn')}</TextLink>
    <TextLink onClick={() => goTo(loginUrls.forgotPassword)}>{t('signUp')}</TextLink>
</LoginFormContainer>)
