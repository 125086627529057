export enum notificationsTypes {
    quizInvite,
    quizResult,
    teamInvite,
    playerInvite,
    jobFinished,
    challengeFinished,
}

export type NotificationKeys = keyof typeof notificationsTypes

export const notifications: NotificationType = {
    [notificationsTypes.quizInvite]: {
        emailId       : 170,
        deviceTemplate: (payload: QuizInvitePayload) => ({
            title: `Vous avez été défié au Dual-Quiz !`,
            body : `${payload.OPPONENT_NAME} vous défi !`,
        }),
    },
    [notificationsTypes.quizResult]: {
        emailId       : 176,
        deviceTemplate: (payload: QuizResultPayload) => ({
            title: `Les résultats de votre Dual-Quiz!`,
            body : `Consultez les vite !`,
        }),
    },
    [notificationsTypes.teamInvite]: {
        emailId       : 175,
        deviceTemplate: (payload: InvitePayload) => ({
            title: `Rejoignez l'équipe ${payload.TEAM_NAME} !`,
            body : `${payload.PLAYER_NAME || 'Quelqu\'un'} vous a invité à rejoindre son équipe`,
        }),
    },
    [notificationsTypes.playerInvite]: {
        emailId       : 138,
        deviceTemplate: (payload: InvitePayload) => ({
            title: `Rejoignez CiviTime`,
            body : `${payload.PLAYER_NAME || 'Quelqu\'un'} vous a invité à rejoindre CiviTime`,
        }),
    },
    [notificationsTypes.jobFinished]: {
        emailId       : 172,
        deviceTemplate: (payload: PlayerPayload) => ({
            title: `Un CiviJob est terminé`,
            body : `Découvrez vite l'évolution de votre entreprise !`,
        }),
    },
    [notificationsTypes.challengeFinished]: {
        emailId       : 171,
        deviceTemplate: (payload: PlayerPayload) => ({
            title: `Avez-vous réussi votre défi ?`,
            body : `Venez vite le valider.`,
        }),
    },
}


export type NotificationType = {
    [K in notificationsTypes]: NotificationConfig<any>
}

export type NotificationConfig<T> = {
    emailId: number,
    deviceTemplate: (payload: T) => DeviceNotificationBody
}

export type DeviceNotificationBody = {
    title: string,
    body: string,
    clickAction?: string,
    icon?: string,
}

export type QuizInvitePayload = {
    PLAYER_NAME: string,
    OPPONENT_NAME: string,
}
export type PlayerPayload = {
    PLAYER_NAME: string,
}
export type QuizResultPayload = {
    PLAYER_NAME: string,
    OPPONENT_NAME: string,
}
export type InvitePayload = {
    EMAIL: string,
    PLAYER_NAME: string,
    TEAM_NAME: string,
    INVITE_LINK: string,
}
