import {Component, h} from 'preact'
import {IconButton} from '../ui/Buttons'
import {Eye, X} from 'preact-feather'
import {media, styled} from '../../style/styled'
import {managerTheme} from '../ui/managerTheme'
import {PreviewCard} from '../ui/Page'
import {Navbar} from '../../app/style/Nav'
import {connectCampaign} from '../Campaigns/campaigns.connectors'
import {connect} from '../../utils/connect'

export const AppPreview = connect(
    connectCampaign,
)(class AppPreview extends Component {
    state = {
        openPreview: false,
    }
    showPreview = () => {
        this.setState({
            openPreview: true,
        })
    }
    closePreview = () => {
        this.setState({
            openPreview: false,
        })
    }

    getChildContext() {
        return {
            userData: this.context.userData || {},
            campaign: this.props.campaign,
        }
    }

    render({children, buttonDirection, campaign, campaignRef, onUrlChange, flatButton, onAddActions}, {openPreview}, _) {
        return <Container>
            {flatButton ? <Flat onClick={this.showPreview}>
                <Eye/>&nbsp;Prévisualiser
            </Flat> : <IconButton icon={<Eye/>} direction={buttonDirection} onClick={this.showPreview}>
                Prévisualiser
            </IconButton>}
            {openPreview && <Backdrop onClick={this.closePreview}/>}
            <Drawer opened={openPreview}>
                <Close onClick={this.closePreview}/>
                <PreviewCard campaignId={campaign?.key || campaignRef?.key} settings={campaign?.settings}
                             onUrlChange={onUrlChange} onAddActions={onAddActions}>
                    <Nav/>
                    {children}
                </PreviewCard>
            </Drawer>
        </Container>
    }
})

const Flat = styled('div')({
    display        : 'flex',
    flex           : '1 1 auto',
    alignItems     : 'center',
    justifyContent : 'center',
    cursor         : 'pointer',
    backgroundColor: managerTheme.colors.action,
    color          : managerTheme.colors.lightText,
    padding        : '1vh 1rem',
})

const Nav = styled(Navbar)({
    pointerEvents: 'none'
})

const Container = styled('div')({
    display       : 'inline-flex',
    justifyContent: 'center',
    alignItems    : 'center',
})

const Backdrop = styled('div')({
    position: 'fixed',
    top     : 0,
    bottom  : 0,
    left    : 0,
    right   : 0,
    zIndex  : 99999,

    backgroundColor: managerTheme.colors.backdrop
})

const Close = styled(X)({
    position: 'absolute',
    right   : 0,
    top     : 0,
}).withProps({size: '8vmin'})

const Drawer = styled('div')(({opened}) => ({
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    alignItems    : 'center',

    position       : 'fixed',
    zIndex         : 99999,
    top            : 0,
    bottom         : 0,
    transition     : 'transform ease-in-out 200ms',
    transform      : opened ? 'translateX(-100%)' : 'translateX(0)',
    backgroundColor: managerTheme.colors.navBackground,
    color          : managerTheme.colors.navText,
    width          : '100vw',
    right          : '-100vw',
    [media(1025)]  : {
        width: '30vw',
        right: '-30vw',
    }
}))
