const max = require('date-fns/max')
const min = require('date-fns/min')
const addDays = require('date-fns/add_days')
const differenceInDays = require('date-fns/difference_in_days')
const differenceInCalendarWeeks = require('date-fns/difference_in_calendar_weeks')
const startOfDay = require('date-fns/start_of_day')
const isWeekend = require('date-fns/is_weekend')

function dates(start, end, themes) {
    const calculationStart = startOfDay(start)
    const calculationEnd = startOfDay(addDays(end, 1))

    const days = differenceInDays(calculationEnd, calculationStart)

    const weeks = Math.floor(days / 7)
    const rest = days % 7

    let weekendsCount = weeks
    if (rest !== 0) {
        const lastPlainWeek = addDays(end, -rest)
        if (differenceInCalendarWeeks(end, lastPlainWeek) !== 0) {
            weekendsCount++
        }
    }
    const workDays = days - weekendsCount * 2
    const themesCount = Object.keys(themes || {}).length

    const rawSize = workDays / themesCount
    const minSize = Math.floor(rawSize)
    const decimalPartSize = rawSize - minSize
    const turnToCompleteDecimal = decimalPartSize === 0 ? themesCount + 1 : Math.trunc(1 / decimalPartSize)
    console.log(themesCount, workDays, decimalPartSize, decimalPartSize === 0, minSize, rawSize)
    return Array(themesCount)
        .fill(null)
        .reduce((result, _, i) => {
            const [lastDates] = result
            const startAt = lastDates ? addDays(lastDates.endAt, 1) : calculationStart
            const innerCalculationStart = lastDates ? addWorkDays(lastDates.endAt, 1) : calculationStart
            const workDaysToAdd = minSize - 1 + ((i + 1) % turnToCompleteDecimal === 0 ? 1 : 0)
            const endAt = (i + 1) === themesCount ? end : addWorkDays(innerCalculationStart, workDaysToAdd)
            console.log(startAt, workDaysToAdd)
            return [{
                startAt: startAt,
                endAt  : endAt,
            }].concat(result)
        }, [])
        .reverse()
}

export function addWorkDays(date, workDays) {
    const result = Array(workDays).fill(1).reduce((date) => {
        let result = addDays(date, 1)
        while (isWeekend(result)) {
            result = addDays(result, 1)
        }
        return result
    }, date)
    //console.log(date, workDays, result)
    return result
}

export function themesDaysCount(start, end, themes) {
    const dates1 = dates(start, end, themes)
    return dates1.length ? dates1.map(({startAt, endAt}) => differenceInDays(addDays(endAt, 1), startAt)) : [differenceInDays(end, start) + 1]
}

export function themesWorkDaysCount(start, end, themes) {
    const dates1 = dates(start, end, themes)
    return dates1.map(({startAt, endAt}) => {
        const difference = differenceInDays(addDays(endAt, 1), startAt)
        const afterWorkDays = differenceInDays(addWorkDays(startAt, difference - 1), startAt)
        return difference - (afterWorkDays + 1 - difference)
    })
}
