import {CampaignTypes} from './campaigns.types'
import {appTheme} from '../../app/style/appTheme'
import {addActions, completePathFromRef} from '../../utils/onlineActions'
import generate from 'nanoid/generate'
import nanoid from 'nanoid'
import {ManagerTypes} from '../manager.types'
import {database} from '../../firebase'

export const defaultScenario = {
    first : `Nous sommes en 2099. Plus qu’un pas pour atteindre l'objectif de la COP21 : réduire de 2 degrés le réchauffement climatique.`,
    second: `Cette ultime mission, si vous l'acceptez, c’est à la pépinière Start'inbox des Sanitas qu’elle a été confiée !`,
    third : `Votre rôle sera de réaliser avec vos collègues d'étage, des actions quotidiennes liées au développement durable et ainsi, espérer voir votre team vainqueur de Civitime !`,
}

export async function createCampaign(campaignsRef, key, quizzes, acts) {
    const path = completePathFromRef(campaignsRef.child(key))
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : {
                settings : {
                    colors       : {
                        main  : appTheme.colors.main,
                        second: appTheme.colors.topBar,
                        third : appTheme.colors.second,
                    },
                    code         : generate('ABCEDFGHJKLMNOPQRSTUVWXYZ0123456789', 5),
                    licencesCount: 10,
                },
                scenario : {...defaultScenario},
                quizzes  : quizzes,
                acts     : acts,
                updatedAt: new Date().getTime(),
            },
        },
    })
}

export async function removeCampaign(campaignRef) {
    const campaignPath = completePathFromRef(campaignRef)
    return await addActions({
        type   : ManagerTypes.delete,
        payload: {
            target: campaignPath,
        },
    })

}

export async function updateCampaign(campaignRef, data) {
    const path = completePathFromRef(campaignRef)
    console.log(path, data)
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}

export async function createTeam(campaignRef) {
    return updateTeams(campaignRef, {
        [nanoid()]: {
            name     : 'Nouvelle équipe',
            updatedAt: new Date().getTime(),
        },
    })
}

export async function updateTeams(campaignRef, data) {
    const path = completePathFromRef(campaignRef.child('teams'))
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}

export async function deleteTeam(campaignRef, teamId) {
    const path = completePathFromRef(campaignRef.child('teams').child(teamId))
    return await addActions({
        type   : CampaignTypes.deleteTeam,
        payload: {
            target: path,
            data  : null,
        },
    })
}

export async function updateQuiz(campaignRef, data) {
    const path = completePathFromRef(campaignRef.child('quizzes'))
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}

export async function updateAct(campaignRef, data) {
    const path = completePathFromRef(campaignRef.child('acts'))
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}

export async function updateTheme(campaignRef, data) {
    const path = completePathFromRef(campaignRef.child('themes'))
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}

export async function askForPublication(campaignRef) {
    const path = completePathFromRef(campaignRef)
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : {askForPublication: true},
        },
    })
}

export async function updateThemeList(campaignRef, data, nanoidKey) {
    console.log(data)
    const path = completePathFromRef(campaignRef.child('themes').child(nanoidKey))
    await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
    if (!data.type) return
    const campaign = (await campaignRef.once('value')).val()
    const actsLibrary = (await database.ref('acts').once('value')).val()
    const data1 = filterByCategory(campaign.acts || {}, actsLibrary, data.type, nanoidKey)
    console.log(data1)
    await updateAct(campaignRef, data1)
    const quizLibrary = (await database.ref('quiz').once('value')).val()
    await updateQuiz(campaignRef, filterByCategory(campaign.quizzes || {}, quizLibrary, data.type, nanoidKey))
}

function filterByCategory(actual, library, catId, theme) {
    return Object
        .keys(library)
        .map(key => ({
            key,
            theme: theme,
            ...library[key],
        }))
        .map(item => ({
            ...item,
            category: Object.keys(item.category || {}).map(i => item.category[i]),
        }))
        .filter(item => item.category.includes(catId))
        .filter(item => !actual[item.key])
        .sort(() => 0.5 - Math.random())
        .slice(0, 5)
        .reduce((acc, {key, ...item}) => ({
            ...acc,
            [key]: item,
        }), {})
}

export async function deleteTheme(campaignRef, theme) {
    const path = completePathFromRef(campaignRef.child('themes').child(theme))
    return await addActions({
        type   : CampaignTypes.deleteTeam,
        payload: {
            target: path,
            data  : null,
        },
    })
}

export async function updateOneAct(campaignRef, idAct, data) {
    const path = completePathFromRef(campaignRef.child('acts')) + '/' + idAct
    return await addActions({
        type   : CampaignTypes.updateCampaign,
        payload: {
            target: path,
            data  : data,
        },
    })
}
