import {Component, h} from 'preact'
import {Navbar} from './ui/nav'
import {media, styled} from '../style/styled'
import Router from 'preact-router'
import {fromBaseUrl, goTo} from '../utils/appRoute'
import {managerTheme} from './ui/managerTheme'
import {Dashboard} from './Dashboard/Dashboard'
import {appTheme} from '../app/style/appTheme'
import {currentCustomer} from './Customer/customer.accessors'
import {auth} from '../firebase'
import {CampagnsListScreen} from './Campaigns/List/CampagnsListScreen'
import {CampaignSettingsScreen} from './Campaigns/Settings/CampaignSettingsScreen'
import {CampaignScenarioScreen} from './Campaigns/Scenario/CampaignScenarioScreen'
import {CampaignTeamsScreen} from './Campaigns/Teams/CampaignTeamsScreen'
import {CampaignTeamScreen} from './Campaigns/Teams/CampaignTeamScreen'
import {CampaignQuizzesScreen} from './Campaigns/Quizzes/CampaignQuizzesScreen'
import {CampaignQuizScreen} from './Campaigns/Quizzes/CampaignQuizScreen'
import {CampaignActsScreen} from './Campaigns/Acts/CampaignActsScreen'
import {CampaignActScreen} from './Campaigns/Acts/CampaignActScreen'
import {CampaignThemesScreen} from './Campaigns/Themes/CampaignThemesScreen'
import {tracker} from '../../common/tracker'
import {CampaignPublishScreen} from './Campaigns/Publish/CampaignPublishScreen'
import {history} from '../utils/history'

export class App extends Component {
    state = {
        customer: null,
    }

    async componentWillMount() {
        auth.onAuthStateChanged(async () => {
            const customer = await currentCustomer()
            this.setState({customer})
        })
    }

    getChildContext() {
        return {
            theme   : appTheme,
            customer: this.state.customer,
            routerProps: this.state.routerProps,
        }
    }

    routeChange = ({url, current}) => {
        this.setState({
            routerProps: current?.props,
        })
        tracker.push({
            event: 'navigate',
            cat: 'manager',
            page: url,
        })
    }

    render({}, {customer}) {
        return <AppContainer>
            <Navbar/>
            <Router onChange={this.routeChange} history={history}>
                <Dashboard path={fromBaseUrl('/')}/>
                <CampagnsListScreen path={fromBaseUrl('/campaigns')}/>
                <CampaignSettingsScreen path={fromBaseUrl('/campaigns/:campaignId/settings')}/>
                <CampaignScenarioScreen path={fromBaseUrl('/campaigns/:campaignId/scenario')}/>
                <CampaignTeamsScreen path={fromBaseUrl('/campaigns/:campaignId/teams')}/>
                <CampaignTeamScreen path={fromBaseUrl('/campaigns/:campaignId/teams/:teamId')}/>
                <CampaignQuizzesScreen path={fromBaseUrl('/campaigns/:campaignId/quizz')}/>
                <CampaignQuizScreen path={fromBaseUrl('/campaigns/:campaignId/quizz/:quizId')}/>
                <CampaignActsScreen path={fromBaseUrl('/campaigns/:campaignId/acts')}/>
                <CampaignActScreen path={fromBaseUrl('/campaigns/:campaignId/acts/:actId')}/>
                <CampaignThemesScreen path={fromBaseUrl('/campaigns/:campaignId/themes')}/>
                <CampaignPublishScreen path={fromBaseUrl('/campaigns/:campaignId/publish')}/>
            </Router>
        </AppContainer>
    }
}

const AppContainer = styled('div')({
    display        : 'flex',
    flexDirection  : 'column',
    alignItems     : 'stretch',
    height         : '100%',
    width          : '100%',
    backgroundColor: managerTheme.colors.background,
    color          : managerTheme.colors.text,
    [media(1025)]  : {
        flexDirection: 'row',
    }
})
