import {Component, h} from 'preact'
import {RoundButton, RoundedButton} from '../../ui/Buttons'
import {Plus, Trash2} from 'preact-feather'
import {Input, RichTextArea} from '../../ui/Input'
import {Watcher} from '../../../utils/DataSync'
import {styled} from '../../../style/styled'


export const CampaignQuizScreen = class CampaignQuizScreen extends Component {
    state = {
        actualQuiz: null,
        focused   : false,
    }
    unsubscribe = null

    componentWillMount() {
        this.watcher = Watcher(this.props.quiz)
        this.unsubscribe = this.watcher.subscribe(this.save)
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe()
    }

    componentWillReceiveProps(nextProps, _) {
        this.watcher.silentUpdate(nextProps.quiz)
    }

    save = async () => {
        console.log(this.watcher.get(''))
        this.props.onUpdate && this.props.onUpdate(this.watcher.get(''))
    }

    render({quiz, campaignId, campaign}, {focused, actualQuiz}, _) {
        const watcher = this.watcher

        const allResponses = this.watcher?.get('responses') || actualQuiz?.responses
        const responses = (Object.keys(allResponses || {}))
            .map(key => {
                const data = allResponses[key]
                return data ? ({...data, key,}) : null
            })
            .filter(Boolean)
        return <EditForm>
            <Input value={watcher.get('question')} type="text"
                   onInput={watcher.put('question')}/>
            {
                responses
                    .map((response, i) => <SimpleRow key={response.key}>
                        <Input
                            value={response.response}
                            type="text"
                            width={'80%'}
                            placeholder={`Réponse ${i + 1}`}
                            onInput={watcher.put(`responses/${response.key}/response`)}
                        />
                        <Input
                            checked={response.valid}
                            type="checkbox"
                            onChange={watcher.put(`responses/${response.key}/valid`)}
                        />
                        <RoundButton
                            type="button"
                            margin="1.5rem 5px 0"
                            onClick={() => watcher.put(`responses/${response.key}`)(null)}
                        >
                            <Trash2/>
                        </RoundButton>
                    </SimpleRow>)
            }
            <NewContainer>
                <RoundButton
                    onClick={() => watcher.put(`responses/${responses.length}`)({
                        response: 'Réponse'
                    })}>
                    <Plus/>
                </RoundButton>
                <p>Ajouter une réponse</p>
            </NewContainer>
            <RichTextArea
                value={watcher.get('explanation')}
                placeholder="Explications"
                onInput={watcher.put('explanation')}
                onFocus={() => this.setState({focused: true})}
                onBlur={() => this.setState({focused: false})}
            />
        </EditForm>
    }
}

const SimpleRow = styled('div')({
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
    minWidth      : '60%',
    padding       : '1rem 0',
    flex          : '0 0 auto',
})

const NewContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'center',
    textAlign    : 'center',
})

const EditForm = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    alignItems    : 'stretch',
    minWidth      : '60%',
    flex          : 1,
    justifyContent: 'space-evenly',
})
