import {Component, h} from 'preact'
import {Page} from '../../ui/Page'
import 'humane-js/themes/libnotify.css'

import illusion from './illusion.png'
import {styled} from '../../../style/styled'
import {connectCampaign} from '../campaigns.connectors'
import {CampaignPublicationMessages} from '../CampaignProgress/CampaignProgress'
import {connect} from '../../../utils/connect'
import {updateCampaign} from '../campaigns.creators'

export const CampaignPublishScreen = connect(connectCampaign)(class CampaignPublishScreen extends Component {
    state = {}

    publish = async () => {
        await updateCampaign(this.props.campaignRef, {
            needToBePublished: true,
        })
    }

    render({campaign}, {}, {}) {
        return <Page title="Publier votre campagne">
            <Container>
                <CampaignPublicationMessages campaign={campaign} onPublish={this.publish}/>
            </Container>
        </Page>
    }
})

const Container = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    width         : '100%',
    alignSelf     : 'stretch',
    textAlign     : 'center',
    alignItems    : 'center',
    justifyContent: 'center',
})

const CenterIt = styled('div')({
    margin: '1rem auto'
})

const App = styled('div')({
    backgroundImage: `url(${illusion})`,
    backgroundSize : 'cover',
    height         : '100%',
    flex           : '1 1 auto',
    alignSelf      : 'stretch',

})
