import {atob, btoa} from 'b2a'

const abbreviations = {
    email   : 'e',
    name    : 'n',
    team    : 't',
    code: 'c',
}
const invertedAbbreviations = Object.keys(abbreviations).reduce((acc, k) => ({
    ...acc,
    [abbreviations[k]]: k,
}), {})

const splitter = '&'
const operator = '='
export const fromToken = (s: string) => {
    return atob(s).split(splitter).reduce((o, chain) => {
        const [key, value] = chain.split(operator)
        try {
            return {
                ...o,
                [invertedAbbreviations[key]]: JSON.parse(decodeURIComponent(value)),
            }
        } catch (e) {
            console.warn('error while decoding : ', key, value)
            return o
        }
    }, {})
}

export const toToken = (o): string => {
    const s = Object.keys(o)
                    .map(key => `${abbreviations[key] || key}${operator}${encodeURIComponent(JSON.stringify(o[key]))}`)
                    .join(splitter)
    return btoa(s)
}
