import {Component, h} from 'preact'
import {Scenario as ScenarioContent} from './Scenario.js'
import {seenScenario} from '../PlayerActions/player.actions'

export class ScenarioScreen extends Component {
    state = {
        current: null,
    }

    componentDidMount() {
        this.setState({
            current: 0,
        })
    }

    next = async () => {
        if (this.state.current >= 2) {
            await this.context.addActions(seenScenario())
        } else {
            this.setState(() => ({
                current: this.state.current + 1,
            }))
        }
    }
    prev = () => {
        this.setState(() => ({
            current: Math.max(0, this.state.current - 1),
        }))
    }


    render({scenario}, {current}, _) {
        return <ScenarioContent scenario={scenario} current={current} onNext={this.next} onPrev={this.prev}/>
    }
}
