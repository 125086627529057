const max = require('date-fns/max')
const min = require('date-fns/min')
const addDays = require('date-fns/add_days')
const differenceInDays = require('date-fns/difference_in_days')
const differenceInCalendarWeeks = require('date-fns/difference_in_calendar_weeks')
const startOfDay = require('date-fns/start_of_day')
const isWeekend = require('date-fns/is_weekend')
const isAfter = require('date-fns/is_after')
const isBefore = require('date-fns/is_before')
const endOfDay = require('date-fns/end_of_day')

export function sortThemes(actualCampaign) {
    const themes = Object.keys(actualCampaign.themes || {})
                         .map(key => ({
                             key,
                             ...actualCampaign.themes[key],
                         }))
                         .sort((a, b) => a.order - b.order)
    const dates = themesDates(actualCampaign.settings.startAt, actualCampaign.settings.endAt, themes.length)
    dates.forEach((date, i) => Object.assign(themes[i], date))
    return themes
}

export function currentThemeFromThemes(themes) {
    const now = new Date()
    return themes.find(({startAt, endAt}) => {
        const hasStarted = isAfter(now, startOfDay(startAt))
        const isNotFinished = isBefore(now, endOfDay(endAt))
        return hasStarted && isNotFinished
    })
}

export function themesDates(startAt, endAt, themesCount) {
    const start = startOfDay(min(startAt, endAt))
    const rawEndDay = max(startAt, endAt)
    const end = startOfDay(addDays(rawEndDay, 1))

    const days = differenceInDays(end, start)

    const weeks = Math.floor(days / 7)
    const rest = days % 7

    let weekendsCount = weeks
    if (rest !== 0) {
        const lastPlainWeek = addDays(rawEndDay, -rest)
        if (differenceInCalendarWeeks(rawEndDay, lastPlainWeek) !== 0) {
            weekendsCount++
        }
    }
    const workDays = days - weekendsCount * 2

    const minSize = Math.trunc(workDays / themesCount)
    return Array(themesCount)
        .fill(null)
        .reduce((result, _, i) => {
            const [lastDates] = result
            const startAt = lastDates ? addWorkDays(lastDates.endAt, 1) : start
            const endAt = (i + 1) === themesCount ? rawEndDay : addWorkDays(startAt, minSize - 1)
            return [{
                startAt: startAt,
                endAt  : endAt,
            }].concat(result)
        }, [])
        .reverse()
}

export function addWorkDays(date, workDays) {
    const result = addDays(date, workDays)

    const weeks = differenceInCalendarWeeks(result, date)
    const isItWeekend = !isWeekend(result)
    return weeks === 0 && isItWeekend ? result : addDays(result, 2)
}
