import {LoginFormContainer} from '../style/ui/forms'
import {h} from 'preact'
import {Paragraph, TitlePage} from '../style/ui/text'
import {TextLink} from '../style/ui/links'
import {forScreen} from '../../common/i18n'
import {loginUrls} from './loginUrls'

const t = forScreen('login')

export const ResetPasswordSuccess = (({errors}, {goTo}) => <LoginFormContainer>
    <TitlePage>{t('changePassword')}</TitlePage>
    <Paragraph>{t('passwordReseted')}</Paragraph>
    <TextLink onClick={() => goTo(loginUrls.login)}>{t('logIn')}</TextLink>
</LoginFormContainer>)
