import {styled} from '../../style/styled'
import {h} from 'preact'

export const Progress = ({text, progress, icon: Icon, color, ...props}) => <Container {...props}>
    <Bar progress={progress} color={color}/>
    {
        Icon && <IconWrapper progress={progress}>
            <Icon/>
        </IconWrapper>
    }
</Container>

const Container = styled('div')((_, theme) => ({
    position       : 'relative',
    backgroundColor: theme.colors.cardBackground,
    width          : '100%',
    borderRadius   : '1em',
    padding        : '0.05em',
    border         : '0.1em solid ' + theme.colors.inverted.background,
    boxShadow      : 'inset 0 0 0.3em 0 rgba(0, 0, 0, 0.76)',
    boxSizing      : 'border-box',
}))
const Bar = styled('div')({
    display     : 'block',
    height      : '0.45em',
    transition  : 'all ease-in-out 700ms',
    padding     : 0,
    margin      : 0,
    borderRadius: '1em',
    boxSizing   : 'border-box',
}, ({progress, color}, theme) => ({
    border         : '0.05em solid ' + theme.colors.background,
    width          : Math.round(progress * 100) + '%',
    paddingRight   : progress > 0.05 ? '0.5em' : 0,
    paddingLeft    : progress > 0.05 ? '0.5em' : 0,
    backgroundColor: color || theme.colors.valid,
    color          : theme.colors.text,
}))

const IconWrapper = styled('div')({
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    position      : 'absolute',
    top           : '-0.27em',
    transform     : 'translateX(-50%)',
    height        : '1em',
    width         : '1em',
    boxShadow     : `0 0 0.3em 0 rgba(0, 0, 0, 0.76)`,

    borderRadius: '50%',


    '& > *': {
        height: '0.6em',
    },
}, ({progress}, theme) => ({
    left           : `${Math.round(progress * 100)}%`,
    backgroundColor: theme.colors.inverted.background,
    border         : `0.05em solid ${theme.colors.background}`,
}))
