import '@babel/polyfill'
import {Component, h, render} from 'preact'
import {AuthWrapper} from '../Auth/AuthWrapper'
import {ManagerSignUp} from '../Auth/ManagerSignup.screen'
import {App} from './app'
import {goTo} from '../utils/appRoute'
import {firebaseApp} from '../firebase'

class Container extends Component {
    getChildContext() {
        return {
            goTo: goTo,
        }
    }

    render() {
        return <AuthWrapper components={{SignUpScreen: ManagerSignUp}}>
            <App/>
        </AuthWrapper>
    }
}

window.firebase = firebaseApp

render(<Container/>, document.querySelector('#app'), document.querySelector('#app *'))
