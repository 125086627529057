import {Component, h} from 'preact'
import {TeamPreview} from './TeamPreview'
import {BlockTitle} from '../style/Titles'
import {MobileLogo} from '../style/Nav'
import {Card} from '../style/Cards'
import TeamIcon from '../style/imgs/team.svg'
import {IconButton} from '../style/buttons'
import {Plus} from 'preact-feather'
import {styled} from '../../style/styled'

export class TeamsList extends Component {
    render({teams, campaignId, onSelect, onCreate}, {}, {theme}) {
        const teamsList = Object.keys(teams || {}).map(key => ({
            key,
            ...teams[key],
        }))
        return <Container>
            <MobileLogo/>
            <BlockTitle>équipes</BlockTitle>
            <TeamIcon style={iconStyle}/>
            <Card>
                {teamsList.length ? [
                    <ChooseTitle>Choisi ton équipe</ChooseTitle>,
                    teamsList.map(team => <TeamPreview
                        key={team.key}
                        campaignId={campaignId}
                        team={team}
                        onSelect={onSelect}/>
                    )
                ] : <ChooseTitle>Tu es le premier, crée ton équipe.</ChooseTitle>
                }
            </Card>
            {/*<ButtonContainer>
                <IconButton
                    icon={Plus}
                    iconColor={theme.colors.second}
                    color={theme.colors.create}
                    onClick={onCreate}
                >
                    Crée ton équipe</IconButton>
            </ButtonContainer>*/}
        </Container>
    }
}

const ButtonContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    padding      : '0.4em',
    margin       : '0.3em',
})

const ChooseTitle = styled('h1')({
    fontSize     : '1.2em',
    margin       : '0.5em',
    textAlign    : 'center',
    textTransform: 'uppercase'
})

const iconStyle = {
    color       : '#daecf0',
    maxHeight   : '8em',
    marginBottom: '0.7em',
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 0 auto',
    margin       : '1em',
})
