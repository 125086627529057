import {Component, h} from 'preact'
import {BlockTitle, SectionTitle} from '../style/Titles'
import {Card, CardBottom, CardRow} from '../style/Cards'
import {styled} from '../../style/styled'

export class CiviActEnd extends Component {
    goBack = () => {
        this.context.goTo('/challenges')
    }

    render({closeIt, matches: {id}}, {}, {campaign, goTo}) {
        const act = (campaign?.acts || {})[id] || {}
        return <Container>
            <BlockTitle back={this.goBack}>Défis</BlockTitle>
            <Title>Bravo</Title>
            <ActCard>
                <ActText>{act.label}</ActText>
                <Separator/>
                <FeedbackOut dangerouslySetInnerHTML={{__html: act.feedbackOut}}/>
                <RewardTitle>Récompenses</RewardTitle>
                <ActBonus>
                    <ActBonusText>+{act.flouz || 0} <PointsIcon src={require('!!from-file!../style/svgs/quizPoints.svg')}/>CiviFlouz</ActBonusText>
                </ActBonus>
            </ActCard>
            <ActBonuses>
                <ActButton onClick={this.goBack}>Merci</ActButton>
            </ActBonuses>
        </Container>
    }
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 0 auto',
    height       : '100%',
    fontWeight   : 'bold',
    padding      : '0.5em 2em',
})
const Title = SectionTitle.extends({
    borderBottom: 'none !important',
    width       : 'auto !important',
    textAlign   : 'center',
    fontSize    : '2em',
    fontWeight  : 'bolder',
    margin      : '0.2em',
})

const ActText = styled('p')({
    margin    : '0',
    fontSize  : '1.2em',
    lineHeight: '1.3',
    textAlign : 'center',
})
const ActBonuses = styled('div')({
    display       : 'flex',
    alignItems    : 'center',
    margin        : '1em',
    justifyContent: 'center',
})
const ActBonus = CardBottom.extends((_, theme) => ({
    backgroundColor: theme.colors.modalAccept,
    color          : theme.colors.inverted.text,
    textAlign      : 'center',
}))
const ActBonusText = styled('span')({
    display      : 'inline-block',
    verticalAlign: 'middle',
})
const ActButton = styled('button')((_, theme) => ({
    border         : 'none',
    backgroundColor: theme.colors.create,
    color          : theme.colors.text,
    borderRadius   : '0.2em',
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flex           : '0 0 auto',
    margin         : '0.3em',
    letterSpacing  : '1px',
    fontSize       : '1.2em',
    padding        : '0.5em 2em',
}))
const FeedbackOut = styled('p')({
    margin  : '1.5em 0.5em',
    fontSize: '1em',
})

const ActCard = Card.extends({
    flex: '0 0 auto',

})

const Separator = styled('hr')((_, theme) => ({
    border      : 'none',
    borderBottom: '2px solid ' + theme.colors.text,
    width       : '100%',
    boxSizing   : 'border-box',
    padding     : '0 0.5em',
    margin      : '0.2em 0',
}))
const RewardTitle = CardRow.extends((_, theme) => ({
    textTransform  : 'uppercase',
    fontSize       : '1em',
    textAlign      : 'center',
    backgroundColor: theme.colors.inverted.background,
    color          : theme.colors.inverted.text,
}))


const PointsIcon = styled('img')({
    height       : '1em',
    verticalAlign: 'middle',
})
