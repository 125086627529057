import {CampaignTypes} from './campaigns.types'
import {Action, Database} from '../../../common/database'

export const campaignActions = {
    [CampaignTypes.updateCampaign]: [updateCampaign],
    [CampaignTypes.setField]      : [setField],
    [CampaignTypes.update]        : [update],
    [CampaignTypes.updateTeams]   : [updateTeams],
    [CampaignTypes.deleteTeam]    : [deleteTeam],
}

async function updateCampaign(action: Action, database: Database) {
    const {target, data} = action.payload
    database.ref(target).update(data)
}

async function setField(action: Action, database: Database) {
    const {target, field, data} = action.payload
    await database.ref(target).child(field).set(data)
}

async function update(action: Action, database: Database) {
    const {target, data} = action.payload
    await database.ref(target).update(data)
}

async function updateTeams(action: Action, database: Database) {
    const {target, data} = action.payload
    await database.ref(target).child('teams').update(data)
}

async function deleteTeam(action: Action, database: Database) {
    const {target} = action.payload
    await database.ref(target).remove()
}

async function updateQuiz(action: Action, database: Database) {
    const {target, data} = action.payload
    await database.ref(target).child('quiz').update(data)
}
