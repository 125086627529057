import {Component, h} from 'preact'
import {currentCustomer} from '../manager/Customer/customer.accessors'
import {IterableObject} from './IterableObject'
import {updatedSort} from './updatedSort'

export const connect = (toConnect) => (Target) =>
    class ConnectedComponent extends Component {
        state = {}
        callbacks = {}

        async componentWillMount() {
            const props = {...this.props, ...(this.context.routerProps||{})}
            const customer = await currentCustomer()
            this.ref = customer.ref
            const keys = toConnect(props, customer)
            Object.keys(keys)
                .forEach(key => {
                    const rawPath = keys[key].replace(/^\//, '')
                    const isRoot = rawPath !== keys[key]
                    const intermediatePath = rawPath.replace(' as Array', '')
                    const isArray = intermediatePath !== rawPath
                    const path = intermediatePath.replace(' as Iterable', '')
                    const isIterable = path !== intermediatePath
                    const childRef = (isRoot ? this.ref.root : this.ref).child(path)
                    this.setState({
                        [key]: isArray ? [] : isIterable ? new IterableObject() : null,
                        [key + 'Ref'] : childRef,
                        refInitialized: true,
                    })
                    this.callbacks[key] = childRef.on('value', (snap) => {
                        let value = snap.val()
                        if (isIterable) {
                            value = IterableObject.withKeys(value)
                        } else if (isArray) {
                            value = updatedSort(Object.keys(value || {}).map(innerKey => ({
                                key: innerKey,
                                ...value[innerKey],
                            })))
                        }
                        this.setState({
                            [key]: value,
                        })
                    })
                })
        }

        componentWillUnmount() {
            Object.keys(this.callbacks).forEach(key => {
                const props = {...this.props, ...(this.context.routerProps||{})}
                const keys = toConnect(props)
                const path = keys[key].replace(' as Array', '')
                this.ref?.child(path).off('value', this.callbacks[key])
            })
        }

        render(props, state, _) {
            return state.refInitialized ? <Target {...props} {...state}/> : null
        }
    }
