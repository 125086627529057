export const login = {
    code           : 'Code',
    email          : 'Email',
    name           : 'Nom complet',
    password       : 'Mot de passe',
    passwordConfirm: 'Confirmation du mot de passe',
    signUp         : 'S\'inscrire',
    success        : 'Vous allez recevoir un email de confirmation.',
    logIn          : 'Se connecter',
    forgotPassword : 'Mot de passe oublié',
    changePassword : 'Changer de mot de passe',
    send           : 'Envoyer',
    iRemember      : 'J\'ai retrouvé mon mot de passe',
    emailSent      : 'Email envoyé',
    passwordReseted: 'Mot de passe réinitialisé avec succès. Cliquez sur le lien de validation dans l\'email pour choisir un nouveau mot de passe. Pensez à vérifier vos spams.',
    accountCreated: 'Compte créé avec succès. Cliquez sur le lien de validation dans l\'email pour vous connecter. Pensez à vérifier vos spams.',
}
