import {InputTextWhite, LoginFormContainer} from '../style/ui/forms'
import {h} from 'preact'
import {TitlePage} from '../style/ui/text'
import {TextLink} from '../style/ui/links'
import {ButtonWhite} from '../style/ui/buttons'
import {forScreen} from '../../common/i18n'
import {loginUrls} from './loginUrls'
import {FacebookButton} from './FacebookButton'

const t = forScreen('login')

export const Login = (({errors, email}, {goTo}) => <LoginFormContainer>
    <TitlePage>{t('logIn')}</TitlePage>
    {errors}
    <InputTextWhite placeholder={t('email')} name="email" defaultValue={email || ''}/>
    <InputTextWhite placeholder={t('password')} name="password" type="password"/>
    <ButtonWhite>{t('logIn')}</ButtonWhite>
    <FacebookButton/>
    <TextLink onClick={() => goTo(loginUrls.signUp)}>{t('signUp')}</TextLink>
    <TextLink onClick={() => goTo(loginUrls.forgotPassword)}>{t('forgotPassword')}</TextLink>
</LoginFormContainer>)
