import {styled} from '../styled'

export const TextLink = styled('a')(({color = 'white', size = '1.25em'}) => ({
    textDecoration: 'none',
    color         : color,
    margin        : '10px',
    fontSize      : size,
    width         : '100%',
    textAlign     : 'center',
    cursor        : 'pointer',
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
}))

export const ButtonLink = styled('a')(({}) => ({
    width      : '50px',
    height     : '50px',
    marginRight: '10px',
    cursor     : 'pointer',
}))

export const ImgLink = styled('img')(({active = false}) => ({
    width          : '5vw',
    maxWidth       : '30px',
    minHeight      : '3vh',
    padding        : '10px 15px',
    backgroundColor: active ? '#7BCBD8' : 'none',
    cursor         : 'pointer',

    ':hover': {
        backgroundColor: '#7BCBD8'
    }
}))
