import {database, firebaseApp} from '../firebase'
import {createRealTimeDatabase, mergeActions} from '../../common/database'
import {ManagerActions} from '../manager/manager.actions'
import {UserReducers} from '../../common/User/User.reducers'
import {PlayerReducers} from '../app/PlayerActions/player.reducers'

const {emit} = createRealTimeDatabase(database, mergeActions(ManagerActions, UserReducers, PlayerReducers), {
    notify: notify
})

export const addActions = async (...actions) => {
    const currentUser = firebaseApp.auth().currentUser
    console.log(currentUser)
    if (currentUser) {
        actions.forEach(async (action) => {
            action.triggeredBy = currentUser.uid
            await emit(action)
        })
    } else {
        throw new Error('Not logged in')
    }
}

export function completePathFromRef(ref) {
    return rawPathFromRef(ref).replace(/^\//, '')
}

function rawPathFromRef(ref) {
    if (!ref || !ref.key) return ''
    return completePathFromRef(ref.parent) + '/' + ref.key
}

async function notify(notification) {
    const currentUser = firebaseApp.auth().currentUser

    if (notification.payload) {
        notification.payload.LINK = 'https://app.civitime.com' + formatLink(notification.payload.LINK)
    }

    return await database.ref('users').child(currentUser.uid).child('notifications').push(notification)
}

function formatLink(link) {
    if (!link) return ''

    return '/' + link.split('/').filter(Boolean).join('/')
}
