import {styled} from '../../style/styled'
import {h} from 'preact'
import {ChevronLeft} from 'preact-feather'

export const BlockTitle = ({back, children, ...props}) => <BlockWrapperTitle {...props}>
    {back && <BackButton id="back-button" onClick={back}><ChevronLeft size="1.8em"/></BackButton>}
    <BlockInnerTitle>{children}</BlockInnerTitle>
</BlockWrapperTitle>

export const SectionTitle = styled('h2')((_, theme) => ({
    fontSize     : '1em',
    letterSpacing: '1px',
    borderBottom : '1px solid ' + theme.colors.inverted.text + '50',
    width        : '80%',
    margin       : '0.2em 0.1em 1em',
    lineHeight   : 1,
    textTransform: 'uppercase',
}))

const BackButton = styled('button')((_, theme) => ({
    color     : theme.colors.inverted.text,
    position  : 'absolute',
    background: 'transparent',
    border    : 'none',
    outline   : 'none',
    cursor    : 'pointer',
    left      : 0,
    top       : 0,
    fontSize  : '1.1em',
    padding   : 0,
    margin    : 0,
})).withProps({
    type: 'button'
})

const BlockInnerTitle = styled('h1')({
    fontSize: '1em',
    padding : 0,
    margin  : 0,
})
const BlockWrapperTitle = styled('div')((_, theme) => ({
    position       : 'relative',
    textAlign      : 'center',
    fontWeight     : '900',
    textTransform  : 'uppercase',
    letterSpacing  : '1px',
    backgroundColor: theme.colors.inverted.background,
    color          : theme.colors.inverted.text,
    fontSize       : '1.2em',
    padding        : '0.5em',
    lineHeight     : 1,
    margin         : '0.8em 0',
    borderRadius   : '2em',
}))
