import {styled} from '../../style/styled'
import {managerTheme} from './managerTheme'

export const Card = styled('div')(({color = managerTheme.colors.background, text = managerTheme.colors.text, inactive, paddingSize = '1rem'}) => ({
    boxShadow      : managerTheme.shadow(10),
    backgroundColor: inactive ? 'lightgrey' : color,
    color          : text,
    padding        : paddingSize,
    marginBottom   : '1rem',
    borderRadius   : '0.25rem',
    flex           : '1 1 auto',
    filter         : inactive ? 'grayscale(100%)' : 'none',
    '& > h2': {
        textAlign: 'center',
    },
    '& > .card-foot': {
        margin: `0 -${paddingSize} -${paddingSize}`,
    },
    '& > .card-head': {
        margin: `-${paddingSize} -${paddingSize} 0`,
    },
    '& > .card-row': {
        margin: `0 -${paddingSize}`,
    }
}))
export const CardRow = styled('div')({}).withProps({ className: 'card-row' })
export const CardHead = styled('div')({}).withProps({ className: 'card-head' })
export const CardFoot = styled('div')({}).withProps({ className: 'card-foot' })
