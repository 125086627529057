import {connect} from '../../../utils/connect'
import {Component, h} from 'preact'
import {combine} from '../../../utils/combine'
import {
    connectCampaign,
    connectCampaignQuizzes,
    connectCampaignThemes,
    connectQuizzesLibrary
} from '../campaigns.connectors'
import {updateAct, updateQuiz} from '../campaigns.creators'
import React from 'preact-compat'
import 'react-dragula/dist/dragula.css'
import humane from 'humane-js'
import {DragFromLibrary} from '../DragFromLibrary'
import {CampaignQuizDraggable} from './CampaignQuizDraggable'
import {QuizzQuestions} from '../../../app/Quizz/QuizzQuestions'
import {pick, activityUserCode} from '../../../app/PlayerActions/player.reducers'
import {ModalStyle} from '../../../style/modalStyle'
import {CampaignQuizScreen} from './CampaignQuizScreen'
import {CampaignProgress} from '../CampaignProgress/CampaignProgress'
import nanoid from 'nanoid'


export const CampaignQuizzesScreen = connect(combine(
    connectCampaign,
    connectCampaignQuizzes,
    connectQuizzesLibrary,
    connectCampaignThemes,
))
(class CampaignQuizzesScreen extends Component {
    state = {
        currentQuiz: null,
        modalOpened: false,
    }

    save = async (param) => {
        this.setState({
            acts: param
        })
        const paramSave = param.reduce((obj, item) => {
            obj[item.key] = item
            return obj
        }, {})
        await updateQuiz(this.props.campaignRef, paramSave)
        humane.log('Enregistré')
    }

    getPreviewComponent = () => {
        return class QuizzWrapper extends Component {
            quiz = null

            getChildContext() {
                const quizz = this.context.campaign?.quizzes || {}
                const allQuiz = Object.keys(quizz)
                    .map(key => {
                        const responses = quizz[key].responses || {}
                        return ({
                            key,
                            ...quizz[key],
                            responses: ramdomize(Object.keys(responses).map(key => ({
                                key,
                                ...responses[key],
                            })))
                        })
                    })
                    .filter(({theme}) => theme === this.context.currentTheme?.key)

                const questions = pick(allQuiz, 5)
                    .reduce((acc, {key, ... quiz}) => ({
                        ...acc,
                        [key]: quiz,
                    }), {})

                this.quiz = {
                    [activityUserCode()]: {
                        opponent: {key: 'bot'},
                        questions,
                    },
                }

                return {
                    userData: {
                        quiz: this.quiz,
                    }
                }
            }

            render({closeIt}) {
                return <QuizzQuestions closeIt={closeIt}/>
            }
        }
    }

    onAddActions = () => {

    }

    onUrlChange = () => {

    }

    edit = (data) => {
        this.setState({
            modalOpened: true,
            currentQuiz: data,
        })
    }
    create = (data) => {
        this.setState({
            modalOpened: true,
            currentQuiz: data,
        })
    }

    closeModal = () => {
        this.setState({
            modalOpened: false,
            currentQuiz: null,
        })
    }

    onQuizUpdate = (quiz) => {
        this.setState({
            currentQuiz: quiz,
        })
    }

    saveQuiz = async () => {
        const {key, ...quiz} = this.state.currentQuiz

        await updateQuiz(this.props.campaignRef, {[key || nanoid()]: quiz})
        this.closeModal()
        humane.log('Enregistré')
    }

    render({quizzesLibrary, themes, campaignRef, campaign, quizzes, campaignId}, {currentQuiz, modalOpened}, _) {
        return <DragFromLibrary
            pageTitle="Dual-Quiz"
            campaign={campaign}
            library={quizzesLibrary}
            items={quizzes}
            themes={themes}
            edit={this.edit}
            create={this.create}
            component={CampaignQuizDraggable}
            preview={this.getPreviewComponent()}
            onAddActions={this.onAddActions}
            onUrlChange={this.onUrlChange}
            save={this.save}
        >
            <CampaignProgress campaign={campaign} campaignId={campaignId}/>
            {currentQuiz && <ModalStyle
                isOpen={modalOpened}
                onRequestCancel={this.closeModal}
                onRequestClose={this.saveQuiz}
                title="Dual-quiz"
                key={currentQuiz.key || 'quiz'}
            >
                <CampaignQuizScreen
                    quiz={currentQuiz}
                    quizId={currentQuiz?.key}
                    campaignId={campaign?.key}
                    campaign={campaign}
                    onUpdate={this.onQuizUpdate}
                />
            </ModalStyle>}
        </DragFromLibrary>
    }
})

function ramdomize(arr) {
    return shuffle(shuffle(shuffle(shuffle(arr))))
}

function shuffle(arr) {
    return arr ? arr.reduce((acc, item) => Math.random() > 0.5 ? acc.concat(item) : [item].concat(acc), []) : []
}
