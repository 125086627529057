import {Action, ActionsListeners, Database} from '../../../common/database'
import {PlayerTypes} from './player.types'
import {currentThemeFromThemes, sortThemes} from '../../../common/themesDates'
import {notificationsTypes} from '../../../common/notifications'
import {toToken} from '../../../common/lighttoken'
import nanoid from 'nanoid'
import {guides} from '../../../common/guides'
import {generateInviteLink} from './generateInviteLink'

const isAfter = require('date-fns/is_after')
const addHours = require('date-fns/add_hours')
const addDays = require('date-fns/add_days')
const isBefore = require('date-fns/is_before')
const startOfDay = require('date-fns/start_of_day')
const endOfDay = require('date-fns/end_of_day')
const format = require('date-fns/format')


export const maxActsByDay = 3

export const PlayerReducers: ActionsListeners = {
    [PlayerTypes.chooseCampaign]    : [chooseCampaign],
    [PlayerTypes.seenScenario]      : [seenScenario],
    [PlayerTypes.chooseTeam]        : [chooseTeam],
    [PlayerTypes.startAct]          : [startAct],
    [PlayerTypes.endAct]            : [endAct],
    [PlayerTypes.findQuizOpponent]  : [findQuizOpponent],
    [PlayerTypes.answerQuizQuestion]: [answerQuizQuestion],
    [PlayerTypes.createTeam]        : [createTeam],
    [PlayerTypes.inviteTeam]        : [inviteTeam],
    [PlayerTypes.invitePlayer]      : [invitePlayer],
    [PlayerTypes.lostGame]          : [lostGame],
    [PlayerTypes.wonGame]           : [wonGame],
    [PlayerTypes.guideStep]         : [guideStep],
    [PlayerTypes.buyJob]            : [buyJob],
    [PlayerTypes.seenJob]           : [seenJob],
    [PlayerTypes.resetProfile]      : [resetProfile],
    [PlayerTypes.addADay]           : [addADay],
}

async function chooseCampaign(action: Action, database: Database) {
    const {triggeredBy, payload: {code}} = action
    await database.ref('users').child(triggeredBy).child('currentCampaign').set(code)
}

async function seenScenario(action: Action, database: Database) {
    const {triggeredBy} = action
    const code = await getCampaignCode(triggeredBy, database)
    await database.ref('campaigns').child(code).child('users').child(triggeredBy).child('seenScenario').set(true)
}

async function getCampaignCode(userId, database: Database) {
    return (await database.ref('users').child(userId).child('currentCampaign').once('value')).val()
}

async function startAct(action: Action, database: Database) {
    const {triggeredBy, payload: {act}} = action
    const code = await getCampaignCode(triggeredBy, database)
    const actsRef = database.ref('campaigns').child(code).child('users').child(triggeredBy).child('acts')
    const rawActualActs = (await actsRef.once('value')).val()
    const now = await getNow(code, triggeredBy, database)

    const acts = Object.keys(rawActualActs || {})
                       .map(key => ({
                           key,
                           ...rawActualActs[key],
                       }))
                       .filter(({key, started, done}) => {
                           return started && !done
                       })
    if (acts.length > maxActsByDay) {
        return
    }
    await actsRef.child(act).update({
        started: now,
    })
}

async function endAct(action: Action, database: Database) {
    const {triggeredBy, payload: {act}} = action

    const code = await getCampaignCode(triggeredBy, database)
    const now = await getNow(code, triggeredBy, database)
    const actRef = database.ref('campaigns').child(code).child('acts').child(act)
    const actValue = (await actRef.once('value')).val() || {}
    const userRef = database.ref('campaigns').child(code).child('users').child(triggeredBy)
    const userActRef = userRef.child('acts').child(act)
    const userAct = (await userActRef.once('value')).val() || {}
    const date = addDays(userAct.started, actValue.duration)
    if (isAfter(startOfDay(date), endOfDay(now))) {
        return
    }
    await userActRef.update({done: now})
    await addFlouz(userRef, actValue.flouz)
}

async function findQuizOpponent(action: Action, database: Database, {notify}) {
    const {triggeredBy} = action

    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const campaign = (await campaignRef.once('value')).val()
    const currentTheme = currentThemeFromThemes(
        sortThemes(campaign),
    )
    const usersRef = campaignRef.child('users')
    const players = campaign.users
    const now = await getNow(code, triggeredBy, database)
    const todayQuizKey = activityUserCode(now)

    const snap = await usersRef.child(triggeredBy).child('quiz').child(todayQuizKey).once('value')
    if (snap.exists()) return
    const opponents = Object.keys(players)
                            .filter(key => triggeredBy !== key && key != 'bot')
                            .map(key => ({
                                key,
                                ...players[key],
                            }))
                            .filter(({quiz}) => !quiz || !quiz[todayQuizKey])
    let opponentKey = 'bot/' + triggeredBy
    if (opponents.length > 0) {
        let opponentIndex = Math.floor(Math.random() * opponents.length)
        opponentKey = opponents[opponentIndex].key
    }

    const quiz = campaign.quizzes
    const allQuestions = Object
        .keys(quiz)
        .map(key => ({
            key,
            ...quiz[key],
        }))
        .filter(quiz => quiz.theme === (currentTheme && currentTheme.key))
    const questions = pick(allQuestions, 5).reduce((acc, {key, ... quiz}) => ({
        ...acc,
        [key]: {
            ...quiz,
            responses: ramdomize(Object.keys(quiz.responses).map(key => quiz.responses[key])),
        },
    }), {})

    const isBot = opponentKey.indexOf('bot') === 0
    await Promise.all([
        usersRef.child(triggeredBy).child('quiz').child(todayQuizKey).set({
            opponent: {key: opponentKey},
            questions,
        }),
        usersRef.child(opponentKey).child('quiz').child(todayQuizKey).set({
            opponent : {key: triggeredBy},
            questions: !isBot ? questions : Object.keys(questions).map(key => {
                const question = questions[key]
                const answers = Object.keys(question.responses)
                return ({
                    key,
                    ...question,
                    answer: selectRandom(answers),
                })
            }).reduce((acc, {key, ...question}) => ({
                ...acc,
                [key]: question,
            }), {}),
        }),
    ])

    if (isBot) return


    const playername = (await database.ref('users').child(opponentKey).child('name').once('value')).val()
    const opponentname = (await database.ref('users').child(triggeredBy).child('name').once('value')).val()
    notify({
        type   : notificationsTypes.quizInvite,
        payload: {
            for          : opponentKey,
            PLAYER_NAME  : playername,
            OPPONENT_NAME: opponentname,
        },
    })
}

async function answerQuizQuestion(action: Action, database: Database, {notify}) {
    const {triggeredBy, payload: {question, answer}} = action

    const code = await getCampaignCode(triggeredBy, database)
    const now = await getNow(code, triggeredBy, database)
    const todayQuizKey = activityUserCode(now)
    const usersRef = database.ref('campaigns').child(code).child('users')
    const meRef = usersRef.child(triggeredBy)
    const quizRef = meRef.child('quiz').child(todayQuizKey)

    const quiz = (await quizRef.once('value')).val()
    if (!quiz || !quiz.questions || !quiz.questions[question] || quiz.questions[question].answer) return

    await quizRef.child('questions').child(question).child('answer').set(answer)
    const opponentRef = usersRef.child(quiz.opponent.key)
    const isBot = quiz.opponent.key.indexOf('bot') === 0
    const opponentQuizRef = opponentRef.child('quiz')
    const questionsOpponentRef = opponentQuizRef.child(todayQuizKey).child('questions')
    const opponentQuestions = (await questionsOpponentRef.once('value')).val() || {}
    quiz.questions[question].answer = answer
    const iHaveNoMoreQuestionsToAnswer = noMoreQuestions(quiz.questions)
    const ended = iHaveNoMoreQuestionsToAnswer && noMoreQuestions(opponentQuestions)
    if (iHaveNoMoreQuestionsToAnswer) {
        await quizRef.child('answeredAll').set(true)
    }
    if (ended) {
        const goodResponses = Object
            .keys(quiz.questions)
            .reduce((acc, key) => {
                const responses = quiz.questions[key].responses
                return ({
                    ...acc,
                    [key]: Object.keys(responses).filter(key => responses[key].valid),
                })
            }, {})

        const goodResponsesCount = Object
            .keys(quiz.questions)
            .filter(key => goodResponses[key].includes(quiz.questions[key].answer))
            .length
        const opponentGoodResponsesCount = Object
            .keys(opponentQuestions)
            .filter(key => goodResponses[key].includes(opponentQuestions[key].answer))
            .length

        let pointsPromise: any = Promise.resolve()
        if (goodResponsesCount !== opponentGoodResponsesCount) {
            pointsPromise = addFlouz(
                goodResponsesCount < opponentGoodResponsesCount ? opponentRef : meRef,
                2,
            )
        } else {
            pointsPromise = Promise.all([
                addFlouz(meRef, 1),
                addFlouz(opponentRef, 1),
            ])
        }

        await Promise.all([
            quizRef.child('ended').set(true),
            opponentQuizRef.child('ended').set(true),
            pointsPromise,
        ])
        if (isBot) return
        notify({
            type   : notificationsTypes.quizResult,
            payload: {
                for          : opponentRef.key,
                PLAYER_NAME  : (await database.ref('users').child(opponentRef.key).child('name').once('value')).val(),
                OPPONENT_NAME: (await database.ref('users').child(triggeredBy).child('name').once('value')).val(),
            },
        })
    }
}

export async function addFlouz(userRef: firebase.database.Reference, points: number) {
    const snap = await userRef.once('value')
    const user = snap.val()
    if (user.team && points > 0) {
        try {
            // camapaign -> users -> user => campaign -> teams
            const teamSnap = await userRef.parent.parent.child('teams').child(user.team).once('value')
            await teamSnap.ref.child('flouz').set(Number(teamSnap.val().flouz || 0) + Number(points))
        } catch (e) {
            console.warn('Error while adding flouz to team:', user.team)
        }
    }
    return snap.ref.child('flouz').set(Number(user.flouz || 0) + Number(points))
}

export async function addSocial(userRef: firebase.database.Reference, points: number) {
    const snap = await userRef.once('value')
    const user = snap.val()
    if (user.team && points > 0) {
        try {
            // camapaign -> users -> user => campaign -> teams
            const teamSnap = await userRef.parent.parent.child('teams').child(user.team).once('value')
            await teamSnap.ref.child('socialPoints').set(Number(teamSnap.val().socialPoints || 0) + Number(points))
        } catch (e) {
            console.warn('Error while adding socialPoints to team:', user.team)
        }
    }
    return snap.ref.child('socialPoints').set(Number(user.socialPoints || 0) + Number(points))
}

export async function addEnvironment(userRef: firebase.database.Reference, points: number) {
    const snap = await userRef.once('value')
    const user = snap.val()
    if (user.team && points > 0) {
        try {
            // camapaign -> users -> user => campaign -> teams
            const teamSnap = await userRef.parent.parent.child('teams').child(user.team).once('value')
            await teamSnap.ref.child('environmentPoints').set(Number(teamSnap.val().environmentPoints || 0) + Number(points))
        } catch (e) {
            console.warn('Error while adding environmentPoints to team:', user.team)
        }
    }
    return snap.ref.child('environmentPoints').set(Number(user.environmentPoints || 0) + Number(points))
}

function noMoreQuestions(questions) {
    return Object.keys(questions)
                 .map(key => ({
                     key,
                     ...questions[key],
                 }))
                 .every(quiz => quiz.answer)
}

export function pick<T>(from: Array<T>, count: Number): Array<T> {
    return [...from].reduce((acc, value) => {
        const nextIndex = Math.floor(acc.length * Math.random())
        acc.splice(nextIndex, 0, value)
        return acc
    }, []).filter((_, i) => i < count)
}

export function activityUserCode(date = new Date()) {
    return format(date, 'YYYYMMDD')
}

function selectRandom<T>(from: Array<T>): T {
    return from[Math.floor(from.length * Math.random())]
}


async function chooseTeam(action: Action, database: Database) {
    const {triggeredBy, payload: {team}} = action
    const code = await getCampaignCode(triggeredBy, database)
    await database.ref('campaigns').child(code).child('users').child(triggeredBy).child('team').set(team)
}

async function createTeam(action: Action, database: Database, {notify}) {
    const {triggeredBy, payload: {name, invites}} = action
    const user = (await database.ref('users').child(triggeredBy).once('value')).val()

    const campaignCode = user.currentCampaign

    const finalInvites = invites.map(email => ({
        email,
        invitedBy  : triggeredBy,
        temporaryId: nanoid(),
        joined     : false,
    }))
    const teamRef = database.ref('campaigns').child(campaignCode).child('teams').push({
        name   : name,
        invites: finalInvites,
    })

    await database.ref('campaigns').child(campaignCode).child('users').child(triggeredBy).child('team').set(teamRef.key)

    await finalInvites.reduce((acc, {email, temporaryId}) => acc.then(() => {
            return notify({
                type   : notificationsTypes.teamInvite,
                payload: {
                    forEmail   : email,
                    EMAIL      : email,
                    PLAYER_NAME: user && user.name,
                    TEAM_NAME  : name,
                    INVITE_LINK: `https://app.civitime.com/auth/invite/${toToken({
                        email      : email,
                        team       : teamRef.key,
                        code       : campaignCode,
                        temporaryId: temporaryId,
                    })}`,
                },
            })
        },
    ), Promise.resolve())

}

async function inviteTeam(action: Action, database: Database, {notify}) {
    const {triggeredBy, payload: {email}} = action
    const user = (await database.ref('users').child(triggeredBy).once('value')).val()
    const campaignCode = user.currentCampaign
    const teamKey = (await database.ref('campaigns').child(campaignCode).child('users').child(triggeredBy).child('team').once('value')).val()
    const teamRef = database.ref('campaigns').child(campaignCode).child('teams').child(teamKey)
    const team = (await teamRef.once('value')).val()
    const temporaryId = nanoid()
    await teamRef.child('invites').set((team.invites || []).concat({
        email,
        invitedBy  : triggeredBy,
        temporaryId: temporaryId,
        joined     : false,
    }))
    await notify({
        type   : notificationsTypes.teamInvite,
        payload: {
            forEmail   : email,
            EMAIL      : email,
            PLAYER_NAME: user && user.name,
            TEAM_NAME  : team.name,
            INVITE_LINK: generateInviteLink({
                email      : email,
                team       : teamKey,
                code       : campaignCode,
                temporaryId: temporaryId,
            }),
        },
    })
}

async function invitePlayer(action: Action, database: Database, {notify}) {
    const {triggeredBy, payload: {email}} = action
    const userRef = database.ref('users').child(triggeredBy)
    const user = (await userRef.once('value')).val()
    const temporaryId = nanoid()
    await userRef.child('invites').push({
        email,
        invitedBy  : triggeredBy,
        temporaryId: temporaryId,
        joined     : false,
    })
    const campaignCode = user.currentCampaign
    await notify({
        type   : notificationsTypes.playerInvite,
        payload: {
            forEmail   : email,
            EMAIL      : email,
            PLAYER_NAME: user && user.name,
            INVITE_LINK: generateInviteLink({
                email      : email,
                code       : campaignCode,
                temporaryId: temporaryId,
            }),
        },
    })
}

async function lostGame(action: Action, database: Database, {notify}) {
    const {triggeredBy} = action

    const code = await getCampaignCode(triggeredBy, database)
    const now = await getNow(code, triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const usersRef = campaignRef.child('users')
    const todayQuizKey = activityUserCode(now)

    const snap = await usersRef.child(triggeredBy).child('games').child(todayQuizKey).once('value')
    if (snap.exists()) {
        return
    }

    await snap.ref.set(false)
}

async function wonGame(action: Action, database: Database, {notify}) {
    const {triggeredBy} = action

    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const usersRef = campaignRef.child('users')
    const now = await getNow(code, triggeredBy, database)
    const todayKey = activityUserCode(now)

    const snap = await usersRef.child(triggeredBy).child('games').child(todayKey).once('value')
    if (snap.exists()) {
        return
    }

    await snap.ref.set(true)
    await addFlouz(usersRef.child(triggeredBy), 2)
}

async function guideStep(action: Action, database: Database, {notify}) {
    const {payload: {guideKey}, triggeredBy} = action
    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)

    const userRef = campaignRef
        .child('users')
        .child(triggeredBy)
    const guideRef = userRef
        .child('guide')
        .child(guideKey)
    const guideState = (await guideRef.once('value')).val()
    if (guideState) return
    await guideRef
        .set(true)
    const guide = guides({user: {}, userData: {}}).find(({key}) => key === guideKey)
    if (!guide) return
    if (guide.bonus) {
        await addFlouz(userRef, guide.bonus)
    }
    if (guide.bonusEnvironment) {
        await addEnvironment(userRef, guide.bonusEnvironment)
    }
    if (guide.bonusSocial) {
        await addSocial(userRef, guide.bonusSocial)
    }
}

async function buyJob(action: Action, database: Database, {notify}) {
    const {payload: {jobKey, bought}, triggeredBy} = action

    const job = (await database.ref('civijobs').child(jobKey).once('value')).val()
    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const userRef = campaignRef
        .child('users')
        .child(triggeredBy)
    const userJobRef = userRef
        .child('jobs')
        .child(jobKey)
    const userJob = (await userJobRef.once('value')).val()
    if (userJob) return
    await addFlouz(userRef, -job.cost)
    await userJobRef
        .child('bought')
        .set(bought)

}

async function seenJob(action: Action, database: Database, {notify}) {
    const {payload: {jobKey, seen}, triggeredBy} = action


    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const rawJob = (await database.ref('civijobs').child(jobKey).once('value')).val()
    if (!rawJob) return

    const userRef = campaignRef
        .child('users')
        .child(triggeredBy)
    const jobRef = userRef
        .child('jobs')
        .child(jobKey)

    const job = (await jobRef.once('value')).val()

    if (!job) return
    if (job.seen) return
    if (isBefore(seen, addHours(job.bought, rawJob.productionTime))) return

    await jobRef
        .child('seen')
        .set(seen)

    if (rawJob.socialPoints != 0) {
        await addSocial(userRef, rawJob.socialPoints)
    }
    if (rawJob.environmentPoints != 0) {
        await addEnvironment(userRef, rawJob.environmentPoints)
    }
}

function ramdomize(arr) {
    return shuffle(shuffle(shuffle(shuffle(arr))))
}

function shuffle(arr) {
    return arr ? arr.reduce((acc, item) => Math.random() > 0.5 ? acc.concat(item) : [item].concat(acc), []) : []
}

async function resetProfile(action: Action, database: Database, {notify}) {
    const {triggeredBy} = action

    const code = await getCampaignCode(triggeredBy, database)

    await database.ref('campaigns').child(code).child('users').child(triggeredBy).remove()
}

async function addADay(action: Action, database: Database, {notify}) {
    const {triggeredBy} = action

    const code = await getCampaignCode(triggeredBy, database)
    const campaignRef = database.ref('campaigns').child(code)
    const dateRef = campaignRef.child('users').child(triggeredBy).child('date')
    const date = (await dateRef.once('value')).val() || new Date()
    const newDate = addDays(date, 1)
    try {
        await dateRef.set(newDate.toISOString())
    } catch (e) {
        console.log(e)
    }
}

async function getNow(campaignCode: string, userId: string, database: Database) {
    const now = new Date()
    const campaignRef = database.ref('campaigns').child(campaignCode)
    const type = (await campaignRef.child('type').once('value')).val()
    if (type !== 'test') {
        return now
    }
    const dateRef = campaignRef.child('users').child(userId).child('date')
    const date = (await dateRef.once('value')).val()
    if (!date) {
        dateRef.set(now.toISOString())
        return now
    }
    return date
}

