import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {BlockTitle} from '../style/Titles'
import {Button} from '../style/buttons'


export class QuizzQuestion extends Component {
    render({question, item, count, choose}, {}, _) {
        return question ? <Container>
            <Title>Dual Quizz</Title>
            <QuestionCount>Question {item}/{count}</QuestionCount>
            <Text>{question.question}</Text>
            {(question.responses || []).filter(Boolean).map(response => <Response onClick={() => {
                choose(question, response)
            }}>{response.response}</Response>)}
        </Container> : null
    }
}

const Container = styled('article')({
    display      : 'flex',
    flexDirection: 'column',
    height       : '100%',
    alignSelf    : 'stretch',
    overflowY    : 'auto',
    boxSizing    : 'border-box',
    padding      : '0.5em 2em',
})

const Title = styled(BlockTitle)({
    fontSize: '1.2em',
    margin  : 0,
})

const QuestionCount = styled('p')((_, theme) => ({
    fontSize  : '1.2em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color   : theme.colors.text,
    margin  : '0.5em 0'
}))

const Text = styled('p')({
    fontSize  : '1em',
    fontWeight: 'bold',
})

const Response = Button.extends({
    fontSize     : '1.2em',
    margin       : '0.2em',
    textTransform: 'normal',
})
