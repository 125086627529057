import {Component, h} from 'preact'
import {Info, InfoButton, Page} from '../../ui/Page'
import {Input} from '../../ui/Input'
import {UploadInput} from '../../ui/UploadInput'
import {ColorPicker} from '../../ui/ColorPicker'
import {connect} from '../../../utils/connect'
import {styled} from '../../../style/styled'
import {Watcher} from '../../../utils/DataSync'
import {updateCampaign} from '../campaigns.creators'
import {connectCampaign} from '../campaigns.connectors'
import {getFileUrl} from '../../../utils/files'
import humane from 'humane-js'
import 'humane-js/themes/libnotify.css'
import {Column, Row} from '../../ui/RowCol'
import {HelpCircle} from 'preact-feather'
import testlogo from '../../../style/img/test-setting.png'
import {managerTheme} from '../../ui/managerTheme'
import {Card} from '../../ui/Card'
import {AppPreview} from '../../Preview/AppPreview'
import {HomeComponent} from '../../../app/Home/HomeComponent'
import {CampaignProgress} from '../CampaignProgress/CampaignProgress'

const whiteLogo = require('../../../style/img/logo-white.png')

const logoPathFromCampaign = (campaign) => `campaigns/${campaign?.key}/logo`

export const CampaignSettingsScreen = connect(connectCampaign)
(class CampaignSettingsScreen extends Component {
    state = {
        logo    : null,
        settings: null
    }
    unsubscribe = null

    componentWillMount() {
        this.watcher = Watcher(this.props.campaign?.settings || {})
        this.loadLogo(this.props.campaignRef)
        this.unsubscribe = this.watcher
            .subscribe(tree => this.setState({
                settings: tree,
            }))
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe()
    }

    componentWillReceiveProps(nextProps, _) {
        this.watcher.put('')(nextProps.campaign?.settings)
        this.loadLogo(nextProps.campaignRef)
    }

    loadLogo = async (campaign) => {
        if (campaign) {
            const path = logoPathFromCampaign(campaign)
            this.setState({
                logo: await getFileUrl(path)
            })
        }
    }

    updateColor = (color) => {
        this.watcher.put('colors/main')(color)
        this.save()
    }

    save = async () => {
        const settings = this.watcher.get('')
        delete settings.logo
        await updateCampaign(this.props.campaignRef, {settings})
        await this.uploader?.upload()
        humane.log('Enregistré')
    }

    render({campaign, campaignRef, campaignId}, {logo}, {}) {
        const watcher = this.watcher
        return <Page title="Général" tip={`Changez ici les paramètres généraux de votre campagne.`}>
            <CampaignProgress campaign={campaign} campaignId={campaignId}/>
            <Row>
                <Column>
                    <FieldCard>
                        <h2>Paramètre de votre campagne <InfoButton type="button">
                            <HelpCircle size='30px'/>
                            <Info>Paramètres de votre campagne</Info>
                        </InfoButton></h2>
                        <Input type="text" placeholder="Nom de votre campagne" value={watcher.get('name')}
                               onInput={watcher.put('name')}
                               onBlur={this.save}
                        />
                    </FieldCard>
                    <FieldCard>
                        <Input type="number" placeholder="Nombre de collaborateurs participant à la campagne"
                               onInput={watcher.put('licencesCount')}
                               value={watcher.get('licencesCount')}
                               onBlur={this.save}
                        />
                    </FieldCard>
                    <FieldCard>
                        <Input type="text" placeholder="Code d'inscription" onInput={watcher.put('code')}
                               value={watcher.get('code')}
                               onBlur={this.save}
                        />
                    </FieldCard>

                </Column>
                <Column>
                    <FieldCard>
                        <h2>Personnaliser votre application <InfoButton type="button">
                            <HelpCircle size='30px'/>
                            <Info>Permet de configurer l'application à l'image de votre entreprise</Info>
                        </InfoButton></h2>
                        <Row>
                            <Column>
                                <TitleSetting number={'1'}>Charger votre logo pour personnaliser vos
                                    campagnes</TitleSetting>
                                <UploadInput
                                    size="10vw"
                                    ref={(ref) => this.uploader = ref}
                                    value={this.state.logo}
                                    onChange={this.watcher?.put('logo')}
                                    path={logoPathFromCampaign(campaignRef)}
                                />
                            </Column>
                            <Column>
                                <PreviewContainer>
                                    <PreviewImage src={testlogo}/>
                                    <ImageToShow src={watcher.get('logo') || this.state.logo || whiteLogo}/>
                                </PreviewContainer>
                            </Column>
                        </Row>
                    </FieldCard>


                    <FieldCard>
                        <Row overflow={'visible'}>
                            <div>
                                <TitleSetting number={'2'}>Choisir une couleur pour personnaliser l’appli</TitleSetting>
                                <Row overflow={'visible'}>
                                    <ColorPicker color={watcher.get('colors/main') || managerTheme.colors.action}
                                                 first="true"
                                                 label="Couleur"
                                                 onChange={this.updateColor}
                                    />
                                </Row>
                            </div>
                            <Column>
                                <FalsePreview/>
                            </Column>
                        </Row>
                    </FieldCard>
                </Column>
            </Row>
        </Page>
    }
})

const PreviewContainer = styled('div')({
    width   : '11.1vw',
    height  : '10vw',
    position: 'relative',
})

const PreviewImage = styled('img')({
    width   : '11.1vw',
    height  : '10vw',
    position: 'relative',
})

const ImageToShow = styled('img')({
    borderRadius: '50%',
    width       : '2.6vw',
    height      : '2.6vw',
    position    : 'absolute',
    top         : '1.9vw',
    left        : '0.65vw',
})

const TitleSetting = styled('div')(({number}) => ({
    fontWeight    : 'bold',
    fontFamily    : 'Raleway',
    fontSize      : '18px',
    fontStyle     : 'normal',
    fontStretch   : 'normal',
    lineHeight    : 'normal',
    letterSpacing : '0.3px',
    color         : '#3d3d3d',
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',

    ':before': {
        position      : 'absolute',
        display       : 'flex',
        justifyContent: 'center',
        alignItems    : 'center',
        content       : number,
        fontSize      : '80px',
        opacity       : '0.4',
        color         : '#68c4a2'
    }
}))

const FieldCard = Card.extends({
    backgroundColor: managerTheme.colors.cardBackground
})

const FalsePreview = styled('div')({
    width             : '10vw',
    height            : '10vw',
    borderRadius      : '50%',
    overflow          : 'hidden',
    backgroundColor   : managerTheme.colors.background,
    backgroundImage   : `url(${require('./illusion2.png')})`,
    backgroundSize    : '200%',
    backgroundPosition: '-1vw -1vw',
})

const ToRight = styled('dix')({
    display       : 'flex',
    justifyContent: 'flex-end',
})
