import {toToken} from '../../../common/lighttoken'

type InvitePayload = {
    email?: string,
    code?: string,
    temporaryId?: string,
    team?: string,
}

export function generateInviteLink(payload: InvitePayload) {
    return `https://app.civitime.com/auth/invite/${toToken(payload)}`
}
