import {h} from 'preact'
import {styled} from '../../style/styled'
import {Avatar} from '../style/Avatar'

export const PlayerPreview = ({}, {user, userData, authUser, campaign, teamId, currentTheme, ...context}) => {
    return <Container>
        <ThemeName>{currentTheme?.name}</ThemeName>
        <PlayerDetails>
            <SimpleRow>
                <AvatarContainer>
                    <Avatar size="2.2em" src={user?.avatar || require('!!from-file!../style/svgs/avatar.svg')}/>
                </AvatarContainer>
                <Name>
                    <PlayerName>{user?.name || authUser?.displayName}</PlayerName>
                    <TeamName>{campaign?.teams[teamId]?.name}</TeamName>
                </Name>
            </SimpleRow>
            <SimpleColumn>
                <PointsBar>
                    <PointsIcon src={require('!!from-file!../style/svgs/quizPoints.svg')}/>
                    <PointsText>{userData?.flouz || 0}</PointsText>
                </PointsBar>
            </SimpleColumn>
        </PlayerDetails>
    </Container>
}

const Container = styled('section')((_, theme) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius   : '0.5em',
    color          : theme.colors.inverted.text,
    fontWeight     : 'bold',
    padding        : '0.1em',
}))

const PlayerDetails = styled('article')({
    display       : 'flex',
    flexDirection : 'row',
    justifyContent: 'space-between',
    alignItems    : 'stretch',
    '& > *'       : {
        flexBasis: '50% !important',
    }
})
const Name = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'space-evenly',
    alignItems    : 'flex-start',
})
const PlayerName = styled('h1')({
    fontSize     : '0.7em',
    textTransform: 'uppercase',
    fontWeight   : 'normal',
    margin       : '0',
})
const ThemeName = styled('h2')({
    fontSize  : '0.8em',
    fontWeight: 'normal',
    margin    : '0',
    textAlign : 'center',
})
const TeamName = styled('h2')({
    fontSize  : '0.7em',
    fontWeight: 'normal',
    margin    : '0',
    display   : 'flex',
    alignItems: 'flex-end',
    '& > *'   : {
        flex: '0 0 auto',
    }
})

const SimpleRow = styled('div')({
    display       : 'flex',
    flexDirection : 'row',
    flex          : '1 1 auto',
    justifyContent: 'flex-start',
})
const SimpleColumn = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    flex          : '1 1 auto',
    justifyContent: 'center',
    alignItems    : 'center',
})

const AvatarContainer = styled('div')({
    padding: '0.1em 0.3em'
})

const PointsBar = styled('div')((_, theme) => ({
    position       : 'relative',
    backgroundColor: theme.colors.inverted.background,
    display        : 'flex',
    borderRadius   : '0.3em',
    height         : '1.1em',
    minWidth       : '3em',
    padding        : '0.15em 0.5em 0.15em 1.2em',
    margin         : 'auto',
}))

const PointsText = styled('p')((_, theme) => ({
    margin    : 0,
    fontSize  : '0.7em',
    fontWeight: 'bold',
    lineHeight: '1.6',
    textAlign : 'center',
    flex      : '1 0 auto',
    color     : theme.colors.inverted.text,
}))

const PointsIcon = styled('img')({
    width   : 'auto',
    height  : 'calc(100% + 0.7em)',
    position: 'absolute',
    left    : '-0.7em',
    top     : '-0.3em',
})
