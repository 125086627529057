export const managerTheme = {
    colors: {
        background    : '#EEEEEE',
        disabled      : '#7e7e7e',
        text          : '#3D3D3D',
        lightText     : '#FFF',
        action        : '#40C9A2',
        alphaAction   : 'rgba(64, 201, 162, 0.4)',
        selected      : '#55B99C',
        highlightedRow: '#3D3D3D',
        alpha         : '40%',
        navBackground : '#3D3D3D',
        navHighlight  : '#9fd0de6b',
        get navText() {
            return this.background
        },
        get lightBackground() {
            return this.navBackground + '14'
        },
        get backdrop() {
            return this.navBackground + '50'
        },
        alert         : '#a94442',
        cardBackground: '#FFFFFF',
        inputBorder   : '#979797',
        navSelected   : '#473198',
    },
    shadow: (size = 5) => `${Math.trunc(size / 3)}px ${Math.trunc(size / 3)}px ${size}px rgba(0, 0, 0, 16%)`,
}
