import {Component, h} from 'preact'
import {BlockTitle} from '../style/Titles'
import {styled} from '../../style/styled'
import {CiviActPreview} from './CiviActPreview'
import {endAct, startAct} from '../PlayerActions/player.actions'
import {addWorkDays} from '../../../common/themesDates'
import {ModalTitle} from '../Modals/Modals'
import {guideAct} from '../../../common/guides.js'
import {maxActsByDay} from '../PlayerActions/player.reducers'

const format = require('date-fns/format')

export class CiviActs extends Component {
    state = {}

    seeMore = async (act) => {
        const userAct = (this.context.userData?.acts || {})[act.key]
        const started = userAct && !userAct.done
        if (started && !act.active) {
            return this.showWaitModal(act, userAct)
        }
        if (started) {
            return this.showEndModal(act)
        }
        if (userAct?.done) {
            return this.showFinalModal(act)
        }
        if (this.context.startedActs?.length >= maxActsByDay) {
            return await this.context.popups.alert({
                content: `Vous avez atteint la limite de ${maxActsByDay} défis en cours par jour.`
            })
        }
        const result = await this.context.popups.confirm({
            content: <ModalContent>
                <ModalTitle>Relever le défi ?</ModalTitle>
                <DefiIcon src={require('../style/imgs/sprout.png')}/>
                <ActLabel>{act.label}</ActLabel>
                <ActDescription dangerouslySetInnerHTML={{__html: act.feedbackIn}}/>
            </ModalContent>
        })
        if (!result) return

        await this.context.addActions(startAct(act.key))
    }

    showWaitModal = (act, userAct) => {
        this.context.popups.alert(({
            content: <ModalContent>
                <DefiIcon src={require('../style/imgs/sprout.png')}/>
                <ActLabel>{act.label}</ActLabel>
                <ActDescription dangerouslySetInnerHTML={{__html: act.feedbackIn}}/>
                <p>
                    Vous ne pourrez terminer ce défi que
                    le {format(addWorkDays(userAct?.started, act.duration), 'DD/MM/YYYY')}.
                </p>
            </ModalContent>,
        }))
    }

    showFinalModal = (act) => {
        this.context.popups.alert(({
            content: <ModalContent>
                <DefiIcon src={require('../style/imgs/sprout.png')}/>
                <ActLabel>{act.label}</ActLabel>
                <ActDescription dangerouslySetInnerHTML={{__html: act.feedbackIn}}/>
            </ModalContent>,
        }))
    }

    showEndModal = async (act) => {
        const result = await this.context.popups.confirm(({
            content: `Avez-vous réussi ce Défi ?`,
        }))
        if (!result) return
        await this.endAct(act.key)
        this.context.goTo('/challenges/' + act.key + '/end')
    }

    endAct = async (actKey) => {
        await this.context.addActions(endAct(actKey))
    }

    render({}, {}, {acts, userData, currentTheme, startedActs, goTo,}) {
        if (!acts || !userData) return null
        const userActs = userData.acts || {}
        const starteds = startedActs || []
        const dones = acts
            .filter(({key}) => userActs[key] && userActs[key]?.done)
        const awaitings = acts
            .filter(({key, theme}) => !userActs[key] && theme === currentTheme?.key)
        const guideEnded = (userData.guide || {})['choose-challenge']
        return <Container id="acts-screen">
            <BlockTitle back={() => goTo('/')}>Défis</BlockTitle>
            <div>
                {
                    !guideEnded && <CiviActPreview
                        key="choose-challenge"
                        act={guideAct}
                        status="awaiting"
                        seeMore={() => {}}
                    />
                }
                {
                    starteds.length > 0 && starteds
                        .map((act) => <CiviActPreview key={act.key} act={act} seeMore={this.seeMore}
                                                      status="started"
                                                      started={userActs[act.key].started}
                        />)
                }
                {
                    awaitings
                        .map((act) => <CiviActPreview key={act.key} act={act}
                                                      status="awaiting" seeMore={this.seeMore}/>)
                }
                {
                    dones.length > 0 && dones
                        .map((act) => <CiviActPreview key={act.key} act={act}
                                                      status="done" seeMore={this.seeMore}/>)
                }
            </div>
        </Container>
    }
}

const Container = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    flex         : '1 1 auto',
    padding      : '0.2em 1.5em',
})
const ModalContent = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    alignItems    : 'center',
    textAlign     : 'center',
})
const ActLabel = styled('h2')({
    fontSize     : '1em',
    textTransform: 'uppercase',
    alignSelf    : 'flex-start',
})
const ActDescription = styled('div')({
    fontSize    : '0.8em',
    alignSelf   : 'flex-start',
    marginBottom: '0.5em',
    '& > *'     : {
        margin    : 0,
        fontFamily: `'Raleway', sans-serif`,
    }
})

const DefiIcon = styled('img')({
    width : '30%',
    margin: '0.5em',
})
