import {styled} from '../styled'

export const LoginForm = styled('form')({
    display       : 'flex',
    flexFlow      : 'column nowrap',
    justifyContent: 'center',
    alignItems    : 'center',
    width         : '100%',
    maxWidth      : '600px',
    margin        : '0 auto',
    fontFamily    : 'Raleway, sans-serif',
    fontSize      : '16px',
    flex          : '0 0 auto',
})

export const LoginFormContainer = styled('div')({
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    flexDirection : 'column',
    width         : '100%',
    '& > *'       : {
        flex: '0 0 auto',
    }
})

export const InputTextWhite = styled('input')(({color = 'white'}) => ({
    border         : 'none',
    borderBottom   : `1px solid white`,
    backgroundColor: 'transparent',
    color          : color,
    fontSize       : '1.25em',
    margin         : '20px',
    padding        : '5px',
    width          : '90%',
    outline        : 'none',

    '::placeholder': {
        color: color,
    }
}))


export const InputFile = styled('input')({
    opacity: '0'
})

