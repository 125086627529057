import {ManagerTypes} from '../manager.types'
import {auth} from '../../firebase'

export const createCustomer = () => ({
    type   : ManagerTypes.create,
    payload: {
        target: `customers`,
        data  : {
            owner: auth.currentUser.uid,
        },
    },
})
