import {styled} from '../styled'


export const FullscreenCentered = styled('div')({
    position       : 'fixed',
    top            : 0,
    left           : 0,
    bottom         : 0,
    right          : 0,
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    backgroundColor: '#7BCBD8',
})