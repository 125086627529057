import {Component, h} from 'preact'
import {Page} from '../ui/Page'
import {managerTheme} from '../ui/managerTheme'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    LabelList,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'precharts'
import {Column, Row} from '../ui/RowCol'
import {Card} from '../ui/Card'
import {styled} from '../../style/styled'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export class Dashboard extends Component {
    state = {
        players  : [{name: 'jour 1', players: 0}],
        acts     : [{name: 'jour 1', players: 0}],
        questions: [{
            label    : 'Combien d\'éoliennes faut-il pour remplacer un réacteur nucléaire ?',
            responses: [{
                name : 'bonne réponse',
                value: 0
            }, {
                name : 'mauvaises réponses',
                value: 0
            }]
        }, {
            label    : 'Quel pays achète des déchets à d\'autres pays pour se chauffer ?',
            responses: [{
                name : 'bonne réponse',
                value: 0
            }, {
                name : 'mauvaises réponses',
                value: 0
            }]
        }, {
            label    : 'Quel est le temps d\'utilisation moyen d\'une voiture sur sa durée de vie ?',
            responses: [{
                name : 'bonne réponse',
                value: 0
            }, {
                name : 'mauvaises réponses',
                value: 0
            }]
        },],
        jobs     : [
            {
                label: 'Mise en place du tri sélectif',
                value: 0,
            },
            {
                label: 'Création d\'une salle de médiation',
                value: 0,
            },
            {
                label: 'Mise en place d\'un bus de ramassage',
                value: 0,
            },
        ]
    }


    render({}, {players, acts, questions, jobs}, {customer}) {
        return <Page title="Tableau de bord">
            <BoardRow>
                <Col>
                    <BoardCard>
                        <CenterBlock>
                            <h3>Nombre de joueurs</h3>
                            <LineChart width={300} height={200} data={players}>
                                <XAxis dataKey="label"/>
                                <YAxis dataKey="players"/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Line type="monotone" dataKey="players" stroke="#8884d8" activeDot={{r: 8}}/>
                            </LineChart>
                        </CenterBlock>
                    </BoardCard>
                    <BoardCard>
                        <CenterBlock>
                            <h3>Nombre de défis réalisés</h3>
                            <LineChart width={300} height={200} data={acts}>
                                <XAxis dataKey="label"/>
                                <YAxis dataKey="players"/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Line type="monotone" dataKey="players" stroke="#8884d8" activeDot={{r: 8}}/>
                            </LineChart>
                        </CenterBlock>
                    </BoardCard>
                </Col>
                <Col>
                    <BoardCard>
                        <h3>Pourcentage de bonnes et mauvaises réponses au dual-quiz</h3>
                        {
                            questions.map(question => <InnerCenterBlock>
                                <h4>{question.label}</h4>
                                <BoardCell>
                                    <PieChart width={150} height={150}>
                                        <Pie data={question.responses} cx={75} cy={75} innerRadius={0}
                                             outerRadius={50}
                                             labelInline={false}
                                             label={renderCustomizedLabel}
                                             dataKey="qty" nameKey="label" fill="#82ca9d">
                                            {
                                                question.responses.map((entry, index) => <Cell
                                                    fill={index % 2 === 0 ? managerTheme.colors.action : managerTheme.colors.disabled}/>)
                                            }
                                        </Pie>
                                    </PieChart>
                                </BoardCell>
                            </InnerCenterBlock>)
                        }
                    </BoardCard>
                </Col>
            </BoardRow>

        </Page>
    }
}

const BoardCard = Card.extends({
    display                     : 'flex',
    flexDirection               : 'column',
    backgroundColor             : managerTheme.colors.cardBackground,
    overflowY                   : 'auto',
    '& .recharts-pie-label-line': {
        display: 'none !important',
    }
})
const BoardRow = Row.extends({
    marginTop: 'auto',
    maxWidth : '100%',
    overflow : 'hidden',
})
const Col = Column.extends({
    flex: '1 1 50%',
})
const CenterBlock = styled('div')({
    flex          : '1 1 auto',
    alignSelf     : 'stretch',
    display       : 'flex',
    flexDirection : 'column',
    alignItems    : 'center',
    justifyContent: 'center',
})

const InnerCenterBlock = CenterBlock.extends({
    flex: '0 0 auto',
})

const BoardCell = styled('div')({
    flex: '0 0 auto',
})
