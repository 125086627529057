import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {BlockTitle} from '../style/Titles'
import {Button} from '../style/buttons'
import {database} from '../../firebase'


export class QuizzResult extends Component {
    state = {
        opponentAnswers: [],
        answers        : [],
    }

    componentDidMount() {
        this.syncQuiz(this.props.quiz, this.props.todayQuizzKey)
    }

    componentWillReceiveProps({quiz, todayQuizzKey}, {}) {
        this.syncQuiz(quiz, todayQuizzKey)
    }

    syncQuiz = async (quiz, todayQuizzKey) => {
        if (!quiz) return
        const campaign = this.context.campaign

        const opponentQuiz = quiz.opponent.key.split('/').concat('quiz', todayQuizzKey).reduce((acc, key) => (acc || {})[key], campaign.users || {})
        const opponent = (await database.ref('users').child(quiz.opponent.key).once('value')).val()
        const me = this.context.user

        this.setState({
            opponentAnswers: questionAsResult(opponentQuiz?.questions || quiz.questions),
            answers        : questionAsResult(quiz.questions),
            opponent,
            me,
            ended          : quiz.ended
        })
    }

    back = () => {
        this.context.goTo('/')
    }

    render({quiz, close}, {opponentAnswers, answers, me, opponent, ended}, _) {
        const meGoodCount = answers.filter(Boolean).length
        const opponentGoodCount = opponentAnswers.filter(Boolean).length
        return <Container>
            <Title>Dual Quizz</Title>
            <QuestionCount>{
                !ended ?
                    'En attente de l\'autre joueur'
                    : meGoodCount > opponentGoodCount
                    ? 'Victoire !'
                    : meGoodCount < opponentGoodCount
                        ? 'Perdu !'
                        : 'Égalité !'
            }</QuestionCount>
            <SimpleRow>
                <Text>{meGoodCount}</Text>
                <ThunderImg src={require('!!from-file!../style/svgs/eclair.svg')} alt=""/>
                <Text>{opponentGoodCount}</Text>
            </SimpleRow>

            <SimpleColumn>
                <PlayerRow>
                    <div>
                        <PlayerAvatar src={me?.avatar || require('!!from-file!../style/svgs/avatar.svg')}/>
                        <PlayerName>{me?.name || 'Moise'}</PlayerName>
                    </div>
                    {answers?.map(answer => <ResponseDot good={answer}/>)}
                </PlayerRow>
                <PlayerRow>
                    <div>
                        <PlayerAvatar src={opponent?.avatar || require('!!from-file!../style/svgs/avatar.svg')}/>
                        <PlayerName>{opponent?.name}</PlayerName>
                    </div>
                    {opponentAnswers?.map(answer => <ResponseDot good={answer}/>)}
                </PlayerRow>
            </SimpleColumn>
            <Close onClick={this.back}>Quitter</Close>
        </Container>
    }
}

function questionAsResult(questions) {
    return Object.keys(questions || {})
        .map(key => questions[key])
        .map(({answer, responses}) => !!(responses[answer]?.valid))
}

const Container = styled('article')({
    display      : 'flex',
    flexDirection: 'column',
    height       : '100%',
    alignSelf    : 'stretch',
    overflowY    : 'auto',

    boxSizing: 'border-box',
    padding  : '0.5em 2em',
})

const Title = styled(BlockTitle)({
    fontSize: '1.2em',
})

const QuestionCount = styled('p')({
    fontSize     : '1.3em',
    margin       : '0.5em',
    textAlign    : 'center',
    fontWeight   : 'bolder',
    textTransform: 'uppercase',
})

const Text = styled('p')({
    textAlign : 'center',
    fontSize  : '2em',
    fontWeight: 'bolder',
    padding   : '0.5em',
    margin    : 0,
})

const Close = Button.extends((_, theme) => ({
    fontSize       : '0.7em',
    padding        : '0.8em',
    flex           : '0 0 auto',
    alignSelf      : 'center',
    backgroundColor: theme.colors.create,
    textTransform  : 'normal',
}))
const PlayerAvatar = styled('img')((_, theme) => ({
    display       : 'block',
    height        : '2.1em',
    width         : '2.1em',
    objectFit     : 'cover',
    objectPosition: 'center center',
    borderRadius  : '50%',
    margin        : '0.3em 0.3em 0',
    overflow      : 'hidden',
    border        : '1px solid ' + theme.colors.create,
    marginTop     : '1.3em',
}))
const PlayerName = styled('p')({
    margin: '0.2em',
})
const SimpleRow = styled('div')({
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
})
const SimpleColumn = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    alignSelf    : 'stretch',
    marginBottom : '1em',
})
const PlayerRow = styled('div')((_, theme) => ({
    display       : 'flex',
    flexDirection : 'row',
    alignItems    : 'center',
    justifyContent: 'space-evenly',
    flex          : '1 1 auto',
    position      : 'relative',
    zIndex        : 1,
    '&:before'    : {
        content     : '\'\'',
        display     : 'block',
        position    : 'absolute',
        borderBottom: '1px solid ' + theme.colors.create,
        boxSizing   : 'border-box',


        left  : '0.5em',
        right : '1em',
        zIndex: '-1',
    }
}))
const ResponseDot = styled('div')((_, theme) => ({
    width       : '1em',
    height      : '1em',
    borderRadius: '50%',
    margin      : '0.7em',
    flex        : '0 0 auto',
}), ({good}, theme) => ({
    backgroundColor: good ? theme.colors.create : theme.colors.modalRefuse,
}))

const ThunderImg = styled('img')({
    width: '2em',
})
