import {media, styled} from '../../style/styled'
import {Component, h} from 'preact'
import Router from 'preact-router'
import {Link} from 'preact-router/match'
import {managerTheme} from './managerTheme'
import {fromBaseUrl} from '../../utils/appRoute'
import {Menu} from 'preact-feather'
import {TransparentButton} from './Buttons'
import {connect} from '../../utils/connect'
import {connectCampaign} from '../Campaigns/campaigns.connectors'
import {customStyles, ModalStyle} from '../../style/modalStyle'
import {Input} from './Input'
import {auth} from '../../firebase'
import humane from 'humane-js'
import {fromFirebaseError} from '../../../common/i18n'

export class Navbar extends Component {
    state = {
        opened                  : false,
        buttonActive            : false,
        modalIsOpenUsername     : false,
        modalIsOpenMail         : false,
        modalIsOpenDisconnection: false,
    }

    componentDidMount() {
        this.setState({
            name : auth.currentUser?.displayName,
            email: auth.currentUser?.email,
        })
    }

    toggleNav = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const nextOpenedState = !this.state.opened
        this.setState({
            opened      : nextOpenedState,
            buttonActive: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    buttonActive: false,
                })
            }, 150)
        })
    }
    closeNav = (event) => {
        event?.preventDefault()
        this.setState({
            opened      : false,
            buttonActive: false,
        })
    }
    onRouteChange = ({previous, url}) => {
        if (previous !== url) {
            this.closeNav()
        }
    }
    openModalUsername = () => {
        this.setState({
            modalIsOpenUsername: true,
            inputTyped         : this.state.name
        })
    }
    closeModalUsername = () => {
        this.setState({modalIsOpenUsername: false})
    }
    saveUsername = async () => {

        try {
            await auth.currentUser.updateProfile({
                displayName: this.state.inputTyped
            })
            this.closeModalUsername()
            humane.log('Nom d\'utilisateur modifié')
            this.setState({
                name: this.state.inputTyped
            })
        } catch (e) {
            const message = fromFirebaseError(e)
            humane.log(message)
        }
    }

    openModalMail = () => {
        this.setState({
            modalIsOpenMail: true,
            inputTyped     : this.state.email
        })
    }
    closeModalMail = () => {
        this.setState({modalIsOpenMail: false})
    }
    saveEmail = async () => {
        try {
            await auth.signInWithEmailAndPassword(this.state.email, this.state.inputPassTyped)
            await auth.currentUser.updateEmail(this.state.inputTyped)
            this.closeModalMail()
            humane.log('Email modifié')
            this.setState({email: this.state.inputTyped})
        } catch (e) {
            const message = fromFirebaseError(e)
            humane.log(message)
        }
    }

    openModalDisconnection = () => {
        this.setState({modalIsOpenDisconnection: true})
    }
    closeModalDisconnection = () => {
        this.setState({modalIsOpenDisconnection: false})
    }
    disconnection = async () => {
        try {
            await auth.signOut()
            this.closeModalDisconnection()
        } catch (e) {
            const message = fromFirebaseError(e)
            humane.log(message)
        }
    }
    updateField = (event) => {
        this.setState({
            inputTyped: event.target.value,
        })
    }
    updatePasswordField = (event) => {
        this.setState({
            inputPassTyped: event.target.value,
        })
    }

    render({}, {opened, buttonActive}, _) {
        return <NavbarWrapper active={opened} onClick={this.closeNav}>
            <MobileButton>
                <MobileLogo/>
                <TransparentButton onClick={this.toggleNav} active={buttonActive}>
                    <Menu/>
                </TransparentButton>
            </MobileButton>
            <NavbarContainer active={opened}>
                <Logo/>
                <GlobalNav/>
                <Router onChange={this.onRouteChange}>
                    <CampaignNav path={fromBaseUrl('/campaigns/:campaignId/:rest*')}/>
                </Router>
                <ProfileTab>
                    <ProfileActions>
                        <LinkHover onClick={this.openModalUsername}>Nom Utilisateur</LinkHover>
                        <LinkHover onClick={this.openModalMail}>Mail Utilisateur</LinkHover>
                        <LinkHover onClick={this.openModalDisconnection}>Déconnexion</LinkHover>
                    </ProfileActions>
                    <ProfilePreview>
                        <NavLinkProfile><CampaignNavIconProfile
                            src={require('!!from-file!./images/profil.svg')}/>{this.state.name || 'Profil'}
                        </NavLinkProfile>
                    </ProfilePreview>
                </ProfileTab>
            </NavbarContainer>

            <ModalStyle
                isOpen={this.state.modalIsOpenUsername}
                onRequestCancel={this.closeModalUsername}
                onRequestClose={this.saveUsername}
                title={'Nom d\'utilisateur'}>
                <Input type={'text'} name={'name'} value={this.state.name}
                       placeholder={'Changer votre Nom d\'utilisateur'} onInput={this.updateField}/>
            </ModalStyle>

            <ModalStyle
                isOpen={this.state.modalIsOpenMail}
                onRequestCancel={this.closeModalMail}
                onRequestClose={this.saveEmail}
                title={'Changement d\'email'}>
                <Input key="mail" type={'mail'} name={'mail'} value={this.state.inputTyped} onInput={this.updateField}
                       placeholder={'Nouvel email'}/>
                <Input type={'password'} placeholder={'Tapez votre mot de passe afin de valider le changement d\'email'}
                       onInput={this.updatePasswordField}/>
            </ModalStyle>

            <ModalStyle
                isOpen={this.state.modalIsOpenDisconnection}
                onRequestCancel={this.closeModalDisconnection}
                onRequestClose={this.disconnection}
                title={'Deconnexion'}>
                <p>Voulez vous vous deconnecter?</p>
            </ModalStyle>
        </NavbarWrapper>
    }
}

const GlobalNav = () => <CampaignNavContainerTop>
    <NavLink href={'/'}>Tableau de bord</NavLink>
    <NavLink href={'/campaigns/'}>Campagnes</NavLink>
</CampaignNavContainerTop>

export const CampaignNav = connect(connectCampaign)
(class CampaignNav extends Component {
    render({campaignId, campaign}, {}, _) {
        return <CampaignNavContainer>
            <NavBarTitle>{campaign?.settings?.name || 'Nouvelle campagne'}</NavBarTitle>
            <NavLink href={'/campaigns/' + campaignId + '/settings'}>réglages</NavLink>
            <NavLink href={`/campaigns/${campaignId}/scenario`}>scénario</NavLink>
            <NavLink href={`/campaigns/${campaignId}/themes`}>thèmes</NavLink>
            <NavLink href={`/campaigns/${campaignId}/acts`}><CampaignNavIcon
                src={require('!!from-file!./images/ecogestes.svg')}/>Défis</NavLink>
            <NavLink href={`/campaigns/${campaignId}/quizz`}><CampaignNavIcon
                src={require('!!from-file!./images/dualquiz.svg')}/>Dual-Quizz</NavLink>
            <NavLinkPreview href={`/campaigns/${campaignId}/publish`}><CampaignNavIconPreview
                src={require('!!from-file!./images/picto-previsual.svg')}/>Publier votre campagne</NavLinkPreview>

        </CampaignNavContainer>
    }
})


const CampaignNavContainerTop = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
})
const CampaignNavContainer = styled(CampaignNavContainerTop)({
    flex: 1,
})

const NavbarWrapper = styled('nav')(({active}) => ({
    display      : 'flex',
    flexDirection: 'column',
    alignSelf    : 'stretch',
    flex         : '0 0 auto',
    fontSize     : '1.3rem',
    overflowY    : 'auto',
    [media(1025)]: {
        '&:after': {
            display: 'none !important',
        },
    },
}))
const NavbarContainer = styled('div')(({active}) => ({
    display        : 'flex',
    flexDirection  : 'column',
    alignSelf      : 'stretch',
    minHeight      : '100vh',
    width          : '12rem',
    backgroundColor: managerTheme.colors.navBackground,
    flex           : '0 0 auto',
    padding        : '15px',
    boxSizing      : 'border-box',
    transition     : 'transform ease-in-out 350ms',
    transform      : active ? 'translateX(250px)' : 'translateX(0)',
    zIndex         : 150,
    position       : 'fixed',
    left           : '-250px',
    [media(1025)]  : {
        position : 'static',
        '&:after': {
            display: 'none !important',
        },
    },
}))

const NavBarTitle = styled('h2')({
    margin         : '15px -15px 0 -15px',
    padding        : '0.7rem 20px',
    fontWeight     : 'bold',
    fontSize       : '0.8rem',
    letterSpacing  : '0.2px',
    backgroundColor: managerTheme.colors.navHighlight,
    color          : managerTheme.colors.background,
})

const CampaignNavIcon = styled('img')({
    maxHeight: '38px',
    margin   : '-10px 20px -10px 0',
})
const CampaignNavIconPreview = styled(CampaignNavIcon)({
    margin   : '-2px 5px -10px 0',
    maxHeight: '35px',
})
const CampaignNavIconProfile = styled('img')({
    maxHeight: '50px',
    width    : '100%',
})

const LogoImg = styled('img')({
    width    : '30%',
    alignSelf: 'center',
    flex     : 0,
    margin   : '10px',
    objectFit: 'contain'
})
const MobileLogoImg = styled('img')({
    height   : '3rem',
    margin   : '-0.25rem 0',
    width    : 'auto',
    alignSelf: 'center',
    flex     : 0,
    objectFit: 'contain'
})

const ActiveLink = (props) => <Link {...props} activeClassName="active" href={fromBaseUrl(props.href)}/>

const NavLink = styled(ActiveLink)({
    color          : managerTheme.colors.navText,
    fontWeight     : 'bold',
    fontSize       : '0.8rem',
    letterSpacing  : '0.2px',
    textDecoration : 'none',
    cursor         : 'pointer',
    display        : 'inline-flex',
    margin         : '15px -15px 0 -15px',
    padding        : '0.7rem 20px',
    textTransform  : 'capitalize',
    backgroundColor: '#9fd0de14',
    '&.active'     : {
        backgroundColor: managerTheme.colors.inputBorder,
    },
})
const NavLinkPreview = styled(NavLink)({
    color  : managerTheme.colors.inputBorder,
    padding: '0.1rem 20px',
    margin : '25px -15px 0 -15px',
})

const ProfileTab = styled('div')({
    position                  : 'relative',
    margin                    : '4vh -15px 0 -15px',
    height                    : '80px',
    backgroundColor           : '#ffffff4f',
    overflow                  : 'hidden',
    marginTop                 : 'auto',
    '&:hover > :first-of-type': {
        transform: 'translateY(0)',
    }
})

const ProfileActions = styled('div')({
    position       : 'absolute',
    backgroundColor: '#3D3D3D',
    height         : '100%',
    width          : '100%',
    transform      : 'translateY(100%)',
    transition     : 'transform ease-in-out 350ms',
    zIndex         : 1,
    display        : 'flex',
    flexDirection  : 'column',
})
const ProfilePreview = styled('div')({
    backgroundColor: '#777171',
    height         : '100%',
    width          : '100%',
})
const LinkHover = styled('button')({
    margin         : 0,
    border         : 'none',
    backgroundColor: 'transparent',
    cursor         : 'pointer',
    color          : 'white',
    outline        : 'none',
    fontWeight     : 'bold',
    display        : 'block',
    flex           : '1 1 auto',
    '&:hover': {
        backgroundColor: managerTheme.colors.action
    }
})
const NavLinkProfile = styled(NavLink)({
    color    : 'black',
    textAlign: 'center',
    display  : 'inline-block',
    margin   : '0',
    padding  : '0',
    height   : '100%'
})
const MobileButton = styled('div')({
    display        : 'flex',
    justifyContent : 'space-between',
    width          : '100%',
    backgroundColor: managerTheme.colors.navBackground,
    padding        : '0.5rem',
    boxSizing      : 'border-box',
    [media(1025)]  : {
        display: 'none',
    },
})

export const Logo = () => <LogoImg src={require('../../style/img/logo-white.png')}/>
export const MobileLogo = () => <MobileLogoImg src={require('../../style/img/logo-white.png')}/>
