import {styled} from '../../style/styled'
import {Component, h} from 'preact'
import {firebaseApp} from '../../firebase'

export const Avatar = styled('img')(({size = '3em'}) => ({
    display       : 'block',
    height        : size,
    width         : size,
    objectFit     : 'cover',
    objectPosition: 'center center',
    borderRadius  : '50%',
    overflow      : 'hidden',
    margin: 'auto 0'
}))
