import {fromBaseUrl} from '../utils/appRoute'

export const loginUrls = {
    login                : fromBaseUrl('/auth/log-in'),
    forgotPassword       : fromBaseUrl('/auth/forgot-password'),
    forgotPasswordSuccess: fromBaseUrl('/auth/reset-password-success'),
    signUp               : fromBaseUrl('/auth/sign-up'),
    invite               : fromBaseUrl('/auth/invite/:token'),
    join                 : fromBaseUrl('/auth/join/:code'),
    signUpSuccess        : fromBaseUrl('/auth/sign-up-success'),
    resetPassword        : fromBaseUrl('/auth/reset-password'),
    resetPasswordSuccess : fromBaseUrl('/auth/reset-password-success'),
    redirectAuth         : fromBaseUrl('redirect-auth'),
}
