import {Component, h} from 'preact'
import {Link} from 'preact-router/match'
import {fromBaseUrl} from '../../../utils/appRoute'
import {media, styled} from '../../../style/styled'
import {defaultScenario} from '../campaigns.creators'
import {managerTheme} from '../../ui/managerTheme'
import {FlatButton} from '../../ui/Buttons'
import {Card} from '../../ui/Card'

const bullets = [
    {
        uri     : '/settings',
        label   : 'Réglages',
        messages: (campaign) => campaign?.settings?.name ? [] : ['Choisir un nom de campagne'],
    }, {
        uri     : '/scenario',
        label   : 'Scénario',
        messages: (campaign) =>
            Object.keys(defaultScenario).every(key => defaultScenario[key] !== (campaign?.scenario || {})[key])
                ? [] : ['Personnaliser le scénario'],
    }, {
        uri     : '/themes',
        label   : 'Thèmes',
        messages: (campaign) => {
            const result = []
            if (Object.keys(campaign?.themes || {}).length <= 0) {
                result.push('Avoir au moins un thème')
            }
            if (!campaign?.settings?.startAt) {
                result.push('Choisir les dates de votre campagne')
            }
            return result
        },
    }, {
        uri     : '/acts',
        label   : 'Défis',
        messages: (campaign) => {
            const actCountByTheme = Object.keys(campaign?.acts || {}).map(key => campaign.acts[key]).reduce((acc, {theme}) => ({
                ...acc,
                [theme]: (acc[theme] || 0) + 1,
            }), {})

            const themesWithoutSufficientActs = Object.keys(campaign?.themes || {})
                .filter((key) => !actCountByTheme[key] || actCountByTheme[key] < 5)
                .map(key => campaign?.themes[key]?.text)


            return themesWithoutSufficientActs.length > 0 ? ['Ajouter des défis dans les thèmes : ' + themesWithoutSufficientActs.join(', ')] : []
        },
    }, {
        uri     : '/quizz',
        label   : 'Dual-quiz',
        messages: (campaign) => {
            const quizCountByTheme = Object.keys(campaign?.quizzes || {}).map(key => campaign.quizzes[key]).reduce((acc, {theme}) => ({
                ...acc,
                [theme]: (acc[theme] || 0) + 1,
            }), {})


            const themesWithoutSufficientQuizzes = Object.keys(campaign?.themes || {})
                .filter((key) => !quizCountByTheme[key] || quizCountByTheme[key] < 5)
                .map(key => campaign?.themes[key]?.text)

            return themesWithoutSufficientQuizzes.length > 0 ? ['Ajouter des quiz dans les thèmes : ' + themesWithoutSufficientQuizzes.join(', ')] : []
        },
    }, {
        uri     : '/publish',
        label   : 'Publier',
        messages: (campaign) => [],
    },
]

export const CampaignProgress = class CampaignProgress extends Component {
    render({campaign, campaignId}, {}, _) {
        return <Bullets>
            <Text>Progression de votre personnalisation</Text>
            {
                bullets.map(bullet => <Bullet validated={bullet.messages(campaign)?.length <= 0}
                                              href={`campaigns/${campaignId}${bullet.uri}`}>{bullet.label}</Bullet>)
            }
        </Bullets>
    }
}

export const CampaignPublicationMessages = ({campaign, onPublish}) => {
    const results = bullets
        .map(bullet => ({
            ...bullet,
            messages: bullet.messages(campaign)
        }))
        .filter(({messages}) => messages && messages.length)
        .map(bullet => <div>
            <h2>{bullet.label}</h2>
            <ul>
                {bullet.messages?.map(message => <li>{message}</li>)}
            </ul>
        </div>)
    const readyToPublish = !!results.length
    return <PublishCard>
        <h1>{readyToPublish ? 'Votre campagne n\'est pas prête à être publier.' : 'Publier votre campagne'}</h1>
        {
            readyToPublish ? results : <p>Prêt à publier</p>
        }
        {
            !readyToPublish && <FlatButton onClick={onPublish}>Publier</FlatButton>
        }
    </PublishCard>
}

const progressColor = '#4b7a94'

const ActiveLink = (props) => <Link {...props} activeClassName="active" href={fromBaseUrl(props.href)}/>

const PublishCard = Card.withProps({
    color: managerTheme.colors.cardBackground,
})

const Bullets = styled('nav')({
    display: 'none',

    position: 'fixed',
    top     : 0,
    right   : 0,
    padding : '1.5rem',

    justifyContent: 'center',
    alignItems    : 'center',

    [media(1025)]: {
        display: 'flex',
    },
})

const Bullet = (props) => <BulletContainer href={props.href}>
    <BulletBullet validated={props.validated}>
        <BulletText>{props.children}</BulletText>
    </BulletBullet>

</BulletContainer>

const BulletContainer = styled(ActiveLink)({
    paddingBottom: '1rem',
})

const Text = styled('h4')({
    fontSize     : '0.8rem',
    fontWeight   : '700',
    color        : progressColor,
    margin       : 0,
    paddingBottom: '1rem',
})

const BulletBullet = styled('div')(({validated}) => ({
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'center',
    marginLeft    : '4vw',
    position      : 'relative',
    color         : (validated ? managerTheme.colors.action : progressColor),
    fontSize      : '0.8rem',

    'a:not(:first-of-type) &:before': {
        content     : '\'\'',
        display     : 'block',
        width       : '4vw',
        marginLeft  : '-4vw',
        flex        : '0 0 auto',
        height      : 0,
        borderBottom: '0.12rem solid ' + (validated ? managerTheme.colors.action : progressColor),
    },
    '&:after'                       : {
        content        : '\'\'',
        display        : 'block',
        width          : '1.5rem',
        height         : '1.5rem',
        borderRadius   : '50%',
        border         : '0.12rem solid ' + (validated ? managerTheme.colors.action : progressColor),
        backgroundColor: (validated ? managerTheme.colors.action : 'transparent'),
    },

    '.active &:after, &:hover:after': {
        backgroundColor: (validated ? managerTheme.colors.action : progressColor),
    },
}))

const BulletText = styled('span')({
    position  : 'absolute',
    display   : 'block',
    textAlign : 'center',
    bottom    : '-1.5rem',
    flex      : '0 0 auto',
    whiteSpace: 'nowrap',

    ':hover > &, .active &': {
        fontWeight: 900,
    }
})
