import {styled} from '../styled'

export const ButtonWhite = styled('button')({
    fontSize       : '18px',
    margin         : '20px',
    padding        : '10px 30px',
    borderRadius   : '25px',
    backgroundColor: '#FFF',
    border         : 'none',
    cursor         : 'pointer',
})

export const ButtonRed = styled('button')({
    fontSize       : '18px',
    padding        : '10px 30px',
    borderRadius   : '10px',
    backgroundColor: '#E44234',
    color          : 'white',
    width          : '95%',
    margin         : '10px 20px',
    Height         : '50px',
    border         : 'none',
    cursor         : 'pointer',
})