import {Component, h} from 'preact'
import {styled} from '../../style/styled'
import {BlockTitle} from '../style/Titles'
import {Button, IconButton} from '../style/buttons'
import {ArrowRight} from 'preact-feather'

export class QuizzResponse extends Component {
    render({question, item, count, choose, response, next}, {}, {theme}) {
        const goodResponse = (question?.responses || []).filter((r) => r?.valid)[0]
        const rightAnswer = response === goodResponse
        return <Container>
            <Title>Dual Quizz</Title>
            <QuestionCount>Question {item}/{count}</QuestionCount>
            <ResponseBar rightAnswer={rightAnswer}>
                <ResponseIcon
                    src={require('!!from-file!../style/svgs/pouce.svg')}/>
                <GoodResponse>{goodResponse?.response}</GoodResponse>
            </ResponseBar>
            <Text dangerouslySetInnerHTML={{__html: question?.explanation}}/>
            <NextButtonIconed
                onClick={() => next(question)}
                icon={ArrowRight}
                iconColor={theme.colors.second}
            >{item === count ? 'Voir le résultat' : 'Question suivante'}</NextButtonIconed>
        </Container>
    }
}

const Container = styled('article')({
    display      : 'flex',
    flexDirection: 'column',
    height       : '100%',
    alignSelf    : 'stretch',
    overflowY    : 'auto',
    boxSizing    : 'border-box',
    padding      : '0.5em 2em',
})

const Title = styled(BlockTitle)({
    fontSize: '1.2em',
})

const QuestionCount = styled('p')((_, theme) => ({
    fontSize     : '1.2em',
    fontWeight   : 'bold',
    textTransform: 'uppercase',
    color        : theme.colors.text,
    margin       : '0.5em 0'
}))

const NextButton = Button.extends({
    fontSize: '0.7em',
    padding : '0.8em',
    flex    : '0 0 auto',
})

const ResponseBar = styled('div')(({rightAnswer}, theme) => ({
    display        : 'flex',
    position       : 'relative',
    backgroundColor: rightAnswer ? theme.colors.valid : theme.colors.cancel,
    border         : '0.1em solid ' + theme.colors.background,
    borderRadius   : '0.5em',
    justifyContent : 'center',
    alignItems     : 'center',
    padding        : '0.3em',
    margin         : '0.3em 0',
    flex           : '0 0 auto',
    '& img'        : {
        transform: rightAnswer ? 'scaleY(1)' : 'scaleY(-1)',
    }
}))

const ResponseIcon = styled('img')({
    width : '3em',
    height: 'auto',
    margin: '-1em 0'
})

const GoodResponse = styled('p')((_, theme) => ({
    color        : theme.colors.inverted.text,
    textTransform: 'uppercase',
    margin       : '0 auto',
    paddingRight : '3rem',
    fontSize     : '1em',
    fontWeight   : 'bold',
}))

const Text = styled('p')({
    fontSize  : '1em',
    fontWeight: 'bold',
    textAlign : 'center',
})

const NextButtonIconed = styled(IconButton)({
    fontSize: '1em !important',
})
