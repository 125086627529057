import {Component, h} from 'preact'
import {Page} from '../ui/Page'
import {StyledLabel} from '../ui/Input'
import {styled} from '../../style/styled'
import React from 'preact-compat'
import dragula from 'react-dragula'
import 'react-dragula/dist/dragula.css'
import nanoid from 'nanoid'
import {themesWorkDaysCount} from '../../../common/Themes/themesCalculator'
import {managerTheme} from '../ui/managerTheme'
import {Card, CardFoot, CardHead, CardRow} from '../ui/Card'
import {AppPreview} from '../Preview/AppPreview'
import {ConfigurationTypesArray} from '../../../common/ConfigurationTypes'

const colors = ['#aedccb', '#9ca2c5', '#9fb7c4', '#d8d8d8']

export class DragFromLibrary extends Component {
    state = {
        items      : [],
        finalRender: '',
    }

    async componentWillMount() {
        this.uponUpdate(this.props.items)
    }

    componentWillUnmount() {
        if (this.drake) {
            this.drake.destroy()
        }
    }

    componentWillReceiveProps(nextProps, _) {
        this.uponUpdate(nextProps.items)
    }

    uponUpdate(items) {
        if (!items) return
        this.setState({
            items : items
                .sort((a, b) => (a.order || 0) - (b.order || 0))
                .map((item, i) => ({
                    ...item,
                    order: i,
                })),
            loaded: true,
        }, () => {
            if (!this.drake) {
                this.initDragula()
            }
        })
    }

    deleteToNewData = (el) => {
        const toFind = el.dataset.keydrag
        const newData = this.props.items.map(item => item.key !== toFind ? item : {
            ...item,
            theme: '',
        })

        this.save(newData)
    }
    deleteFromObject = ({key}) => {
        const newData = this.props.items.map(item => item.key !== key ? item : {
            ...item,
            theme: '',
        })

        this.save(newData)
    }

    addToNewData = (el, target, source, sibling) => {
        if (el.dataset.library) {
            const newPosition = Number(sibling?.dataset.orderdragg != null ? sibling.dataset.orderdragg : this.props.items?.length)
            const toFind = el.dataset.keydrag
            const theme = target.dataset.keytheme
            const item = this.props.library.find(({key}) => key === toFind)
            const newData = [].concat(this.props.items)
            newData.splice(newPosition, 0, {
                ...item,
                theme: theme,
            })
            this.save(newData.map((item, i) => ({
                ...item,
                order: i,
            })))
            return
        }
        const newPosition = Number(sibling?.dataset.orderdragg != null ? sibling.dataset.orderdragg : this.props.items?.length)
        const oldPosition = el?.dataset.orderdragg
        const theme = target.dataset.keytheme

        const newData = [].concat(this.state.items)
        const oldItem = this.state.items[oldPosition]
        const patcherOldItem = {...(oldItem || {}), theme, key: oldItem?.key || nanoid()}
        if (newPosition > oldPosition) {
            newData.splice(newPosition, 0, patcherOldItem)
            newData.splice(oldPosition, 1)
        } else {
            newData.splice(oldPosition, 1)
            newData.splice(newPosition, 0, patcherOldItem)
        }

        this.save(newData.map((item, i) => ({
            ...item,
            order: i,
        })))
    }

    initDragula = () => {
        const container = React.findDOMNode(this)
        if (container == null) return
        const edge = 30
        let lastPosition = null

        let scrolling = false
        const step = 30
        const throttleScroll = ($el, direction) => {
            if (!scrolling) {
                scrolling = true
                requestAnimationFrame((time) => {
                    scroll($el, direction, time)
                })
            }
        }

        const scroll = ($el, direction, actual, nextTime = null, lastScrollPosition = lastPosition) => {
            if (nextTime) {
                const ratio = Math.ceil(step * (nextTime - actual) / 100)
                if (direction === 'bottom') {
                    $el.scrollTop += ratio
                } else if (direction === 'top') {
                    $el.scrollTop -= ratio
                } else if (direction === 'left') {
                    $el.scrollLeft -= ratio
                } else {
                    $el.scrollLeft += ratio
                }
            }
            requestAnimationFrame((time) => {
                if (lastScrollPosition !== lastPosition || !lastPosition) {
                    scrolling = false
                    return
                }
                scroll($el, direction, nextTime || actual, time, lastPosition)
            })
        }
        const options = {
            removeOnSpill: true,
            copy         : function (el, source) {
                return source === container.querySelector('.containerOrigin')
            },
            accepts      : function (el, target) {
                return target !== container.querySelector('.containerOrigin')
            },
            isContainer  : function (el) {
                const isAContainer = ['containerOrigin', 'containerUser', 'containerActive'].some((key) => el.classList.contains(key))
                if (lastPosition) {
                    requestAnimationFrame(() => {
                        const pos = el.getBoundingClientRect()
                        const {x, y} = relativePosition(lastPosition, pos)
                        const horizontallyScrollable = el.scrollWidth > el.clientWidth
                        const verticallyScrollable = el.scrollHeight > el.clientHeight
                        const hasHorizontalSpaceBefore = horizontallyScrollable && el.scrollLeft > 0
                        const hasHorizontalSpaceAfter = horizontallyScrollable && el.scrollLeft < (el.scrollWidth + el.clientWidth)
                        const hasVerticalSpaceBefore = verticallyScrollable && el.scrollTop > 0
                        const hasVerticalSpaceAfter = verticallyScrollable && el.scrollTop < (el.scrollHeight + el.clientHeight)
                        if ((x >= -edge && x <= edge) && hasHorizontalSpaceBefore) {
                            throttleScroll(el, 'left')
                        } else if ((x >= pos.width - edge && x <= pos.width + edge) && hasHorizontalSpaceAfter) {
                            throttleScroll(el, 'right')
                        } else if ((y >= -edge && y <= edge) && hasVerticalSpaceBefore) {
                            throttleScroll(el, 'top')
                        } else if ((y >= pos.height - edge && y <= pos.height + edge) && hasVerticalSpaceAfter) {
                            throttleScroll(el, 'bottom')
                        }
                    })
                }
                return isAContainer
            }
        }

        const updateMousePosition = function (event) {
            lastPosition = {
                x: event.pageX,
                y: event.pageY,
            }
        }
        let shouldDelete = null
        this.drake = dragula(options)
            .on('remove', (el) => {
                this.deleteToNewData(el)
            })
            .on('cloned', (copy, original, type) => {
                if (type === 'copy') {
                    shouldDelete = copy
                }
            })
            .on('drag', (el) => {
                document.documentElement.addEventListener('mousemove', updateMousePosition)
            })
            .on('dragend', () => {
                lastPosition = null
                document.documentElement.removeEventListener('mousemove', updateMousePosition)
            })
            .on('drop', (el, target, source, sibling) => {
                this.addToNewData(el, target, source, sibling)
                if (shouldDelete) {
                    shouldDelete.remove()
                }
            })
    }

    save = async (items) => {
        this.props.save(items)
    }

    selectCategory = (event) => {
        const $select = event.target
        const category = $select.options[$select.selectedIndex]?.value

        this.setState({
            category,
            filteredLibrary: (this.props.library || []).filter(item => (item.category || []).includes(category))
        })
    }

    render({library, themes, component: Item, pageTitle, tip, create, edit, children, preview, onUrlChange, onAddActions, campaign}, {loaded, items, category, filteredLibrary}, _) {
        const themesKeys = themes
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .map(({key}) => key)
        const settings = campaign?.settings || {}
        const counts = settings?.startAt ? themesWorkDaysCount(settings?.startAt, settings?.endAt, themes) : []
        return loaded && <Page key={'page'} title={pageTitle} tip={tip}>
            <SimpleRow>
                <AvailableActCard>
                    <StyledLabel>Choisissez vos {pageTitle}&nbsp;
                        <select onChange={this.selectCategory}>
                            <option value="">Filtrer par type</option>
                            {ConfigurationTypesArray.map(item => <option value={item.id}
                                                                         key={item.id}>{item.name}</option>)}
                        </select>
                    </StyledLabel>
                    <LibraryContainer className='containerOrigin'>
                        {
                            (category ? filteredLibrary : library)
                                ?.filter(item => !items.find(({key}) => item.key === key))
                                .map(v => <Item
                                    key={'ref-' + v.key}
                                    data={v}
                                    library={true}
                                    open={create}
                                />)
                        }
                    </LibraryContainer>
                </AvailableActCard>
                <AvailableActCard>
                    <StyledLabel>Créez de nouveaux {pageTitle}</StyledLabel>
                    <LibraryContainer className='containerUser'>
                        <Item
                            data={{}}
                            canCopy={true}
                            open={create}
                        />
                        {
                            items
                                .filter(({theme}) => !themesKeys.includes(theme))
                                .map(v => <Item
                                    key={v.key}
                                    data={v}
                                    canEdit={true}
                                    open={edit}
                                />)
                        }
                    </LibraryContainer>
                </AvailableActCard>
            </SimpleRow>
            <RowTheme>
                {
                    themes
                        .map((theme, themeI) => {
                            const filteredItems = items
                                .filter(item => theme.key === item.theme)
                            return <ThemeColumnContainer key={theme.key} theme={theme} preview={preview}
                                                         campaign={campaign}
                                                         onAddActions={onAddActions}
                                                         onUrlChange={onUrlChange}>
                                <ThemeTitle text={theme.text} count={counts[themeI]} pageTitle={pageTitle}
                                            itemsCount={filteredItems.length} color={colors[themeI % colors.length]}/>
                                <WrapperItemIntheme key={'content-' + theme.key} className='containerActive'
                                                    data-keytheme={theme.key}>
                                    {filteredItems.map(item => <Item
                                        key={item.key}
                                        data={item}
                                        canEdit={true}
                                        canDelete={true}
                                        open={edit}
                                        delete={this.deleteFromObject}
                                    />)}
                                </WrapperItemIntheme>
                            </ThemeColumnContainer>
                        })
                }
            </RowTheme>
            {children}
        </Page>
    }
}

const LibraryContainer = styled('div')({
    display      : 'flex',
    flexDirection: 'row',
    overflowX    : 'scroll',
    margin       : '1vh 10px',
    flex         : '1 1 auto',
})

const WrapperItemIntheme = CardRow.extends({
    display            : 'flex',
    flexFlow           : 'column nowrap',
    flex               : '1 1 auto',
    margin             : '0px',
    opacity            : '0.8',
    overflowY          : 'auto',
    alignItems         : 'center',
    width              : '22rem',
    '& > :last-of-type': {
        marginBottom: '3rem',
    }
})

const AvailableActCard = Card.extends({
    flex           : '1 1 50%',
    overflow       : 'hidden',
    margin         : '1vh 1rem',
    padding        : '1vh 1rem',
    backgroundColor: managerTheme.colors.alphaAction
})

const WrapperTitle = CardHead.extends({
    borderTopLeftRadius : '4px',
    borderTopRightRadius: '4px',
    display             : 'flex',
    flexDirection       : 'column',
    flex                : '0 0 auto',
})

class ThemeColumnContainer extends Component {
    getChildContext() {
        return {
            currentTheme: this.props.theme
        }
    }

    render({children, onUrlChange, onAddActions, campaign, ...props}) {
        const Preview = props.preview
        return (
            <ThemeColumnWrapper {...props}>
                {children}
                <PreviewButtonContainer>
                    <AppPreview campaign={campaign} onUrlChange={onUrlChange} onAddActions={onAddActions} flatButton={true}>
                        <Preview/>
                    </AppPreview>
                </PreviewButtonContainer>
            </ThemeColumnWrapper>
        )
    }
}

const PreviewButtonContainer = CardFoot.extends({
    display   : 'flex',
    alignItems: 'stretch',
    flex      : '0 0 auto',
    '& > *'   : {
        flex: '1 1 auto',
    }
})

const ThemeColumnWrapper = Card.extends({
    position: 'relative',
    margin  : '1vh 1rem',
    overflow: 'hidden',
    flex    : '0 0 auto',

    display      : 'flex',
    flexDirection: 'column',

    maxWidth: '21.85rem',
    boxSizing: 'border-box',
})

const Title = styled('h1')(({color}) => ({
    textAlign      : 'center',
    margin         : '0px',
    minHeight      : '3.6rem',
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    backgroundColor: color || 'transparent',
    textTransform  : 'uppercase',
    textShadow     : managerTheme.shadow(3),
    color          : managerTheme.colors.lightText,
}))


const ThemeTitle = ({text, count, pageTitle, itemsCount, color}) => {
    return (
        <WrapperTitle>
            <Title color={color}><span>{text}</span></Title>
            <SimpleRow>
                <ItemsCount>{itemsCount || 0} {pageTitle}</ItemsCount>
                <DaysCount>{count || 0} jours</DaysCount>
            </SimpleRow>
        </WrapperTitle>
    )
}

const SimpleRow = styled('div')({
    display : 'flex',
    flex    : '0 0 auto',
    maxWidth: '100%',
})
const ItemsCount = styled('div')({
    flex           : '1 1 auto',
    backgroundColor: managerTheme.colors.navBackground,
    color          : managerTheme.colors.navText,
    textAlign      : 'center',
    padding        : '0.4rem',
    margin         : '0.2rem 0.2rem 0.2rem 0',
    textTransform  : 'uppercase',
})
const DaysCount = styled('div')({
    flex           : '0 0 auto',
    backgroundColor: managerTheme.colors.lightBackground,
    textAlign      : 'center',
    padding        : '0.4rem',
    margin         : '0.2rem 0',
    textTransform  : 'uppercase',
})

const RowTheme = Card.extends({
    position       : 'relative',
    display        : 'flex',
    alignSelf      : 'stretch',
    backgroundColor: managerTheme.colors.cardBackground,
    margin         : '1vh 1rem',
    padding        : '1vh 1rem',
    overflowY      : 'hidden',
})

function relativePosition(a, b) {
    if (!a || !b) return {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY,
    }
    return {
        x: a.x - b.x,
        y: a.y - b.y,
    }
}
