import {Component, h} from 'preact'
import {Check} from 'preact-feather'
import {IconButton} from '../style/buttons'

export class TeamPreview extends Component {
    select = (e) => {
        this.props.onSelect(this.props.team)
    }

    render({team, onSelect}, {}, {theme}) {
        if (!team) return null
        return <IconButton
            icon={Check}
            color={theme.colors.lightValid}
            iconColor={theme.colors.valid}
            onClick={this.select}
        >
            {team.name}
        </IconButton>
    }
}
