export const firebase = {
    'auth/email-already-in-use'                    : 'Email déjà utilisé',
    'auth/account-exists-with-different-credential': 'Un compte existe déjà avec cet email. Connecté vous sur ce compte pour valider le lien entre ces comptes.',
    'auth/invalid-email'                           : 'Email invalide',
    'auth/weak-password'                           : 'Mot de passe trop faible',
    'auth/timeout'                                 : 'Délai dépassé',
    'auth/user-not-found'                          : 'Utilisateur inconnu',
    'auth/user-not-verified'                       : 'Vérifiez vos email pour valider votre compte',
    'auth/wrong-password'                          : 'Mauvaise combinaison Mot de passe/email',
    'auth/not-same-password'                       : 'Mauvaise combinaison Mot de passe/email',
    'auth/code-used'                               : 'Lien périmé',
    'auth/wrong-code'                              : 'Votre code de campagne semble erroné.',
    'auth/user-disabled'                           : 'Compte désactivé',
    'auth/argument-error'                          : 'Saisie incorrect',
    'auth/web-storage-unsupported'                 : 'Votre navigateur ne supporte pas l\'utilisation de cookie tiers.',
    '*'                                            : 'Une errreur inconnue est survenue.',
}
