import {styled} from '../../style/styled'
import {h} from 'preact'

const paddingSize = 0.5
export const Button = styled('button')((_, theme) => ({
    border         : 'none',
    backgroundColor: 'white !important',

    color         : theme.colors.text,
    borderRadius  : '0.4em',
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    flex          : '1 0 auto',
    letterSpacing : '1px',
    fontSize      : '1.2em',
    fontWeight    : 'bold',
    textTransform : 'uppercase',
    lineHeight    : 1,
    margin        : '0.3em',
    padding       : `${paddingSize}em`,
    boxShadow     : theme.shadow(),
}))


export const IconButton = ({icon: Icon, iconColor, children, ...props}) => <IconButtonWrapper {...props}>
    <ButtonContent>{children}</ButtonContent>
    <IconWrapper iconColor={iconColor}>
        <Icon size="1em" style={iconStyle}/>
    </IconWrapper>
</IconButtonWrapper>

export const Label = styled('label')({
    margin       : '0.3em',
    fontWeight   : 'bold',
    textTransform: 'uppercase',
})
export const IconInput = ({icon: Icon, children, inputProps = {}, onSubmit, ...props}) => <IconInputWrapper
    onSubmit={onSubmit}>
    <InputContent {...inputProps}/>
    <IconInputIconWrapper {...props}>
        <Icon size="1em" style={iconStyle}/>
    </IconInputIconWrapper>
</IconInputWrapper>

const IconButtonWrapper = Button.extends(({color}) => ({
    flex           : '0 0 auto',
    backgroundColor: color,
    overflow       : 'hidden',
}))

const IconInputWrapper = IconButtonWrapper.with('form').withProps({
    color: 'white',
})

const ButtonContent = styled('span')({
    flex     : '1 1 auto',
    textAlign: 'left',
})
const InputContent = ButtonContent.with('input').extends({
    border  : 'none',
    outline : 'none',
    padding : 0,
    font    : 'inherit',
    height  : '1.25em',
    fontSize: '0.8em',
})

const iconStyle = {
    display: 'block',
    color  : 'white',
}

const IconWrapper = styled('div')(({iconColor}) => ({
    display        : 'block',
    margin         : `-${paddingSize}em -${paddingSize}em -${paddingSize}em 0`,
    flex           : '0 0 auto',
    padding        : `${paddingSize}em`,
    backgroundColor: iconColor,
    '& > svg'      : {
        height: '1em',
        width : '1em',
    }
}))

const IconInputIconWrapper = IconWrapper.with('button').extends({
    border   : 'none',
    outline  : 'none',
    font     : 'inherit',
    boxSizing: 'content-box',
})
