import {Component, h} from 'preact'
import {styled} from '../../style/styled'


export class Slider extends Component {
    render({children, item, ...props}, {}, _) {
        return <Container {...props}>
            <Wrapper item={item} count={children.length}>
                {children}
            </Wrapper>
        </Container>
    }
}

const Container = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    width   : '100%',
    height  : '100%',
    flex    : '1 1 auto',
})
const Wrapper = styled('div')({
    display      : 'flex',
    flexDirection: 'row',
    position     : 'absolute',
    transition   : 'transform ease-in-out, 350ms',
    height       : '100%',
    top          : 0,
    left         : 0,
}, ({count, item}) => {
    const width = 100 / count
    return ({
        width    : (100 * count) + '%',
        transform: `translateX(-${item * width}%)`,
        '& > *'  : {
            flex: '1 1 ' + (width) + '%'
        }
    })
})
