import {h} from 'preact'
import {styled} from '../styled'
import LogoLoader from '../img/loader.svg'
import {FullscreenCentered} from './positioning'

const baseIconStyle = {
    width : '20vw',
    height: 'auto',
}
const SizedIcon = styled(LogoLoader)(baseIconStyle)

const PositionedIcon = styled(LogoLoader)({
    ...baseIconStyle,
    display: 'block',
    margin : 'calc(50vh - 10vw) auto 0',
})
export const Loader = SizedIcon

export const FullscreenLoader = () => <FullscreenCentered>
    <SizedIcon/>
</FullscreenCentered>