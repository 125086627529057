export const PlayerTypes = {
    'chooseCampaign'    : 'Player_chooseCampaign',
    'chooseTeam'        : 'Player_chooseTeam',
    'seenScenario'      : 'Player_seenScenario',
    'startAct'          : 'Player_startAct',
    'endAct'            : 'Player_endAct',
    'findQuizOpponent'  : 'Player_findQuizOpponent',
    'answerQuizQuestion': 'Player_answerQuizQuestion',
    'createTeam'        : 'Player_createTeam',
    'joinTeam'          : 'Player_joinTeam',
    'inviteTeam'        : 'Player_inviteTeam',
    'invitePlayer'      : 'Player_invitePlayer',
    'wonGame'           : 'Player_wonGame',
    'lostGame'          : 'Player_lostGame',
    'guideStep'         : 'Player_guideStep',
    'buyJob'            : 'Player_buyJob',
    'seenJob'           : 'Player_seenJob',
    'resetProfile'      : 'Player_resetProfile',
    'addADay'           : 'Player_addADAy',
}
