import {Component, h} from 'preact'
import {styled} from '../../../style/styled'
import {Input, RichTextArea} from '../../ui/Input'
import {Watcher} from '../../../utils/DataSync'
import {database} from '../../../firebase'
import {renderEquivalence} from '../../../../equivalences'


export class CampaignActScreen extends Component {
    state = {}

    async componentDidMount() {
        this.watcher = Watcher({...(this.props.act || {})})

        this.watcher.subscribe(this.save)

        const equivalences = (await database.ref('equivalences').once('value')).val()
        this.setState({
            equivalences,
        }, () => {
            this.updateAct(this.props)
        })
    }

    componentWillUnmount() {
        this.watcher?.destroy()
    }

    componentWillReceiveProps(props) {
        this.updateAct(props)
    }

    async updateAct(props) {
        this.watcher?.silentUpdate(props.act)

        if (!this.state.equivalences) return

        const duration = this.watcher?.get('duration') || 0
        this.setState({
            selectedActIn : renderEquivalence(this.state.equivalences, props.act.feedbackIn, 1, duration),
            selectedActOut: renderEquivalence(this.state.equivalences, props.act.feedbackOut, props.campaign?.settings?.licencesCount || 2000, duration),
        }, () => {
        })
    }

    get feedbackIn() {
        const feedbackIn = this.watcher?.get('feedbackIn')
        return this.props.act.feedbackIn === feedbackIn ? this.state.selectedActIn : feedbackIn
    }

    get feedbackOut() {
        const feedbackOut = this.watcher?.get('feedbackOut')
        return this.props.act.feedbackOut === feedbackOut ? this.state.selectedActOut : feedbackOut
    }

    save = async () => {
        this.props.onUpdate && this.props.onUpdate(this.watcher.get(''))
    }

    render({campaign, act}, {}, _) {
        const watcher = this.watcher
        if (!watcher) return null
        return <EditForm>
            <Input
                value={watcher.get('label')}
                type="text"
                onChange={watcher.put('label')}
                placeholder="Défi"
            />
            <Input
                value={watcher.get('flouz')}
                type="number"
                onChange={watcher.put('flouz')}
                placeholder="Récompense"
            />
            <Input
                value={watcher.get('duration')}
                type="number"
                onChange={watcher.put('duration')}
                placeholder="Durée"
            />
            <RichTextArea
                margin={'0'}
                onInput={watcher.put('feedbackIn')}
                placeholder="Message d'ajout"
                value={this.feedbackIn}
            />
            <RichTextArea
                margin={'0'}
                onInput={watcher.put('feedbackOut')}
                placeholder="Message de validation"
                value={this.feedbackOut}
            />
        </EditForm>
    }
}

const EditForm = styled('div')({
    display       : 'flex',
    flexDirection : 'column',
    alignItems    : 'stretch',
    minWidth      : '60%',
    flex          : 1,
    justifyContent: 'space-evenly',
})
