import {Component, h} from 'preact'
import dragula from 'react-dragula'
import React from 'preact-compat'
import {styled} from '../../../style/styled'
import {deleteTheme, updateTheme} from '../campaigns.creators'
import humane from 'humane-js'
import 'react-dragula/dist/dragula.css'
import * as nanoid from 'nanoid'
import {Edit2, Move, Trash2} from 'preact-feather'
import {FlatButton, RoundButton} from '../../ui/Buttons'
import {Card} from '../../ui/Card'
import {managerTheme} from '../../ui/managerTheme'

export class DragAndDropTheme extends Component {
    state = {
        finalArray: [],
        dragging  : true,
    }

    componentWillMount() {
        if (this.props.campaign?.themes) {
            this.setState({
                finalArray: Object.keys(this.props.campaign?.themes || {})
                    .map(key => ({
                        key,
                        ...this.props.campaign.themes[key]
                    }))
                    .sort((a, b) => a.order - b.order)
            })
        }
    }

    componentWillReceiveProps(nextProps, _) {
        if (nextProps.campaign?.themes !== this.props.campaign?.themes) {
            this.setState({
                finalArray: Object.keys(nextProps.campaign.themes || {})
                    .map(key => ({
                        key,
                        ...nextProps.campaign.themes[key]
                    }))
                    .sort((a, b) => a.order - b.order)
            })
        }
    }

    shouldComponentUpdate() {
        return !this.dragging
    }

    componentDidMount() {
        const root = React.findDOMNode(this)
        const containers = root.querySelectorAll('.container')
        const mirrorContainer = root.querySelector('.home-container')
        this.drake = dragula([...containers], {
            mirrorContainer,
        })
            .on('drop', (el, target, source, sibling) => this.dndDrop(el, target, source, sibling))
            .on('drag', (el, target) => {
                const $el = el.childNodes[0]
                requestAnimationFrame(() => {
                    const $target = mirrorContainer.childNodes[0]?.childNodes[0]
                    if ($target) {
                        $target.style.width = $el.offsetWidth + 'px'
                    }
                })
                this.dragging = true
            })
    }

    componentWillUnmount() {
        if (this.drake) {
            this.drake.destroy()
        }
    }

    dndDrop(element, target, source, sibling) {
        this.dragging = false
        let whoMove = element.dataset.id
        let data = this.state.finalArray[whoMove]
        let afterIndex = Number(sibling?.dataset?.id ? sibling.dataset.id : this.state.finalArray.length)
        let partOn = [...this.state.finalArray]
        partOn.splice(whoMove, 1)
        partOn.splice(afterIndex, 0, data)
        partOn.map((value, key) => {
            value.order = key
        })
        this.setState({
            finalArray: partOn
        })
        this.props.changeEditingKey()
        this.save()
    }

    save = async () => {
        const themes = this.state.finalArray.reduce((acc, {key, ...theme}) => ({...acc, [key || nanoid()]: theme}), {})
        await updateTheme(this.props.campaignRef, themes)
        humane.log('Enregistré')
    }

    new = () => {
        this.props.changeEditingKey('')
    }

    render({colors}, {}, _) {
        return <Columns>
            <Container>
                <Head>
                    <Cell>Thèmes</Cell>
                    <Cell>Modifier</Cell>
                    <Cell>Supprimer</Cell>
                </Head>
                <DragAndDropContainer
                    className='container'
                    key="drag-themes"
                >
                    {
                        this.state.finalArray.length
                            ? this.state.finalArray.map((item, i) => {
                                return (
                                    <Drag key={item.key} data-id={i}>
                                        <Cell>
                                            <ItemTitle color={colors[i % colors.length]}>
                                                <Move/>
                                                <InnerText>{item.text}</InnerText>
                                            </ItemTitle>
                                        </Cell>
                                        <Cell>
                                            <RoundDragButton
                                                size="2rem"
                                                aria-label="Modifier"
                                                onClick={() => this.props.changeEditingKey(item.key)}
                                            >
                                                <Edit2 size="1.5rem"/>
                                            </RoundDragButton>
                                        </Cell>
                                        <Cell>
                                            <RoundDragButton
                                                size="2rem"
                                                aria-label="Supprimer"
                                                cancel={true}
                                                onClick={() => deleteTheme(this.props.campaignRef, item.key)}
                                            >
                                                <Trash2 size="1.5rem"/>
                                            </RoundDragButton>
                                        </Cell>
                                    </Drag>
                                )
                            })
                            : <p>Pas de thème configuré</p>
                    }
                </DragAndDropContainer>
                <DragAndDropHome className="home-container"/>
            </Container>
            <div>
                <SimpleColumn>
                    <FlatButton onClick={this.new} key="save">Ajouter un thème</FlatButton>
                </SimpleColumn>
            </div>
        </Columns>
    }

}

const Columns = styled('div')({
    display: 'flex',
})

const Actions = styled('div')({
    flex          : '0 0 auto',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'flex-end',
    marginBottom  : '1rem'
})


const SimpleColumn = styled('div')({
    display      : 'flex',
    flexDirection: 'column',
    marginLeft   : '1rem',
    minWidth     : '190px',
    '& > *'      : {
        marginTop: '1rem !important',
        alignSelf: 'stretch !important',
    }
})

const DragAndDropContainer = styled('div')({
    display: 'table-row-group'
})
const DragAndDropHome = styled('div')({
    display: 'table-row-group'
})

const Container = Card.extends({
    display  : 'table',
    textAlign: 'center',
    boxSizing: 'border-box',
    width    : '100%',
    flex     : '1 1 auto',
}).withProps({
    color: managerTheme.colors.cardBackground,
})

const Head = styled('div')({
    display: 'table-header-group'
})

const ItemTitle = Card.extends({
    flex          : '1 1 auto',
    marginBottom  : 0,
    textAlign     : 'center',
    padding       : '0.2rem',
    margin        : '0.5rem 2rem',
    color         : managerTheme.colors.lightText,
    display       : 'flex',
    flexDirection : 'row',
    justifyContent: 'center',
    alignItems    : 'center',
    '.gu-mirror &': {
        transform: 'rotate(-5deg)',
    }
})

const Drag = styled('div')({
    display  : 'table-row',
    boxSizing: 'border-box',
    cursor   : 'move',
})
const Cell = styled('div')({
    display: 'table-cell'
})

const RoundDragButton = RoundButton.extends({
    '.gu-mirror &': {
        opacity: 0,
    }
})

const InnerText = styled('span')({
    flex      : '1 1 auto',
    textShadow: managerTheme.shadow(3)
})
