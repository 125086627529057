import {Component, h} from 'preact'
import {database} from '../firebase'
import {currentThemeFromThemes, sortThemes} from '../../common/themesDates'
import {updateAvatar, updateProfile} from '../../common/User/User.actions'
import {getFileUrl} from '../utils/fileUrl'

const isAfter = require('date-fns/is_after')
const isBefore = require('date-fns/is_before')
const startOfDay = require('date-fns/start_of_day')
const endOfDay = require('date-fns/end_of_day')
const startOfHour = require('date-fns/start_of_hour')
const endOfHour = require('date-fns/end_of_hour')
const addHours = require('date-fns/add_hours')
const differenceInHours = require('date-fns/difference_in_hours')

const differenceInDays = require('date-fns/difference_in_days')
const addDays = require('date-fns/add_days')

export class AppDataWrapper extends Component {
    state = {}
    lastUid = null
    lastCampaignId = null

    componentWillMount() {
        const authUser = this.context.authUser
        this.updateUser(authUser)
    }

    componentWillReceiveProps({}, {authUser}) {
        this.updateUser(authUser)
    }

    async updateUser(authUser) {
        if (authUser?.uid && this.lastUid !== authUser.uid) {
            this.unregisterUser()
            this.lastUid = authUser.uid
            const userRef = database.ref('users').child(authUser.uid)

            this.lastUserCb = userRef.on('value', async (snap) => {
                const actualUser = snap.val()

                if (authUser.displayName && !actualUser.name) {
                    this.context.addActions(updateProfile(authUser.displayName))
                }
                if (authUser.photoURL && !actualUser.avatar) {
                    this.context.addActions(updateAvatar(authUser.photoURL))
                }

                this.setState({
                    user  : actualUser,
                    userId: snap.key,
                })

                if (actualUser?.currentCampaign) {
                    this.lastCampaignId = actualUser.currentCampaign
                    const actualCampaign = (await database.ref('campaigns').child(actualUser.currentCampaign).once('value')).val()
                    const themes = sortThemes(actualCampaign)
                    const currentTheme = currentThemeFromThemes(themes)
                    const acts = Object.keys(actualCampaign.acts || {})
                        .map(key => ({key, ...actualCampaign.acts[key]}))
                    getFileUrl(`campaigns/${actualUser?.currentCampaign}`)
                        .then(url => {
                            this.setState({
                                logo: url,
                            })
                        })
                    this.setState({
                        campaign   : actualCampaign,
                        acts       : acts,
                        quiz       : Object.keys(actualCampaign.quiz || {})
                            .map(key => ({key, ...actualCampaign.quiz[key]})),
                        currentTheme,
                        themes,
                        preregister: !currentTheme,
                        campaignId : snap.key,
                    })

                    this.lastCampaignCb = database.ref('campaigns').child(actualUser.currentCampaign).child('users').child(authUser.uid).on('value', (snap) => {
                        const userData = snap.val()

                        const now = this.state.campaign?.type === 'test' ? (userData?.date || new Date()) : new Date()
                        const userActs = userData?.acts || {}
                        const rawUserJobs = userData?.jobs || {}
                        const startedActs = (this.state.acts || [])
                            .filter(({key}) => userActs[key] && userActs[key].started && !userActs[key]?.done)
                            .map(act => {
                                const endDate = addDays(userActs[act.key].started, act.duration)
                                return ({
                                    ...act,
                                    active          : isAfter(now, startOfDay(endDate)),
                                    daysToValidation: differenceInDays(endOfDay(endDate), startOfDay(now))
                                })
                            }).sort((a, b) => a.daysToValidation - b.daysToValidation)
                        const userJobs = (this.state.jobs || [])
                            .map(job => {
                                const userJob = rawUserJobs[job.key] || {}
                                const endDate = userJob.bought && addHours(new Date(userJob.bought), job.productionTime)
                                const hoursLeftToBuild = userJob.bought ? differenceInHours(endOfHour(endDate), startOfHour(now)) : null
                                return ({
                                    ...job,
                                    seen            : userJob.seen,
                                    bought          : userJob.bought,
                                    hoursLeftToBuild: hoursLeftToBuild,
                                    viewable        : !userJob.seen && userJob.bought && !isBefore(now, endDate),
                                })
                            })

                        this.setState({
                            userData,
                            teamId: userData?.team,
                            startedActs,
                            userJobs,
                            now,
                        })
                    })
                } else {
                    this.lastCampaignId = null
                }
            })

            const jobsRef = database.ref('civijobs')

            const jobs = (await jobsRef.once('value')).val()
            this.setState({
                jobs: Object.keys(jobs).map(key => ({
                    ...jobs[key],
                    key: key,
                }))
            })
        }
    }

    getChildContext() {
        return this.state
    }

    unregisterUser = () => {
        if (!this.lastUid) return
        const userRef = database.ref('users').child(this.lastUid)
        if (this.lastUserCb) userRef.off('value', this.lastUserCb)
        this.unregisterCampaign()
    }

    unregisterCampaign = () => {
        if (!this.lastCampaignId) return
        if (this.lastCampaignCb) database.ref('campaigns').child(this.lastCampaignId).off('value', this.lastCampaignCb)
    }

    render({children}, {}, _) {
        return children[0]
    }
}
